<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

<!--CONTENTS START-->
<div class="college-backdrop">
    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_music/School of Music Logo.png'" alt="School of Music" class="logo">
            </div>
            <div class="col-md-6 mt-5">
                <h1 style="font-family: Arial; font-size: 3rem; color: #ffffff;">
                    SCHOOL OF
                </h1>
                <h1 style="font-family: Arial; font-size: 4rem; color: #ffffff;">
                    <b>MUSIC</b>
                </h1>
            </div>
        </div>
        <div class="container-fluid" style="margin-top: 50px;">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <h1 class="text-right justify-content-end" style="font-family: Arial; font-size: 3rem; color: #ffffff; margin-bottom: 25px;">About the School of Music</h1>
                    <h4 class="text-left" style="color: #ffffff; font-size: 2rem;">
                        ST. SCHOLASTICA’S COLLEGE SCHOOL OF MUSIC
                        <br><br>
                        <b>MISSION:</b><br>
                        We are a Benedictine School of Music committed to seeking and nurturing musical talent towards excellence in order to develop servant leaders with the mission to take music to the greater community, thereby enriching the society.
                        <br><br>
                        <b>VISION:</b><br>
                        We envision graduates who are competent, creative, and compassionate musicians who can provide inspiration and direction for all peoples towards life-long involvement in the art of music – graduates who are:
                        <ul>
                            <li><b>Competent</b> – knowledgeable, effective, pedagogue, and performer</li>
                            <li><b>Creative</b> – imaginative, expressive, flexible, resourceful, and innovative</li>
                            <li><b>Compassionate</b> – selfless, committed, service-oriented, caring, and sensitive</li>
                        </ul>
                        <br>
                        <b>GOALS:</b><br>
                        In pursuit of its mission-vision, the School of Music shall:
                        <ol>
                            <li>Prepare the students for careers as performers, composers, teachers, researchers, administrators of music for elementary and secondary levels, church musicians, private teachers, art administrators, and other related areas of professional activity.</li>
                            <li>Develop the students’ own knowledge, understanding, and skills in all aspects of music at a level appropriate to their needs and interests.</li>
                            <li>In addition, the School shall equip the students, in order that they may:
                                <ul>
                                    <li>Broaden and deepen their knowledge and understanding of all music literature (Western, Asian, Indigenous, traditional, electronic, etc.) and related arts through research and publications.</li>
                                    <li>Enrich their lives and that of their community and the nation through musical productions and performances.</li>
                                    <li>Attain the values of personal dignity so that they can become leaders capable of the highest professional accomplishment who can manifest and transmit Benedictine values.</li>
                                    <li>Have acquired the necessary musical knowledge and skills to become leaders in music education capable of awakening cultural and social values in the young.</li>
                                    <li>Have been trained as liturgical musicians who can provide the leadership necessary to elevate the aesthetic level of the liturgy in the community which they serve.</li>
                                    <li>Possess the necessary training, knowledge, and skills to use technology as an instructional tool and as a means of creating and producing music.</li>
                                </ul>
                            </li>
                        </ol>
                    </h4>
                </div>
            </div>
        </div>
        <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">
        <div class="container-fluid" style="margin-top: 50px;">
            <div class="row" style="margin-left: 15px;" v-if="isWindowOnDesktop">
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Performance</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Music in Performance program is designed to develop highly skilled musicians in various instruments and voice. Students will receive intensive training in their chosen area of specialization, preparing them for careers as professional performers.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Performance</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Diploma in Music Performance program provides focused training for students who wish to develop their performance skills to a professional level. This program is ideal for those seeking a shorter course of study.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Certificate in Music Performance</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Certificate in Music Performance program offers foundational training in music performance. It is designed for students who wish to gain basic skills and knowledge in their chosen instrument or voice.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Music Production and Technology</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Arts in Music Production and Technology program combines music theory, composition, and technology. Students will learn to produce, record, and engineer music using the latest technology.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Production and Technology</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Diploma in Music Production and Technology program provides students with the skills and knowledge needed to work in the music production industry. It covers recording, mixing, and mastering techniques.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Music Composition</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Music in Music Composition program trains students to create original music in various genres and styles. Students will develop their compositional skills through theoretical and practical training.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Music Education Major In:</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Music in Music Education program prepares students to become music educators. Students can specialize in piano, voice, strings, winds, or percussion.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Composition</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Diploma in Music Composition program provides focused training in music composition. It is designed for students who wish to develop their compositional skills in a shorter time frame.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Music Production and Technology</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Arts in Music Production and Technology program combines music theory, composition, and technology. Students will learn to produce, record, and engineer music using the latest technology.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>

            <div class="row" v-if="!isWindowOnDesktop">
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Performance</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Music in Performance program is designed to develop highly skilled musicians in various instruments and voice. Students will receive intensive training in their chosen area of specialization, preparing them for careers as professional performers.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Production and Technology</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Diploma in Music Production and Technology program provides students with the skills and knowledge needed to work in the music production industry. It covers recording, mixing, and mastering techniques.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Performance</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Diploma in Music Performance program provides focused training for students who wish to develop their performance skills to a professional level. This program is ideal for those seeking a shorter course of study.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Music Composition</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Music in Music Composition program trains students to create original music in various genres and styles. Students will develop their compositional skills through theoretical and practical training.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Certificate in Music Performance</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Certificate in Music Performance program offers foundational training in music performance. It is designed for students who wish to gain basic skills and knowledge in their chosen instrument or voice.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Composition</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Diploma in Music Composition program provides focused training in music composition. It is designed for students who wish to develop their compositional skills in a shorter time frame.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial; font-size: 0.75rem;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Music Production and Technology</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                The Bachelor of Arts in Music Production and Technology program combines music theory, composition, and technology. Students will learn to produce, record, and engineer music using the latest technology.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }
    .logo{
        width: 200px;
        height: 200px;
        margin-right: 10px;
    }
</style>
<script>
    import NavbarStatic from "../../../../components/general/NavbarStatic";
    import SiteHeader from "../../../../components/general/SiteHeader";
    import SiteFooter from "../../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>
