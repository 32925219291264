<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="container">
            <div class="header">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial; margin-bottom: 50px;"><strong>COLLEGE</strong></h1>
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial"><strong><i>ABOUT OUR COLLEGE PROGRAMS</i></strong></h1>
                <p style="color: #012376; font-size: 1.5rem; font-family: Arial">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>

            <div class="school-section">
                <a :href="env_wp.APP_URL+'/school/school-of-music'" target="_blank">
                    <div class="school-card" style="background-image: url('https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_music/School of Music Backdrop.jpg')" v-if="isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_music/School of Music Logo.png'" alt="School of Music" class="logo">
                            <div class="text">
                                <h5 class="card-title">School of Music</h5>
                            </div>
                        </div>
                    </div>
                    <div class="school-card" v-if="!isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_music/School of Music Logo.png'" alt="School of Music" class="logo-mobile">
                            <div class="text">
                                <h5 class="card-title3">School of Music</h5>
                            </div>
                        </div>
                    </div>
                </a>

                <a :href="env_wp.APP_URL+'/school/school-of-arts-and-science'" target="_blank">
                    <div class="school-card" style="background-image: url('https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_arts_and_sciences/School of Arts and Science Backdrop.jpg');" v-if="isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_arts_and_sciences/School of Arts and Sciences.png'" alt="School of Arts and Sciences" class="logo">
                            <div class="text">
                                <h5 class="card-title3">School of Arts and Sciences</h5>
                            </div>
                        </div>
                    </div>
                    <div class="school-card" v-if="!isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_arts_and_sciences/School of Arts and Sciences.png'" alt="School of Arts and Sciences" class="logo-mobile">
                            <div class="text">
                                <h5 class="card-title3">School of Arts and Sciences</h5>
                            </div>
                        </div>
                    </div>
                </a>

                <a :href="env_wp.APP_URL+'/school/school-of-business-and-accountancy'" target="_blank">
                    <div class="school-card" style="background-image: url('https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_business_and_accountancy/School of Business and Accountancy Backdrop.jpg');" v-if="isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_business_and_accountancy/Business and Accountancy Logo.png'" alt="School of Business and Accountancy" class="logo">
                            <div class="text">
                                <h5 class="card-title2">School of<br/>Business and Accountancy</h5>
                            </div>
                        </div>
                    </div>
                    <div class="school-card" v-if="!isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_business_and_accountancy/Business and Accountancy Logo.png'" alt="School of Business and Accountancy" class="logo-mobile">
                            <div class="text">
                                <h5 class="card-title4">School of Business and Accountancy</h5>
                            </div>
                        </div>
                    </div>
                </a>

                <a :href="env_wp.APP_URL+'/school/school-of-hotel-leisure-and-restaurant-management'" target="_blank">
                    <div class="school-card" style="background-image: url('https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_hotel_leisure_and_restaurant_management/SHLRM page Backdrop.jpg');" v-if="isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_hotel_leisure_and_restaurant_management/SHLRM Logo.png'" alt="School of Hotel, Leisure and Restaurant Management" class="logo">
                            <div class="text">
                                <h5 class="card-title2">School of Hotel, Leisure<br/>and Restaurant<br/>Management</h5>
                            </div>
                        </div>
                    </div>
                    <div class="school-card" v-if="!isWindowOnDesktop">
                        <div class="backdrop"></div>
                        <div class="content">
                            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_hotel_leisure_and_restaurant_management/SHLRM Logo.png'" alt="School of Hotel, Leisure and Restaurant Management" class="logo-mobile">
                            <div class="text">
                                <h5 class="card-title4">School of Hotel, Leisure and Restaurant Management</h5>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div style="margin-top: 150px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }


    .header {
        text-align: center;
        margin: 20px 0;
    }
    .header h1 {
        font-size: 2.5rem;
    }
    .header h3 {
        font-size: 1.5rem;
        margin-top: 10px;
    }
    .header p {
        font-size: 1rem;
        margin-top: 10px;
    }
    .school-section {
        margin-top: 20px;
    }
    .school-card {
        position: relative;
        color: white;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;
        padding: 20px;
        background-color: blue;
        background-repeat: no-repeat;
        background-size: 25% 100%;
        background-position: right;
    }
    .school-card .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background: rgba(0, 0, 0, 0.5);*/
        background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
        z-index: 1;
    }
    .school-card .content {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
    }
    .school-card img.logo {
        width: 175px;
        height: 175px;
        margin-right: 10px;
    }
    .school-card img.logo-mobile {
        width: 200px;
        height: 200px;
        margin-right: 15px;
    }
    .school-card .text {
        flex: 1;
    }
    .school-card .card-title {
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    .school-card .card-title2 {
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    .school-card .card-title3 {
        font-size: 1.75rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    .school-card .card-title4 {
        font-size: 1.25rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    .school-card .description {
        margin-top: 5px;
    }
    .school-card img.background {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 10;
        z-index: 1;
    }
</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>