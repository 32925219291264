<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="college-backdrop">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_music/School of Music Logo.png'" alt="School of Music" class="logo">
                    </div>
                    <div class="col-md-6 mt-5">
                        <h1 style="font-family: Arial; font-size: 3rem; color: #ffffff;">
                            SCHOOL OF
                        </h1>
                        <h1 style="font-family: Arial; font-size: 4rem; color: #ffffff;">
                            <b>MUSIC</b>
                        </h1>
                    </div>
                </div>
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h1 class="text-right justify-content-end" style="font-family: Arial; font-size: 3rem; color: #ffffff; margin-bottom: 25px;">About the School of Music</h1>
                            <h4 class="text-left" style="color: #ffffff; font-size: 2rem;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</h4>
                        </div>
                    </div>
                </div>
                <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row" style="margin-left: 15px;" v-if="isWindowOnDesktop">
                        <div class="col-md-6">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Performance</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Performance</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Certificate in Music Performace</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Music Production and Technology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="col-md-6">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Production and Technology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Music Composition</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Music Education Major In:</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Composition</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Music Production and Technology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>

                    <div class="row" v-if="!isWindowOnDesktop">
                        <div class="col-md-6">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Performance</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Production and Technology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Performance</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Music in Music Composition</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Certificate in Music Performace</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Diploma in Music Composition</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial; font-size: 0.75rem;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Music Production and Technology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </div>

                <div class="container-fluid" style="margin-top: 150px; margin-bottom: 150px;">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h1 class="text-left justify-content-start ssa-text-color" style="font-family: Arial; font-size: 5rem; margin-bottom: 25px;">CONTACT US:</h1>
                            <h4 class="text-left justify-content-start ssa-text-color" style="font-size: 2rem;">
                                <strong>School of Music</strong><br>
                                local 2288 (Dean of School of Music Secretary)
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }
    .logo{
        width: 200px;
        height: 200px;
        margin-right: 10px;
    }
</style>
<script>
    import NavbarStatic from "../../../../components/general/NavbarStatic";
    import SiteHeader from "../../../../components/general/SiteHeader";
    import SiteFooter from "../../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>