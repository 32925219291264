<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="container" style="margin-top: 50px;">
            <center>
                <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'"><strong>GIVE</strong></h1>
                <br>
                <br>
                <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'"><strong>The Scholastician Legacy Fund</strong></h1>
                <h4 class="ssa-text-color" style="font-family: Arial; font-size: 1.5rem;">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab animi distinctio dolorem enim explicabo illo laboriosam, libero nostrum odit quod rerum similique vitae voluptatum! Aliquam et magni minus quo veritatis?
                </h4>
            </center>
            <div class="row" style="margin-top: 125px; margin-left: 100px;">
                <div class="col-md-6">
                    <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'">Preserve the Legacy...</h1>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-lg btn-ssa"><span style="color: white; font-size: 28px;">DONATE NOW</span></button>
                </div>
            </div>
            <div style="margin-top: 125px;">&nbsp;</div>
            <center>
                <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'"><strong>CONTACT FOR DONATION</strong></h1>
                <div class="container">
                    <div class="container">
                        <hr style="border-top: 2px solid rgba(1, 35, 118, 1);">
                    </div>
                </div>
                <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'"><strong>Mr. Iane Dauson</strong></h1>
                <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'"><strong>(Scholarships and Grant Office)</strong></h1>
                <br>
                <br>
                <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'">8567-7686 (local 8163)</h1>
                <h1 class="ssa-text-color" :style="!isWindowOnDesktop ? 'font-family: Poppins; font-size: 2rem;' : 'font-family: Poppins; font-size: 3rem;'">scholarships@ssc.edu.ph</h1>
            </center>
        </div>
        <div style="margin-top: 100px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div style="background: #012376;">
            <div class="row">
                &nbsp;
            </div>
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .blue-shape {
        position: absolute;
        width: 350px;
        height: 350px;
        background-color: #002d72;
        transform: rotate(45deg);
        top: -10%;
        right: 10%;
        z-index: 0;
        left: 50%;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 50%; /* Maintain aspect ratio */
    }

    .image {
        position: absolute;
        border: 5px solid white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease;
    }

    .image1 {
        top: -10%;
        left: 50%;
        width: 60%;
        transform: rotate(-5deg);
        z-index: 2;
    }

    .image2 {
        top: 70%;
        left: 25%;
        width: 60%;
        transform: rotate(5deg);
        z-index: 3;
    }

    .image:hover {
        transform: scale(1.05);
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Cover the container */
        border: 2px solid white;
        border-radius: 5px;
    }

</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>