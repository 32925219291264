<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="college-backdrop">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_hotel_leisure_and_restaurant_management/SHLRM Logo.png'" alt="SHLRM" class="logo">
                    </div>
                    <div class="col-md-6 mt-5">
                        <h1 style="font-family: Arial; font-size: 2rem; color: #ffffff;">
                            SCHOOL OF
                        </h1>
                        <h1 style="font-family: Arial; font-size: 2.75rem; color: #ffffff;">
                            <b>HOTEL, LEISURE and RESTAURANT MANAGEMENT</b>
                        </h1>
                    </div>
                </div>
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h1 class="text-right justify-content-end" style="font-family: Arial; font-size: 3rem; color: #ffffff; margin-bottom: 25px;">About the School of Hotel, Leisure<br>and Restaurant Management</h1>
                            <h4 class="text-left" style="color: #ffffff; font-size: 1.3rem;">The School of Hotel, Leisure and Restaurant Management (SHLRM) of St. Scholastica’s College Manila is a hospitality management school that offers a full range of bachelor’s degree and non-degree courses in the field of hospitality and tourism management. It aims to produce confident and competent graduates imbued with the passion for excellence and live out their Christian and Benedictine values as they take an active role in the hospitality and tourism industries.</h4>
                            <br>
                            <br>
                            <h4 class="text-left" style="color: #ffffff; font-size: 1.3rem;">SHLRM is focused on providing high standard academic programs that equip students with knowledge & relevant training in business and management that will enable them to take on leadership positions soon as they graduate. Students are mentored by faculty members who are industry experts and leaders in the tourism and hospitality industries.</h4>
                            <br>
                            <br>
                            <h4 class="text-left" style="color: #ffffff; font-size: 1.3rem;">The School offers a holistic practical learning experience that allows a student to develop value for academic excellence, acquire soft skills, communicate effectively, and achieve professional and technical competency needed in the field. All of these are done by aligning the curricula with the needs and specialties of the hospitality and tourism professions and industries and providing real-world experiences from intensive internships both in the Philippines and abroad.</h4>
                            <br>
                            <br>
                            <br>
                            <h1 class="text-right justify-content-end" style="font-family: Arial; font-size: 3rem; color: #ffffff; margin-bottom: 25px;">School VMG's and Objectives</h1>
                            <br>
                            <br>
                            <h4 class="text-left justify-content-end" style="color: #ffffff; font-size: 2rem;">VISION:</h4>
                            <br>
                            <br>
                            <h4 class="text-left" style="color: #ffffff; font-size: 1.3rem;">A center of excellence in hotel, leisure, and restaurant management education in the country.</h4>
                            <br>
                            <h4 class="text-left justify-content-end" style="color: #ffffff; font-size: 2rem;">MISSION:</h4>
                            <br>
                            <h4 class="text-left" style="color: #ffffff; font-size: 1.3rem;">The School of Hotel, Leisure and Restaurant Management (SHLRM) provides young women and men with necessary knowledge, skills, values and attitudes through experiential learning opportunities that would prepare them for successful management careers and leadership roles in hospitality and tourism industry.</h4>
                            <br>
                        </div>
                        <div class="col-12">
                            <h4 class="text-left justify-content-end" style="color: #ffffff; font-size: 2rem;">GOALS:</h4>
                            <br>
                            <ol style="color: #ffffff; font-size: 1.3rem;">
                                <li>Develop programs to equip the students with essential knowledge and skills to be job-ready in the hospitality and tourism industries</li>
                                <li>Provide mechanism to actively contribute to the efforts of the Corporate Social Responsibility of the hospitality and tourism industries to reach out to the marginalized sectors of society</li>
                                <li>Instill the values of social responsibility, pursuit of justice, respect for human dignity and love for work with the end goal of making a difference.</li>
                            </ol>
                            <br>
                            <br>
                            <h4 class="text-left justify-content-end" style="color: #ffffff; font-size: 2rem;">OBJECTIVES:</h4>
                            <br>
                            <ol style="color: #ffffff; font-size: 1.3rem;">
                                <li>To provide a common body of knowledge in hospitality management coupled with a broad education and awareness of skills and attitudes which will prepare students for responsible leadership roles in the hospitality industry.</li>
                                <li>To develop employability skills required of hospitality and tourism management the use of a competency-based program.</li>
                                <li>To provide students with relevant hands-on operational experience in some facet of the hospitality industry.</li>
                                <li>To create and deliver programs that enhances the quality and contribution of hospitality and tourism to sustainable social, economic, cultural development and environmental conservation.</li>
                                <li>To be a beacon of Benedictine values by maintaining a fair and creative work environment that promotes and respects academic freedom and freedom of expression, humility and hard work, and diversity in all forms.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row" style="margin-left: 15px;" v-if="isWindowOnDesktop">
                        <div class="col-md-12">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">
                                            &nbsp;&nbsp;&nbsp;Bachelor of Science in Hospitality with specialization in Culinary Arts and Kitchen Management
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div class="container-fluid" style="width: 1000px;">
                                        The Culinary Arts and Kitchen Operations Program is a Bachelor of Science degree Program that provides foundation and
                                        specialization study in the Culinary Arts and Food Service field through academic, laboratory and field internship experiences. The
                                        Program provides the knowledge and skills necessary for the students to ensure efficiency and quality product preparation and
                                        service in the food service setting.  The program is designed in two depth areas in the culinary field: operations and management,
                                        which is to prepare students for employment in a variety of food production and restaurant positions in the growing of food service field.
                                        <br>
                                        <br>
                                        <b>Career Opportunities:</b>
                                        <br>
                                        <div class="row">
                                            <div class="col">
                                                <ul>
                                                    <li>Executive Chef</li>
                                                    <li>Chef de Partie</li>
                                                    <li>Poissonier</li>
                                                    <li>Extremetier</li>
                                                    <li>Restaurateur</li>
                                                    <li>F & B Manager</li>
                                                    <li>Garde Manger</li>
                                                </ul>
                                            </div>
                                            <div class="col">
                                                <ul>
                                                    <li>Grillardin </li>
                                                    <li>Pastry Chef </li>
                                                    <li>Caterer </li>
                                                    <li>Consultant </li>
                                                    <li>Wine Sommelier </li>
                                                    <li>R&D Chef</li>
                                                    <li>Culinary Instructor</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br>
                                        <b>Competencies:</b>
                                        <ol>
                                            <li>Knowledge of Competitive Products and Competitive Product Analysis</li>
                                            <li>Knowledge of Current and Projected Food Trends (temperature conversion, metric conversion, weight calculation, etc)</li>
                                            <li>Knowledge of Commercial Equipment</li>
                                            <li>Knowledge of Commercial Kitchen Responsibilities and Pressures</li>
                                            <li>Knowledge of Culinary Fundamentals and Production Systems</li>
                                            <li>Knowledge of Culinary Uses and Application of Products</li>
                                            <li>Knowledge of End-user Skills (including cooks in national chains)</li>
                                            <li>Knowledge of Kitchen Tools and Equipment (including how to use and apply)</li>
                                            <li>Knowledge of Principles of Cooking (including heat transfer, cooking methods, breading/batters, etc.)</li>
                                            <li>Knowledge of Regional and World Cuisines (including preparation, spicing & presentation, and cultural topics)</li>
                                            <li>Knowledge of Traditional Stocks and Sauces</li>
                                        </ol>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff;">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">
                                            &nbsp;&nbsp;&nbsp;Bachelor of Science in Hospitality with specialization in Hotel and Restaurant Administration
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div class="container-fluid" style="width: 1000px;">
                                        The Hotel and Restaurant Administration Program is a Bachelor of Science Degree Program that provides foundation
                                        and specialization study in the Hotel Management field through academic, laboratory and field internship experiences.
                                        The program provides the knowledge and skills necessary for the students to ensure efficiency and to prepare the students to be supervisors in the
                                        Hospitality industry.  The program is designed in two depth areas in the Hotel field operations and management, which is to prepare
                                        students for employment in a variety of hotel positions in the growing hotel field.
                                        <br>
                                        <br>
                                        <b>Career Opportunities:</b>
                                        <div class="row">
                                            <div class="col">
                                                <ul>
                                                    <li>F & B Manager </li>
                                                    <li>Hotel General Manager </li>
                                                    <li>Sales Manager in a Hotel Chain</li>
                                                    <li>Quality Manager</li>
                                                    <li>Events Manager </li>
                                                    <li>Event Specialist</li>
                                                    <li>Executive Housekeeper </li>
                                                    <li>Reservation Agent</li>
                                                </ul>
                                            </div>
                                            <div class="col">
                                                <ul>
                                                    <li>Front Desk Manager </li>
                                                    <li>Front Desk Agent </li>
                                                    <li>Room Sales Agent </li>
                                                    <li>Hotelier</li>
                                                    <li>Human Resource Manager</li>
                                                    <li>Account Executive</li>
                                                    <li>Business Analyst</li>
                                                    <li>Safety & Security Manager</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br>
                                        <b>Competencies:</b>
                                        <ol>
                                            <li>Ability to work with customer/client groups </li>
                                            <li>Knowledge of end user skills (e.g. cooks in national chains) </li>
                                            <li>Skilled at food presentation </li>
                                            <li>Management skills (delegating and organizing, personnel development) </li>
                                            <li>Knowledge of food service operations </li>
                                            <li>Skilled at basic computer systems </li>
                                            <li>Skilled at presentation of research and plans </li>
                                            <li>Skilled at writing accurate reports </li>
                                            <li>Identify and solve managerial problems </li>
                                            <li>Demonstrate leadership abilities </li>
                                            <li>Control costs effectively </li>
                                            <li>Develop positive customer relationships </li>
                                            <li>Demonstrate effective oral communication skills</li>
                                        </ol>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Tourism Management</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div class="container-fluid" style="width: 1000px;">
                                        The Tourism Management program offers a broad array of progressive courses positioning students to meet the challenges of the
                                        tourism industry. The program is designed to prepare students for leadership positions in hotels, resorts, restaurants, airline, cruise
                                        lines, travel agencies as well as conventions and events. In addition to both management and industry-specific courses, our program
                                        also stresses core concepts such as excellence, quality and relevance. Graduates are prepared to design and lead customer-driven
                                        service systems that address the needs of consumers throughout the tourism industry.
                                        <br>
                                        <br>
                                        <b>Career Opportunities:</b>
                                        <br>
                                        <div class="row">
                                            <div class="col">
                                                <ul>
                                                    <li>Flight Attendant/Cabin Crew</li>
                                                    <li>Travel Agent/Tour Guide</li>
                                                    <li>Travel Operator</li>
                                                    <li>Hotel Manager</li>
                                                    <li>Spa Manager</li>
                                                    <li>Event & Conference Organizer</li>
                                                </ul>
                                            </div>
                                            <div class="col">
                                                <ul>
                                                    <li>PR Manager</li>
                                                    <li>Leisure Activity Coordinator</li>
                                                    <li>Cruise Ship Attendant</li>
                                                    <li>Bartender/ Sommelier </li>
                                                    <li>Account Executive</li>
                                                    <li>Sales and Marketing Manager</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br>
                                        <b>Competencies:</b>
                                        <br>
                                        <ol>
                                            <li>Ability to understand international travel and tourism discipline</li>
                                            <li>Excellent ability in written, oral communication skills and clear expression in more than one language.</li>
                                            <li>Ability to compete for management positions and to secure upward mobility</li>
                                            <li>Ability to work and interact with people with diversified culture backgrounds through team-spirit and respect.</li>
                                            <li>Plan, implement and monitor tours and sales activities,</li>
                                            <li>Research, plan and conduct various tour guiding activities;</li>
                                            <li>Develop appropriate marketing programs and arrange the required travel services;</li>
                                            <li>Plan / Organize, implement and evaluate MICE activities; </li>
                                            <li>Plan, develop and evaluate tourism sited and attractions.</li>
                                        </ol>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                    <div class="row" v-if="!isWindowOnDesktop">
                        <div class="col-md-12">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial; font-size: 0.6rem;">
                                            &nbsp;&nbsp;&nbsp;BSH with specialization in Culinary Arts and Kitchen Management
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Culinary Arts and Kitchen Operations Program is a Bachelor of Science degree Program that provides foundation and
                                        specialization study in the Culinary Arts and Food Service field through academic, laboratory and field internship experiences. The
                                        Program provides the knowledge and skills necessary for the students to ensure efficiency and quality product preparation and
                                        service in the food service setting.  The program is designed in two depth areas in the culinary field: operations and management,
                                        which is to prepare students for employment in a variety of food production and restaurant positions in the growing of food service field.
                                        <br>
                                        <br>
                                        <b>Career Opportunities:</b>
                                        <br>
                                        <div class="row">
                                            <div class="col">
                                                <ul>
                                                    <li>Executive Chef</li>
                                                    <li>Chef de Partie</li>
                                                    <li>Poissonier</li>
                                                    <li>Extremetier</li>
                                                    <li>Restaurateur</li>
                                                    <li>F & B Manager</li>
                                                    <li>Garde Manger</li>
                                                </ul>
                                            </div>
                                            <div class="col">
                                                <ul>
                                                    <li>Grillardin </li>
                                                    <li>Pastry Chef </li>
                                                    <li>Caterer </li>
                                                    <li>Consultant </li>
                                                    <li>Wine Sommelier </li>
                                                    <li>R&D Chef</li>
                                                    <li>Culinary Instructor</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br>
                                        <b>Competencies:</b>
                                        <ol>
                                            <li>Knowledge of Competitive Products and Competitive Product Analysis</li>
                                            <li>Knowledge of Current and Projected Food Trends (temperature conversion, metric conversion, weight calculation, etc)</li>
                                            <li>Knowledge of Commercial Equipment</li>
                                            <li>Knowledge of Commercial Kitchen Responsibilities and Pressures</li>
                                            <li>Knowledge of Culinary Fundamentals and Production Systems</li>
                                            <li>Knowledge of Culinary Uses and Application of Products</li>
                                            <li>Knowledge of End-user Skills (including cooks in national chains)</li>
                                            <li>Knowledge of Kitchen Tools and Equipment (including how to use and apply)</li>
                                            <li>Knowledge of Principles of Cooking (including heat transfer, cooking methods, breading/batters, etc.)</li>
                                            <li>Knowledge of Regional and World Cuisines (including preparation, spicing & presentation, and cultural topics)</li>
                                            <li>Knowledge of Traditional Stocks and Sauces</li>
                                        </ol>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff;">
                                    <template slot="title">
                                        <strong style="font-family: Arial; font-size: 0.6rem;">
                                            &nbsp;&nbsp;&nbsp;BSH with specialization in Hotel and Restaurant Administration
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Hotel and Restaurant Administration Program is a Bachelor of Science Degree Program that provides foundation
                                        and specialization study in the Hotel Management field through academic, laboratory and field internship experiences.
                                        The program provides the knowledge and skills necessary for the students to ensure efficiency and to prepare the students to be supervisors in the
                                        Hospitality industry.  The program is designed in two depth areas in the Hotel field operations and management, which is to prepare
                                        students for employment in a variety of hotel positions in the growing hotel field.
                                        <br>
                                        <br>
                                        <b>Career Opportunities:</b>
                                        <div class="row">
                                            <div class="col">
                                                <ul>
                                                    <li>F & B Manager </li>
                                                    <li>Hotel General Manager </li>
                                                    <li>Sales Manager in a Hotel Chain</li>
                                                    <li>Quality Manager</li>
                                                    <li>Events Manager </li>
                                                    <li>Event Specialist</li>
                                                    <li>Executive Housekeeper </li>
                                                    <li>Reservation Agent</li>
                                                </ul>
                                            </div>
                                            <div class="col">
                                                <ul>
                                                    <li>Front Desk Manager </li>
                                                    <li>Front Desk Agent </li>
                                                    <li>Room Sales Agent </li>
                                                    <li>Hotelier</li>
                                                    <li>Human Resource Manager</li>
                                                    <li>Account Executive</li>
                                                    <li>Business Analyst</li>
                                                    <li>Safety & Security Manager</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br>
                                        <b>Competencies:</b>
                                        <ol>
                                            <li>Ability to work with customer/client groups </li>
                                            <li>Knowledge of end user skills (e.g. cooks in national chains) </li>
                                            <li>Skilled at food presentation </li>
                                            <li>Management skills (delegating and organizing, personnel development) </li>
                                            <li>Knowledge of food service operations </li>
                                            <li>Skilled at basic computer systems </li>
                                            <li>Skilled at presentation of research and plans </li>
                                            <li>Skilled at writing accurate reports </li>
                                            <li>Identify and solve managerial problems </li>
                                            <li>Demonstrate leadership abilities </li>
                                            <li>Control costs effectively </li>
                                            <li>Develop positive customer relationships </li>
                                            <li>Demonstrate effective oral communication skills</li>
                                        </ol>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Tourism Management</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Tourism Management program offers a broad array of progressive courses positioning students to meet the challenges of the
                                        tourism industry. The program is designed to prepare students for leadership positions in hotels, resorts, restaurants, airline, cruise
                                        lines, travel agencies as well as conventions and events. In addition to both management and industry-specific courses, our program
                                        also stresses core concepts such as excellence, quality and relevance. Graduates are prepared to design and lead customer-driven
                                        service systems that address the needs of consumers throughout the tourism industry.
                                        <br>
                                        <br>
                                        <b>Career Opportunities:</b>
                                        <br>
                                        <div class="row">
                                            <div class="col">
                                                <ul>
                                                    <li>Flight Attendant/Cabin Crew</li>
                                                    <li>Travel Agent/Tour Guide</li>
                                                    <li>Travel Operator</li>
                                                    <li>Hotel Manager</li>
                                                    <li>Spa Manager</li>
                                                    <li>Event & Conference Organizer</li>
                                                </ul>
                                            </div>
                                            <div class="col">
                                                <ul>
                                                    <li>PR Manager</li>
                                                    <li>Leisure Activity Coordinator</li>
                                                    <li>Cruise Ship Attendant</li>
                                                    <li>Bartender/ Sommelier </li>
                                                    <li>Account Executive</li>
                                                    <li>Sales and Marketing Manager</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br>
                                        <b>Competencies:</b>
                                        <br>
                                        <ol>
                                            <li>Ability to understand international travel and tourism discipline</li>
                                            <li>Excellent ability in written, oral communication skills and clear expression in more than one language.</li>
                                            <li>Ability to compete for management positions and to secure upward mobility</li>
                                            <li>Ability to work and interact with people with diversified culture backgrounds through team-spirit and respect.</li>
                                            <li>Plan, implement and monitor tours and sales activities,</li>
                                            <li>Research, plan and conduct various tour guiding activities;</li>
                                            <li>Develop appropriate marketing programs and arrange the required travel services;</li>
                                            <li>Plan / Organize, implement and evaluate MICE activities; </li>
                                            <li>Plan, develop and evaluate tourism sited and attractions.</li>
                                        </ol>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </div>
            </div>

            <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">

            <div class="container" style="margin-top: 35px;">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="text-left justify-content-end ssa-text-color" style="font-family: Arial; font-size: 3rem; margin-bottom: 25px;">Our Faculty</h1>
                        <br>
                        <br>
                        <h4 class="text-left ssa-text-color" style="font-size: 1.3rem;">At St. Scholastica’s College- School of Hotel, Leisure and Restaurant Management, we believe that the best education combines cutting-edge knowledge in all aspects of our Industry. We have diverse faculty that includes preeminent lecturers from the United Nations Educational, Scientific & Cultural Organization (UNESCO), seasoned business professors, outstanding researcher from the National Commission for Culture and the Arts (NCCA), and professional experts from the culinary, hotel, cruise line and airline organizations.</h4>
                        <br>
                        <h4 class="text-left ssa-text-color" style="font-size: 1.3rem;">&nbsp;&nbsp;Our Professors are pioneering Culinarians, Speakers, Advisors and Sustainability Advocates.  They have worked at top hotels in the country and important government agencies, and occupied top positions within the culinary industry. That is why our graduates are well-rounded and prepared to meet the challenges they will face when they graduate.</h4>
                    </div>
                </div>
            </div>

            <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">

            <br>

            <center>
                <h1 class="ssa-text-color">
                    <b>Notable Alumnae</b>
                </h1>
                <!-- MOBILE -->
                <div class="container-fluid" style="margin-top: 35px; width: 80%; padding-left: 0%;" v-if="!isWindowOnDesktop">
                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/1.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">EVELYN CAYABAN</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1984</p>
                                    <br>
                                    <p>NOTABLE BUSINESS DEVELOPMENT SPECIALIST</p>
                                    <br>
                                    <p>HERITAGE HOTEL GROUP, ROSEVILLE, CALIFORNIA</p>
                                </div>
                            </el-card>
                        </div>
                    </div>
                    
                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/2.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">LILYBETH TAN</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1996</p>
                                    <br>
                                    <p>VICE PRESIDENT FOR PILOT AFFAIRS</p>
                                    <br>
                                    <p>PHILIPPINE AIRLINES</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>
                    
                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/3.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">JUDY TY</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2004</p>
                                    <br>
                                    <p>CALL CENTER AND RESERVATION MANAGER</p>
                                    <br>
                                    <p>SOLAIRE CASINO AND RESORT</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/4.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">PERLA PERALTA-MACLANG</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1983</p>
                                    <br>
                                    <p>COMMERCIAL DIRECTOR</p>
                                    <br>
                                    <p>LINDEN SUITES</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/5.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">REGINE VALERIE BANAAG</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2008</p>
                                    <br>
                                    <p>COE AND GENERAL MANAGER</p>
                                    <br>
                                    <p>MySTAY HOTELS</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/6.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">DR.FRANCIA YVETTE MAGPANTAY</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2000</p>
                                    <br>
                                    <p>DEAN, COLLEGE OF HOSPITALITY AND TOURISM MANAGEMENT</p>
                                    <br>
                                    <p>UNIVERSITY OF BATANGAS</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/7.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">CHEF KRIZZA PALMERO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2012</p>
                                    <br>
                                    <p>OWNER</p>
                                    <br>
                                    <p>PILYA'S KITCHEN</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/8.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">JOBE BEO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2007</p>
                                    <br>
                                    <p>REGIONAL ATTRACTION & REGIONAL MARKETING MANAGER (MID-EAST & AFRICA)</p>
                                    <br>
                                    <p>RECKIIT</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/9.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">MADELINE GRACE SY</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2008</p>
                                    <br>
                                    <p>SENIOR SALES MANAGER</p>
                                    <br>
                                    <p>SOFITEL PHILIPPINE PLAZA</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/10.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">KRIS INA BUENAGUA</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2009</p>
                                    <br>
                                    <p>HUMAN RESOURCE MANAGER</p>
                                    <br>
                                    <p>VOCO DUBAI THE PALM</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/11.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">NANETTE KHO-MORENO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2000</p>
                                    <br>
                                    <p>GENERAL MANAGER</p>
                                    <br>
                                    <p>ASCOTT HOTEL</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/12.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">NICOLE AMPARO DONATO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1989</p>
                                    <br>
                                    <p>GENERAL MANAGER</p>
                                    <br>
                                    <p>COMFORT INN EMIRATES HOTEL</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/13.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">BINA NANDWANI</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1981</p>
                                    <br>
                                    <p>OWNER</p>
                                    <br>
                                    <p>BINAPAM EVENTS</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/14.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">ROSEANN VILLEGAS</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1983</p>
                                    <br>
                                    <p>DIRECTOR FOR CORPORATE PUBLIC RELATIONS</p>
                                    <br>
                                    <p>ROBINSONS LAND CORPORATIONS</p>
                                </div>
                            </el-card>
                        </div>
                    </div>

                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex;">
                        <div class="col-3" style="padding-left: 0%; padding-right: 0%;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/15.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">ANNE MARIE KRISTINE CARBALLO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2005</p>
                                    <br>
                                    <p>OWNER/CONSULTANT</p>
                                    <br>
                                    <p>FESTEJO & CARBALLO TRAINING AND DEVELOPMENT CONSULTANCY</p>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
                <br>
                <!-- MOBILE -->
                <!-- WEB -->
                <!-- 1 -->
                <div class="container-fluid" style="margin-top: 35px; width: 80%;" v-if="isWindowOnDesktop">
                    <div class="row" style="margin: 0%; padding: 0%; display: flex; justify-content: space-between;">
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/1.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">EVELYN CAYABAN</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1984</p>
                                    <br>
                                    <p>NOTABLE BUSINESS DEVELOPMENT SPECIALIST</p>
                                    <br>
                                    <p>HERITAGE HOTEL GROUP, ROSEVILLE, CALIFORNIA</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/2.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">LILYBETH TAN</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1996</p>
                                    <br>
                                    <p>VICE PRESIDENT FOR PILOT AFFAIRS</p>
                                    <br>
                                    <p>PHILIPPINE AIRLINES</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/3.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">JUDY TY</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2004</p>
                                    <br>
                                    <p>CALL CENTER AND RESERVATION MANAGER</p>
                                    <br>
                                    <p>SOLAIRE CASINO AND RESORT</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/4.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">PERLA PERALTA-MACLANG</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1983</p>
                                    <br>
                                    <p>COMMERCIAL DIRECTOR</p>
                                    <br>
                                    <p>LINDEN SUITES</p>
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <!-- 2 -->
                    <br>
                    
                    <div class="row" style="margin: 0%; padding: 0%; display: flex; justify-content: space-between;">
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/5.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">REGINE VALERIE BANAAG</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2008</p>
                                    <br>
                                    <p>COE AND GENERAL MANAGER</p>
                                    <br>
                                    <p>MySTAY HOTELS</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/6.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">DR.FRANCIA YVETTE MAGPANTAY</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2000</p>
                                    <br>
                                    <p>DEAN, COLLEGE OF HOSPITALITY AND TOURISM MANAGEMENT</p>
                                    <br>
                                    <p>UNIVERSITY OF BATANGAS</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/7.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">CHEF KRIZZA PALMERO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2012</p>
                                    <br>
                                    <p>OWNER</p>
                                    <br>
                                    <p>PILYA'S KITCHEN</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/8.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">JOBE BEO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2007</p>
                                    <br>
                                    <p>REGIONAL ATTRACTION & REGIONAL MARKETING MANAGER (MID-EAST & AFRICA)</p>
                                    <br>
                                    <p>RECKIIT</p>
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <!-- 3 -->
                     <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex; justify-content: space-between;">
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/9.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">MADELINE GRACE SY</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2008</p>
                                    <br>
                                    <p>SENIOR SALES MANAGER</p>
                                    <br>
                                    <p>SOFITEL PHILIPPINE PLAZA</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/10.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">KRIS INA BUENAGUA</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2009</p>
                                    <br>
                                    <p>HUMAN RESOURCE MANAGER</p>
                                    <br>
                                    <p>VOCO DUBAI THE PALM</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/11.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">NANETTE KHO-MORENO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2000</p>
                                    <br>
                                    <p>GENERAL MANAGER</p>
                                    <br>
                                    <p>ASCOTT HOTEL</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/12.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">NICOLE AMPARO DONATO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1989</p>
                                    <br>
                                    <p>GENERAL MANAGER</p>
                                    <br>
                                    <p>COMFORT INN EMIRATES HOTEL</p>
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <!-- 4 -->
                    <br>

                    <div class="row" style="margin: 0%; padding: 0%; display: flex; justify-content: space-between;">
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/13.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">BINA NANDWANI</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1981</p>
                                    <br>
                                    <p>OWNER</p>
                                    <br>
                                    <p>BINAPAM EVENTS</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/14.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">ROSEANN VILLEGAS</b>
                                    <br>
                                    <p>AB HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 1983</p>
                                    <br>
                                    <p>DIRECTOR FOR CORPORATE PUBLIC RELATIONS</p>
                                    <br>
                                    <p>ROBINSONS LAND CORPORATIONS</p>
                                </div>
                            </el-card>
                        </div>
                        <div class="col-3" style="flex: 0 0 0;">
                            <el-card class="box-card">
                                <div>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hlrm_alumni/15.png'" class="alumni-img">
                                </div>
                                <hr>
                                <div>
                                    <b style="text-decoration: underline;">ANNE MARIE KRISTINE CARBALLO</b>
                                    <br>
                                    <p>BS HOTEL AND RESTAURANT MANAGEMENT,</p>
                                    <br>
                                    <p>BATCH 2005</p>
                                    <br>
                                    <p>OWNER/CONSULTANT</p>
                                    <br>
                                    <p>FESTEJO & CARBALLO TRAINING AND DEVELOPMENT CONSULTANCY</p>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
                <!-- WEB -->
            </center>

            <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">

            <div class="container" style="margin-top: 50px;">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h1 class="text-left justify-content-end ssa-text-color" style="font-family: Arial; font-size: 3rem; margin-bottom: 25px;">School or Program Highlights</h1>
                        <center>
                            <h4 class="text-left ssa-text-color" style="font-size: 2rem; text-decoration: underline;">Accredited Status of BS Hospitality Management Program</h4>
                        </center>
                        <br>
                        <ul class="ssa-text-color" style="font-size: 1.3rem;">
                            <li>
                                <b>
                                    Work-Integrated Education
                                </b>
                                <ul style="list-style-type: none;">
                                    <li>SHLRM has work-integrated programs in the undergraduate curriculum, thus providing students with real-life industry experience.</li>
                                </ul>
                            </li>
                            <li>
                                <b>
                                    Service-Learning
                                </b>
                                <ul style="list-style-type: none;">
                                    <li>A unique hallmark of SSC's holistic education. where teaching & learning integrates meaningful community service with
                                        instruction and reflection to enrich the learning experience, teach civic responsibility, and strengthen communities.</li>
                                </ul>
                            </li>
                            <li>
                                <b>
                                    Student Exchange Program
                                </b>
                                <ul style="list-style-type: none;">
                                    <li>An opportunity given to students to foster an international outlook, gain cultural enrichment, and professional
                                        development, and expand global network of partnership.</li>
                                </ul>
                            </li>
                            <li>
                                <b>
                                    Leadership Programs
                                </b>
                                <ul style="list-style-type: none;">
                                    <li>Inherent to the mission of SSC in creating leaders for social transformation, the SHLRM curricula incorporate leadership
                                        skills training for its students </li>
                                </ul>
                            </li>
                            <li>
                                <b>
                                    The Friedenshaus Hotel School 
                                </b>
                                <ul style="list-style-type: none;">
                                    <li>A practicum venue for SHLRM students of St. Scholastica’s College Manila where the rich German tradition of the
                                        Congregation of the Missionary Benedictine Sisters of Tutzing, Germany and as advocates of peace, love for music and
                                        deep appreciation of the arts will be experienced.</li>
                                </ul>
                            </li>
                        </ul>
                        <br>
                        <h4 class="text-left ssa-text-color" style="font-size: 2rem;">Linkages/Partner Industries</h4>
                        <ol class="ssa-text-color" style="font-size: 1.3rem;">
                            <li>The Manila Hotel </li>
                            <li>Okada Hotel</li>
                            <li>Grand Hyatt Manila</li>
                            <li>Hilton Manila</li>
                            <li>Ascott Manila</li>
                            <li>Vivire Hotel</li>
                            <li>RainTree Group</li>
                            <li>Wolfgang Steakhouse</li>
                            <li>Hospitality Institute America-Philippines</li>
                            <li>International Training Network </li>
                            <li>EdiPhi (World Wide Internship Philippines)</li>
                            <li>Amadeus Philippines</li>
                            <li>Philippine-Italian Association</li>
                            <li>Limtuaco Distillery</li>
                        </ol>
                        <br>
                        <h4 class="text-left ssa-text-color" style="font-size: 2rem;">Membership in Professional Organization</h4>
                        <ol class="ssa-text-color" style="font-size: 1.3rem;">
                            <li>Asia-Pacific CHRIE (Council on Hotel, Restaurant, and Institutional Education)(APacCHRIE)</li>
                            <li>Hotel and Restaurant Association of the Philippines (HRAP)</li>
                            <li>Council of Hotel and Restaurant Educators of the Philippines (COHREP)</li>
                            <li>Association of Administrators in Hospitality, Hotel and Restaurant Management Educational Institution (AAHRMEI)</li>
                            <li>Hospitality and Tourism Council of Deans (HosTCoDe) Union of Filipino Tourism Educators (UFTE)</li>
                            <li>Junior Tourism and Hospitality Management Association of the Philippines (JTHMAP)</li>
                        </ol>
                    </div>
                </div>
            </div>

            <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">

            <div class="container" style="margin-top: 150px; margin-bottom: 150px;">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="text-left justify-content-start ssa-text-color" style="font-family: Arial; font-size: 5rem; margin-bottom: 25px;">CONTACT US:</h1>
                        <h4 class="text-left justify-content-start ssa-text-color" style="font-size: 2rem;">
                            <strong>School of Hotel, Leisure and Restaurant Management</strong><br>
                            local 7110 (Friedenshaus Hotel Admin Office)
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }
    .logo{
        width: 200px;
        height: 200px;
        margin-right: 10px;
    }
    .box-card{
        width: 350px;
        overflow: hidden;
        padding: 0;
    }
    .alumni-img{
        width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
        border-radius: 8px;
    }
    .el-collapse-item__wrap{
        overflow-x: scroll;
    }
</style>
<script>
    import NavbarStatic from "../../../../components/general/NavbarStatic";
    import SiteHeader from "../../../../components/general/SiteHeader";
    import SiteFooter from "../../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>