<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="container">
            <div class="header">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial; margin-bottom: 50px;"><strong>GRADUATE SCHOOL</strong></h1>
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial"><strong><i>ABOUT OUR GRADUATE SCHOOL PROGRAMS</i></strong></h1>
                <p style="color: #012376; font-size: 1.5rem; font-family: Arial">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>

            <div class="school-section">
                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Humanities major in Women’s Studies (Button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Humanities major in Women’s Studies (Button)2.png\');' ">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in  Preschool Management (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in  Preschool Management (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Special Education (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Special Education (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Counseling (Button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Counseling (Button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Psychology (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Psychology (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Accountancy (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Arts in Accountancy (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Business in Business Management (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Business in Business Management (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Music in Music Education (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Music in Music Education (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Music in Piano Pedagogy (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Music in Piano Pedagogy (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>

                <div class="school-card2" :style="!isWindowOnDesktop ? 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Music in String Pedagogy (button)2.png\'); background-size: 100% 60%;' : 'background-image: url(\'https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/graduate_school_landing_page/button_links_to_masters_programs/Master of Music in String Pedagogy (button)2.png\');'">
                    <div class="backdrop2"></div>
                    <div class="content">
                        <div class="text">
                            <h5 class="card-title2">&nbsp;<br><br><br><br></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 150px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }


    .header {
        text-align: center;
        margin: 20px 0;
    }
    .header h1 {
        font-size: 2.5rem;
    }
    .header h3 {
        font-size: 1.5rem;
        margin-top: 10px;
    }
    .header p {
        font-size: 1rem;
        margin-top: 10px;
    }
    .school-section {
        margin-top: 20px;
    }
    .school-card2 {
        position: relative;
        color: white;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;
        padding: 20px;
        /*background-color: blue;*/
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: left;
    }
    .school-card2 .backdrop2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background: rgba(0, 0, 0, 0.5);*/
        /*background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));*/
        z-index: 1;
    }
    .school-card2 .content {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
    }
    .school-card2 img.logo {
        width: 175px;
        height: 175px;
        margin-right: 10px;
    }
    .school-card2 .text {
        flex: 1;
    }
    .school-card2 .card-title4 {
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        float: right;
    }
    .school-card2 .card-title5 {
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        float: right;
    }
    .school-card2 .card-title6 {
        font-size: 1.75rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        float: right;
    }
    .school-card2 .description {
        margin-top: 5px;
    }
    .school-card2 img.background {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 10;
        z-index: 1;
    }
</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>