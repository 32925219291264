<template>
    <div>
        <SiteHeader style="width: 100%;"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <div style="margin-top: 50px;">
            <div class="container" style="background-color: rgba(246, 246, 246, 0.80); padding-bottom: 50px;">
                <br>
                <center>
                    <h1 style="font-family: Arial; color: #012376;"><strong>REGISTRAR</strong></h1>
                </center>
                <br>
                <center>
                    <h3 style="font-family: Arial;"><b>ABOUT US</b></h3>
                    <br>
                </center>
                <div class="justify-content-center">
                    <span style="font-family: Arial;">
                        The Office of the Registrar provides services related to students’ academic records –
                        maintaining, updating, and preserving official student files with grades and academic decisions,
                        to audit and determine student’s eligibility to continue their studies or to graduate.
                        <br><br>
                        As the central hub on the school campus where student records are stored, the Registrar’s Office
                        is committed to its mission of providing administrative and professional services to students,
                        faculty, and administration with competence, dedication, objectivity, and efficiency.
                        <br><br>
                        Daily transactions and requests for documents from here and abroad which are received through
                        e-mail or filed personally by students and alumni are processed following the standard operating
                        procedures and time frame indicated in the section on Requests for Academic Records. Policies
                        of the Registrar’s Office, especially those which pertain to academic records and grades of
                        students, are implemented in keeping with the provisions and guidelines found in the Student
                        Manual of Information and those of the Commission on Higher Education (CHED) or the
                        Department of Education (DepEd).
                        <br><br>
                        In addition, the Office oversees student enrolment and registration as well as the preparation of
                        the different academic units for the annual graduation and completion exercises, among other
                        academic support activities.
                    </span>
                </div>
                <br>
                <br>
                <h3 style="font-family: Arial;"><b>VISION</b></h3>
                <br>
                <div class="justify-content-center">
                    <span style="font-family: Arial;">
                        The Registrar’s Office envisions full automation of the major functions of the Office to ensure
                        accurate management of records and prompt delivery of its various services to all stakeholders.
                    </span>
                </div>
                <br>
                <br>
                <h3 style="font-family: Arial;"><b>MISSION</b></h3>
                <br>
                <div class="justify-content-center">
                    <span style="font-family: Arial;">
                        The Registrar’s Office aims to improve its delivery of administrative and professional services to
                        all stakeholders promptly, accurately, and effectively through full utilization of human and
                        technological resources to manage, maintain, and update student records from admission until
                        after graduation. It also aims to further progress in its information dissemination on enrollment,
                        academic performance, and related matters to aid the administration in program evaluation and
                        development.
                    </span>
                </div>
                <br>
                <br>
                <center>
                    <div class="justify-center">
                        <table>
                            <thead>
                                <tr class="bg-muted dark:bg-primary dark:text-primary-foreground">
                                    <th colspan="2" style="border: 1px solid black; text-align: center; font-family: Arial;">Service Hours</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="border: 1px solid black; text-align: center; font-family: Arial;">MONDAY TO FRIDAY</td>
                                    <td style="border: 1px solid black; text-align: center; font-family: Arial;">From 7:00 am to 5:00 pm (No Lunch Break)</td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid black; text-align: center; font-family: Arial;">SATURDAY</td>
                                    <td style="border: 1px solid black; text-align: center; font-family: Arial;">From 7:00 am to 12:00 noon</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </center>
                <br>
                <br>
                <div class="container">
                    <h3 style="font-family: Arial;"><b>CONTACT US</b></h3>
                    <br>
                    <span style="font-family: Arial;"><b>REGISTRAR'S OFFICE</b></span><br>
                    <span style="font-family: Arial;"><b>St. Scholastica's College Manila</b></span><br>
                    <span style="font-family: Arial;"><i>2560 Leon Guinto Street,</i></span><br>
                    <span style="font-family: Arial;"><i>Malate, Metro Manila</i></span><br>
                    <span style="font-family: Arial;"><i>PHILIPPINES 1004</i></span><br>
                    <br>
                    <span style="font-family: Arial;"><b>TRUNK LINE:</b><i>(632) 8567-7686, local 8167 (College & Graduate School) / 8165 (Basic Education)</i></span><br>
                    <span style="font-family: Arial;"><b>MOBILE:</b><i>09278107384</i></span><br>
                    <span style="font-family: Arial;"><b>EMAIL:</b><i>sscregistrar@ssc.edu.ph</i></span><br>
                    <br>
                    <span style="font-family: Arial;">Note: Please observe office hours for texts and calls.</span>
                </div>
                <br>
                <br>
                <center>
                    <h3 style="font-family: Arial;"><b>REGISTRAR AND STAFF</b></h3>
                    <br>
                    <br>

                    <span style="font-family: Arial;"><h5><b>Ms. Ann Joan Hope V. Caparas</b></h5></span>
                    <span style="font-family: Arial;"><h5>Registrar</h5></span>
                    <br>
                    <span style="font-family: Arial;"><h5><b>Ms. Maria Agnes P. Cabig</b></h5></span>
                    <span style="font-family: Arial;"><h5>Grade School Evaluator / Department of Education Liaison Officer</h5></span>
                    <br>
                    <span style="font-family: Arial;"><h5><b>Ms. Elda G. Cruz</b></h5></span>
                    <span style="font-family: Arial;"><h5>High School Evaluator</h5></span>
                    <br>
                    <span style="font-family: Arial;"><h5><b>Mr. Robert Q. Famahelin</b></h5></span>
                    <span style="font-family: Arial;"><h5>College and Graduate School Evaluator / CHED & Bureau of Immigration Liaison Officer</h5></span>
                    <br>
                    <span style="font-family: Arial;"><h5><b>Ms. Marie Elizabeth G. Raval</b></h5></span>
                    <span style="font-family: Arial;"><h5>College Evaluator</h5></span>
                </center>
                <br>
                <br>
                <el-collapse>
                    <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff;">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;REQUEST FOR ACADEMIC RECORDS</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <span style="font-family: Arial; font-size: 1.25rem;"><b>POLICIES AND PROCEDURES</b></span>
                            <br><br>
                            <span style="font-family: Arial;"><b>Requests for documents may be made onsite or by email to the following:</b></span>
                            <br><br>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>Grade School</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>agnes.cabig@ssc.edu.ph</b></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>High School</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>eldacruz@ssc.edu.ph</b></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;">
                                            <b>
                                            College
                                            <br>
                                            Music
                                            <br>
                                            Accountancy, Biology, Business
                                            Administration, Communication, Education,
                                            Fine Arts, Hospitality Management,
                                            Information Technology, Interior Design,
                                            Political Science, Psychology, Tourism
                                            Management
                                            </b>
                                        </td>
                                        <td style="border: 1px solid black; font-family: Arial;">
                                            <b>
                                            rqfamahelin@ssc.edu.ph
                                            <br>
                                            marieelizabeth.raval@ssc.edu.ph
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>Graduate School</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>rqfamahelin@ssc.edu.ph</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br>
                        <br>
                        <div style="width: 120%;">
                            <span style="font-family: Arial;">A notarized affidavit of loss must be submitted by those requesting for a second copy of the diploma.</span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">Since the student’s signature is required before any record is released by the office, requests should be done personally or by email with the signature and valid ID of the student/graduate.</span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">If requests are not made personally by the student, an authorization letter is required together with the following information which must also be included in emailed requests:</span>
                            <br>
                            <br>
                            <ol style="font-family: Arial;">
                                <li>FULL NAME used while in school</li>
                                <li>Student ID No., if applicable</li>
                                <li>Years of attendance</li>
                                <li>Course taken/obtained</li>
                                <li>Date of Graduation, if applicable</li>
                                <li>Signature</li>
                                <li>Number of copies requested</li>
                                <li>Scanned copy of original transcript / diploma, if applicable</li>
                                <li>Complete address to which documents are to be sent, if applicable</li>
                                <li>Special instructions for delivery (i.e., courier delivery, or for pickup, signed/seal envelopes, etc.) Only an authorized representative may pick up the requested document.</li>
                                <li>Payment may be made in cash or deposited to St. Scholastica’s College bank account. The account numbers will be provided in response to your emailed requests.</li>
                            </ol>
                            <br>
                            <br>
                            <span style="font-family: Arial; font-size: 1.25rem;"><b>PROCESSING TIME</b></span><br>
                            <span style="font-family: Arial;">Under normal circumstances, processing takes about (7) working days for transcript of records and three (3) days for certifications. Second copy requests for diploma require at least seven (7) days to complete the signatures on the document.</span>
                        </div>
                        <br>
                        <br>
                        <div style="width: 120%;">
                            <span style="font-family: Arial; font-size: 1.25rem;"><b>NEW RATES FOR DOCUMENT REQUESTS</b></span><br>
                            <span style="font-family: Arial;">EFFECTIVE NOVEMBER 2, 2022</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th style="border: 1px solid black; font-family: Arial; text-align: center;"><b>DOCUMENTS</b></th>
                                        <th style="border: 1px solid black; font-family: Arial; text-align: center;"><b>RATES(IN PESOS)</b></th>
                                        <th style="border: 1px solid black; font-family: Arial; text-align: center;"><b>PROCESSING TIME</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>TRANSCRIPT OF RECORDS (TOR)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>Unofficial: College (all levels)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;">100.00 (with CTC)</td>
                                        <td style="border: 1px solid black; font-family: Arial;">2-3 working days</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>Basic Education</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;">100.00</td>
                                        <td style="border: 1px solid black; font-family: Arial;">3-5 working days</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;">
                                            <b>OFFICIAL:</b><br>
                                            for evaluation purposes only
                                            personal copy/for employment purposes
                                            for Board Examination/PRC purposes
                                            <br><br>
                                            <b>RUSH processing</b>(Please confirm this when you make your request)
                                        </td>
                                        <td style="border: 1px solid black; font-family: Arial;">
                                            400.00<br>
                                            Plus 250.00
                                        </td>
                                        <td style="border: 1px solid black; font-family: Arial;">
                                            7 WORKING DAYS
                                            <br>
                                            2 to 3 WORKING DAYS
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>Form 137/SF10 (Grade School/High School transcript)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;">400.00</td>
                                        <td style="border: 1px solid black; font-family: Arial;">7 working days</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>TRANSCRIPT OF RECORDS (TOR)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;">
                                            <br>
                                            <ul style="list-style: none;">
                                                <li>Graduation</li>
                                                <li>Candidate for Graduation</li>
                                                <li>Units earned</li>
                                                <li>Enrollment</li>
                                                <li>Grading System</li>
                                                <li>Grade Equivalent</li>
                                                <li>Academic Standing/Grade Point Average</li>
                                                <li>English as Medium of Instruction</li>
                                                <li>Completion</li>
                                                <li>Ranking/Honor</li>
                                            </ul>
                                        </td>
                                        <td style="border: 1px solid black; font-family: Arial; align-items: center;">100.00 (Plus PHP30.00 for documentary stamps)</td>
                                        <td style="border: 1px solid black; font-family: Arial;">3-5 working days</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>TEACHER’S CERTIFICATE</b>(for Cognate Course completers)</td>
                                        <td style="border: 1px solid black; font-family: Arial;">150.00</td>
                                        <td style="border: 1px solid black; font-family: Arial; align-items: center;">7 working days depending on the availability of signatories</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>CERTIFIED TRUE COPY with dry seal (original copy needed)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;">75.00</td>
                                        <td style="border: 1px solid black; font-family: Arial;">SAME DAY</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>CERTIFICATION, AUTHENTICATION, VERIFICATION (CAV-CHED) (TOR/diploma; inclusive of certificate of units earned)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial; align-items: center;">850.00 (Plus, PHP80 for CHED)</td>
                                        <td style="border: 1px solid black; font-family: Arial;">2 WEEKS</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>(CAV-DEPED) (SF10/F137, certificate of graduation/enrollment)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">(Plus, PHP100 for Qatar)</td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>2ND COPY OF DIPLOMA (submission of affidavit of loss required)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">580.00 (College) 550.00 (Basic Education)</td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">minimum of 7 working days depending on the availability of signatories</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>3RD COPY OF DIPLOMA (submission of affidavit of loss required)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">1,000.00</td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">minimum of 7 working days depending on the availability of signatories</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>COURSE DESCRIPTION</b></td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">50.00/page (Plus, PHP30 for documentary stamps)</td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">minimum of 7 working days</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>TRANSFER CREDENTIALS</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>BASIC EDUCATION</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;">400.00</td>
                                        <td style="border: 1px solid black; font-family: Arial;">7 working days</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>BASIC EDUCATION</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;">400.00</td>
                                        <td style="border: 1px solid black; font-family: Arial;">minimum of 7 working days</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;" colspan="2"><b>COURIER DELIVERY CHARGES (Subject to change without prior notice)</b></td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>INTERNATIONAL</b></td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">varies according to recipient’s address</td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; font-family: Arial;"><b>LOCAL</b></td>
                                        <td style="border: 1px solid black; font-family: Arial; text-align: center;">starts at 200.00</td>
                                        <td style="border: 1px solid black; font-family: Arial;"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-collapse-item>
                    <br>
                    <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff;">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;REGISTRATION/ENROLLMENT POLICIES</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div style="width: 150%;">
                            <span style="font-family: Arial;">
                                Enrolment of students is conducted during registration days indicated in the school calendar
                                approved by CHED and disseminated to students by the Registrar’s Office. Once admitted,
                                students enjoy the right to enroll and stay until the end of the term or until graduation. Therefore,
                                except for cases of academic deficiency, violation of school rules and regulations, or failure to
                                pay school fees, qualified students have the right to stay for the entire period in which they are
                                expected to complete the course in the school, without prejudice to their right to transfer to other
                                schools within the prescribed period.
                            </span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">
                                Students should enroll during the official registration period. Unless extended, no enrolment shall be allowed two (2) weeks after the first day of classes.
                            </span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">
                                For problem-free enrolment/registration, please enroll on time and on the day assigned to your level.
                            </span>
                            <br>
                            <br>

                            <span style="font-family: Arial; font-size: 1.07rem;"><u>Enrolment of students is covered by the following policies:</u></span>
                            <br>
                            <ol style="font-family: Arial;">
                                <li>
                                    An <b>OLD STUDENT</b> shall be allowed to enroll if s/he had been cleared for enrolment:
                                    <ul style="list-style: none;">
                                        <li>a. Grades in all subjects taken the previous semester have been encoded submitted</li>
                                        <li>b. Subject/s with prerequisites that have W and/or F grades have been replaced by other subjects</li>
                                        <li>c. All accountabilities with the Business / Registrar’s / Student Affairs Office, the Library and/or other offices have been settled.</li>
                                    </ul>
                                </li>
                                <li>A <b>NEW STUDENT</b> can only enroll after submission of all required admissions credentials.</li>
                                <li>Enrolment of <b>RETURNEES</b> is allowed only after they have filed their application for re-admission which is then subsequently approved by their Principal or Academic Dean.</li>
                                <li>Prior to their enrolment, <b>TRANSFEREES</b> are required to complete and submit all admissions requirements particularly their Transfer Credentials from the previous school.</li>
                            </ol>
                            <br>
                            <br>
                            <span style="font-family: Arial; font-size: 1.07rem;">
                                <b>
                                   Students are considered officially enrolled only upon payment of fees made onsite
                                   or verification of online payment made, and for the College Unit, the validation of their student
                                   IDs.
                                </b>
                            </span>
                            <br>
                            <br>
                            <span style="font-family: Arial; font-size: 1.07rem;"><u>Other Related Policies and Procedures:</u></span>
                            <br>
                            <ol style="font-family: Arial;">
                                <li>
                                    <b>First Come, First Served Policy</b><br>
                                    GE Classes allow a maximum number of 35/40 students per class. If a block/section is
                                    small, other students from other sections/levels who need to take the same subject may
                                    be assigned to the same section. Enrolment on a later date may result in the loss of one’s

                                    slot which means the student may have to attend the subject with another section or in
                                    the next semester.
                                </li>
                                <li>
                                    <b>Checking of Attendance Begins on the First Day of Classes</b><br>
                                    a. Only those who are officially enrolled will be allowed to attend classes.
                                    b. All those who do not attend on the first days of classes will already be marked
                                    ABSENT.
                                </li>
                                <li>
                                    <b>Surcharge of PHP 500.00 for Late Enrollment</b><br>
                                    Those who enroll after official enrolment days may be required to pay a late enrollment
                                    fee of PhP500.00.
                                </li>
                                <li>
                                    <b>Cross Enrolment Procedure</b><br>
                                    4.1 Secure a letter/permit to cross enroll from the Registrar’s Office of your home school.
                                    <br><br>
                                    4.2 Obtain the needed endorsement from your Department Chairperson/Academic Dean
                                    and Registrar.
                                    <br><br>
                                    4.3 Present your letter/permit to cross enroll to the Registrar of St. Scholastica’s College
                                    (your Host School) for acceptance.
                                    <br><br>
                                    4.4 Pay the required fees at the Business Office.
                                    (Cross enrollees from SMEC-member schools who pay the home school rate must bring
                                    a copy of the schedule of fees reflecting the cost per unit of their school; cross enrollees
                                    from other schools pay the host school rate.)
                                    <br><br>
                                    4.5 Present your receipt at the Registrar’s Office to secure your Registration Card.
                                </li>
                            </ol>

                            <br>
                            <br>
                            <span style="font-family: Arial; font-size: 1.07rem;">
                                Students of any of the consortium schools in the South Manila Education Consortium (SMEC -
                                formerly SMI-IC) or Consortium of Benedictine Colleges in Metro Manila (CBCM) can easily cross
                                enroll at SSC.
                            </span>

                            <br>
                            <br>
                            <center>
                                <span style="font-family: Arial; font-size: 1.07rem;"><b>South Manila Educational Consortium Member Schools</b></span>
                                <br>
                                <br>
                                <div class="row justify-content-center">
                                    <div class="col-md-4">
                                        <ul style="list-style: none;">
                                            <li>Adamson University</li>
                                            <li>De La Salle University</li>
                                            <li>De La Salle-College of St. Benilde</li>
                                            <li>Emilio Aguinaldo College</li>
                                            <li>Lyceum of the Philippines University</li>
                                            <li>Mapua University</li>
                                            <li>Philippine Christian University</li>
                                            <li>Pamantasang Lungsod ng Maynila</li>
                                            <li>Philippine Normal University</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <ul style="list-style: none;">
                                            <li>Philippine Women’s University</li>
                                            <li>St. Paul University Manila</li>
                                            <li>St. Scholastica’s College</li>
                                            <li>Sta. Isabel College Manila</li>
                                            <li>Technological University of the Philippines</li>
                                            <li>University of the Philippines Manila</li>
                                        </ul>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <span style="font-family: Arial; font-size: 1.07rem;"><b>Consortium of Benedictine Colleges in Metro Manila</b></span>
                                <ul style="list-style: none;">
                                    <li>San Beda College Alabang</li>
                                    <li>San Beda College Mendiola</li>
                                    <li>St. Scholastica’s College Manila</li>
                                </ul>
                            </center>
                            <br>
                            <br>
                            <span style="font-family: Arial;"><b>5. Policies Regarding Payment of Fees and Penalties</b></span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">5.1 Upon registration, a student pays for tuition and other fees for the semester, either in full<br>
                                (cash, credit card, check) or on instalment basis (cash, check, or credit card plus post-dated<br>
                                checks with a fully accomplished PDC Warranty Form).
                            </span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">
                                5.2 Students with a load of not more than 12 units for the semester are required to pay in full<br>
                                upon enrolment.
                            </span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">
                                5.3 A surcharge of PHP 500.00 may be collected from students who enroll beyond the<br>
                                specified official enrolment period.
                            </span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">
                                5.4 When students register, it is understood that they are enrolling for the entire semester.
                            </span>
                            <br>
                            <br>
                            <span style="font-family: Arial;">
                                5.5 Withdrawal of enrolment is subject to penalty:
                            </span>
                            <br>
                            <br>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center;"><b>BEFORE</b> the opening of classes</td>
                                        <td style="border: 1px solid black; text-align: center;">Php P2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center;">Within the <b>FIRST</b> week of classes</td>
                                        <td style="border: 1px solid black; text-align: center;">10% of total school fees</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center;">Within the <b>SECOND</b> week of classes</td>
                                        <td style="border: 1px solid black; text-align: center;">20% of total school fees</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; text-align: center;"><b>AFTER</b> the second week of classes</td>
                                        <td style="border: 1px solid black; text-align: center;">100% of total school fees</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <br>
                            <span style="font-family: Arial;">
                                5.6 Report cards, official, and unofficial transcripts of records will not be released to students<br>
                                with back accounts or outstanding balance. The viewing of online grades will also be<br>
                                adversely affected.
                            </span>
                        </div>
                    </el-collapse-item>
                    <br>
                    <el-collapse-item name="3" style="background-color: #012376; text-color: #ffffff;">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;FAQs on ENROLLMENT</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div style="padding-left: 75px;">
                            <ol style="font-family: Arial; padding: 0%; width: 200%;">
                                <li>
                                    <b>How do I Enroll?</b>
                                    <br>
                                    <br>
                                    For online enrollment, you will need to go to the school website (www.ssc.edu.ph) and log in to your MySSC-IS account to access the online enrollment portal.<br>
                                    There you will read and submit the DPA consent form, accomplish or update the personal information and health survey forms,<br>
                                    after which you can view your assessment, enlist, and then pay for your enrollment. Upon successful verification of your payment,<br>
                                    you will receive an enrollment confirmation, after which you may view your class schedule or section when these are available.<br>
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>What number do I call if I have questions on enrollment?</b>
                                    <br>
                                    <br>
                                    Call 8567-7686 local 8165 to 8167 to reach the Registrar’s Office. For specific concerns, you may dial the following:
                                    <ul>
                                        <li>Grade School 8165</li>
                                        <li>High School 8165</li>
                                        <li>College 8167</li>
                                        <li>Graduate School 8167</li>
                                    </ul>
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>What is academic advising for? Who needs to go through it?</b>
                                    <br>
                                    <br>
                                    Academic advising is a pre-registration requirement for students in the College Unit, especially those who are irregular, those who may not be<br>
                                    taking the regular curriculum load, and those with incomplete grades in the previous semester. It is being done to give you and your<br>
                                    department chair or academic dean the chance to discuss your academic performance and determine the<br>
                                    correctness of your subject lineup before enrolment.
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>Why can’t I view my assessment online?</b>
                                    <br>
                                    <br>
                                    It is likely to be due to problems with your clearance. Make sure that you have been cleared by all offices.
                                    <br>
                                    Here are contact details for assistance with your clearance:
                                    <br>
                                    <br>
                                    <ul>
                                        <li>Grade School Office: 8567-7686 local 4498</li>
                                        <li>High School Office: 8567-7686 local 3398</li>
                                        <li>Student Affairs Office: 8567-7686 local 2227</li>
                                        <li>Registrar’s Office: 8567-7686 local 8165 and 8167</li>
                                        <li>Business Office: 8567-7686 local 8174</li>
                                        <li>Faculty Evaluation: ltlindaya@ssc.edu.ph</li>
                                    </ul>
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>What are the payment modes?</b>
                                    <br>
                                    <br>
                                    The modes of payment are full and installment and may be made through bank deposit, through online payment by fund transfer or via<br>
                                    the Paymongo payment gateway, or onsite at the Business Office through cash, check, or credit card. Post-dated checks (PDCs) are required<br>
                                    for the installment mode. A fully accomplished PDC information slip is also required.
                                    <br>
                                    <br>
                                    Students enrolling for 12 units or less are required to pay in full.
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>How do I pay online?</b>
                                    <br>
                                    <br>
                                    You may pay online through fund transfer (BDO & BPI) or through Paymongo which accommodates credit card payments among others.<br>
                                    If you choose the Paymongo option, please request for the payment link by sending an email to businessoffice@ssc.edu.ph or calling<br>
                                    8567-7686 local 8174.
                                    <br>
                                    <br>
                                    After paying online, upload your proof of payment through the online enrollment portal. Payment verification may take 1-2 banking days<br>
                                    for Metro Manila branches and 3-5 banking days for remittances from abroad. Your official receipt number will be displayed on the<br>
                                    Interface when payment has been verified.
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>How do I fill out a check?</b>
                                    <br>
                                    <br>
                                    <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/fill_out_check_no7.png'" style="height: 75%; width: 75%;">
                                    <br>
                                    <br>
                                    Submit postdated checks (PDCs) to the Business Office together with the accomplished PDC<br>
                                    Information Slip that may be downloaded from the Online Enrollment Portal.
                                    <br>
                                    <br>
                                    PDCs may be hand-carried or sent thru courier to:<br>
                                    <b>THE TREASURER, St. Scholastica’s College, 2560 Leon Guinto St., Malate, Manila</b>
                                    <br>
                                    <br>
                                    Submission of PDCs is necessary to complete the enrollment process of installment payers.
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>What is the effect of an unpaid account on my enrollment?</b>
                                    <br>
                                    <br>
                                    Students with unpaid accounts will not be allowed to enroll unless their accounts are settled in full.
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>What will happen if a check is returned or dishonored by the bank?</b>
                                    <br>
                                    <br>
                                    The Business Office will automatically cancel the official receipt and the enrollment of students whose checks are returned/dishonored by the bank.<br>
                                    Such checks may be replaced by cash or credit card payment or by manager’s checks. Transactions shall be made only at the Cashier’s Window of the Business Office.
                                    <br>
                                    <br>
                                    A penalty equivalent to 3% of the amount of the returned check will be imposed. This check will not be redeposited.
                                    <br>
                                    <br>
                                </li>
                                <li>
                                    <b>Is onsite enrollment still an option?</b>
                                    <br>
                                    <br>
                                    You are strongly encouraged to enroll online, but if you do opt to pay onsite, you will be accommodated. And since there are steps in the<br>
                                    enrollment procedure that can only be done online, such as the submission of the DPA consent form, the accomplishment of the personal<br>
                                    information and health survey forms, the viewing of your assessment and enlistment, on-site payers will still need online enrollment access.<br>
                                    It will then be a combination of online and on-site enrollment.
                                    <br>
                                    <br>
                                </li>
                            </ol>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div style="margin-top: 20px; margin-bottom: 20px;">
            <div class="row" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" style="padding-right: 0px;">
                    &nbsp;
                </div>
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                    <button @click="scrollToTop()" class="btn btn-ssa pull-right"><span style="font-family: Arial; font-size: 2rem; color: #ffffff;"><strong><i class="fa fa-angle-double-up" aria-hidden="true"></i>Back to Top</strong></span></button>
                </div>
            </div>
        </div>

        <div style="margin-top: 75px;">
            &nbsp;
        </div>
        <SiteFooter></SiteFooter>
    </div>
</template>
<style>
    .el-collapse-item__header{
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        background-color: #012376;
        color: #ffffff;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        font-size: 13px;
        font-weight: 500;
        transition: border-bottom-color .3s;
        outline: 0;
    }
    .el-collapse-item__content {
        padding-bottom: 25px;
        font-size: 13px;
        color: #303133;
        line-height: 1.769230769230769;
        margin-left: 25px;
    }
</style>
<script>
    import SiteHeader from "../../../components/general/SiteHeader";
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {

        },
        components: { SiteHeader,NavbarStatic,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {
            this.addSsaColorClass();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            addSsaColorClass(){
                $(".el-collapse-item__header").css({
                    "background-color": "#012376",
                    "color": "#ffffff"
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>
