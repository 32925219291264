<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="container my-5">
            <div class="text-center mb-4">
                <h1 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>HIGH SCHOOL</strong></h1>
                <p style="color: #012376; font-family: Arial; font-size: 1.25rem;">
                    Anchored on the thrust of academic excellence as social responsibility, the High School
                    Scholasticans imbibe Ora et Labora, which means Prayer and Work, to ensure that all
                    the work that they do also serves as a form of prayer to God, and as a commitment to be a
                    positive change in society, especially to the poor and marginalized.
                    <br>
                    <br>
                    The Junior and Senior High School Units are committed to holistic student formation,
                    emphasizing that education extends beyond academics to include the cultivation of talents
                    and skills, the fostering of strong relationships with God, others, and self, and the
                    development of social responsibility.
                </p>
            </div>
            <div class="row" style="margin-top: 200px; position: relative;">
                <div class="col-md-6">
                    <h1 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>About our High School Program</strong></h1>
                    <br><br>
                    <h4 style="color: #012376; font-family: Arial; font-size: 1.25rem;"><strong>Junior High School</strong></h4>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;">
                        With its PAASCU Level III Accreditation, the Junior High School Unit is progressively
                        enhancing its curriculum and instruction through a Blended Learning approach anchored in
                        the 5E Instructional Model. This model empowers students to become self-regulated
                        learners, discovering and rediscovering their potential across various disciplines through
                        engaging subject-area challenges. Besides complying to the DepEd standards, the JHS Unit
                        also offers other programs based on the students' needs and interests:
                        <br>
                        <br>
                        <ul>
                            <li>Robotics - an enrichment subject under Science</li>
                            <li>Special Filipino and Araling Panlipunan - a customized program for foreign students</li>
                            <li>
                                Advanced Learners in Math and Science (ALMS) - a block section with more challenging approaches, and also serves as a training pool for Math and Science competitions
                            </li>
                        </ul>
                        <br>
                        Moreover, the High School Unit’s Religion Extension Program (REP) is part of the unit's
                        holistic education. It enables the students to live out the See-Judge-Act pastoral cycle by
                        integrating different subjects in their interaction with the school’s partner communities,
                        learning through observation, interaction, and immersion.
                    </p>
                    <h4 style="color: #012376; font-family: Arial; font-size: 1.25rem;"><strong>Senior High School</strong></h4>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;">
                        Recognizing the value of truth, research, and media literacy, the Senior High School Unit
                        develops students' critical thinking skills through its core, contextualized, formation, and
                        specialized subjects. Incorporating Blended Learning through 5E with the  Social Change
                        Model of Leadership Development framework, each subject plays a crucial role in becoming
                        a true Christ-centered servant leader by leading one's self, peers, and society.
                        <br>
                        With the developmental progression of the REP activities in their Junior HS, the
                        Scholasticans utilize their gained knowledge, skills, values, and attitudes through their
                        chosen Senior HS strands. The completion of the REP is Social Change Leadership (SCL),
                        which is incorporated in each strand’s culminating project:
                        <br>
                        <br>
                        <ul>
                            <li>STEM: A capstone grassroots innovation project addressing a community or institutional need.</li>
                            <li>ABM: A social enterprise mini-company developing a product committed to a community's sustainable development.</li>
                            <li>HUMSS: A short film production addressing a relevant social issue within a community.</li>
                            <li>GAS: A community-focused project or program promoting mental health awareness.</li>
                            <li>Visual Arts: Art exhibits showcasing self-expression and the power of visual arts in society.</li>
                            <li>Music: A self-produced recital demonstrating the value of music through a series of performances.</li>
                        </ul>
                        <br>
                        From these programs of the Junior and Senior High School, the Scholasticans enact
                        the school’s vision and mission as they realize how even as students, they can make a
                        difference in society.
                    </p>
                </div>
                <div class="col-md-6" style="margin-top: 200px;">
                    <div class="blue-shape"></div>
                    <div class="image-container">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/high_school_page/hs1.jpg'" alt="Image 1" class="image image1">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/high_school_page/hs2.jpg'" alt="Image 2" class="image image2">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/high_school_page/hs3.jpg'" alt="Image 3" class="image image3">
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 200px;">
            <center>
                <h1 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>OUR FACILITIES</strong></h1>
                <br><br>
                <el-carousel type="card" height="500px" :autoplay="false">
                    <el-carousel-item v-for="res in resources" :key="item">
                        <img :src="res" style="height: 500px; width: 500px;">
                    </el-carousel-item>
                </el-carousel>
            </center>
        </div>

        <div style="margin-top: 150px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .blue-shape {
        position: absolute;
        width: 500px;
        height: 500px;
        background-color: #002d72;
        transform: rotate(45deg);
        top: 100%;
        right: 10%;
        z-index: 0;
        left: auto;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 50%; /* Maintain aspect ratio */
    }

    .image {
        position: absolute;
        border: 5px solid white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease;
    }

    .image1 {
        top: -30%;
        left: 60%;
        width: 60%;
        transform: rotate(-5deg);
        z-index: 2;
    }

    .image2 {
        top: 30%;
        left: 10%;
        width: 60%;
        z-index: 3;
    }

    .image3 {
        top: 95%;
        left: 75%;
        width: 60%;
        transform: rotate(5deg);
        z-index: 3;
    }

    .image:hover {
        transform: scale(1.05);
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Cover the container */
        border: 2px solid white;
        border-radius: 5px;
    }


</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp
            }
        },
        mounted() {

        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>