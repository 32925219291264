<template>
    <div>
        <SiteHeader style="width: 100%;"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <div style="margin-top: 50px;">
            <center><h1 class="ssa-text-color" style="font-family: Arial; font-size: 3rem;"><strong>INSTITUTIONAL OFFICE</strong></h1></center>
        </div>
        <div style="margin-top: 50px;">
            <div class="row" style="margin-left: 15px;">
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ARCHIVES OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Our Mission and Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">1. Function 1</span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">2. Function 2</span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">3. Function 3</span>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>archives@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local ####)</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="3" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ADMINISTRATIVE AND GENERAL SERVICES OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;AGSO envisions a safe, comfortable, clean, green, orderly and well-maintained learning and
                                    working environment adhering to the architectural integrity of the Institution.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Administrative and General Services Office (AGSO) provides prompt and efficient delivery
                                    of services in housekeeping, plumbing, carpentry and electrical needs of the Institution. The
                                    AGSO is likewise tasked to oversee and supervise the school’s preventive maintenance
                                    program and assist in the various outsourced building construction projects.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ol style="font-family: Arial;" class="ssa-text-color">
                                            <li>Prepare work plans with work schedules for housekeeping, plumbing, carpentry and electrical services</li>
                                            <li>Provide prompt and efficient delivery of AGSO services in the preparation and implementation of scheduled activities and special events</li>
                                            <li>Assist in outsourced projects of the various units and departments</li>
                                            <li>Implement policies on the waste management program of the school</li>
                                            <li>Draw up and implement a preventive maintenance program</li>
                                            <li>Take responsibility for all keys to classrooms, offices, vehicles and special facilities</li>
                                            <li>Exercise stewardship in the management of equipment, tools and supplies in the delivery of AGSO services</li>
                                            <li>Draw up a plan to secure regular services of professionals to address structural, mechanical and electrical requirements of the Institution in coordination with the AGSO personnel</li>
                                        </ol>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>agso@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local 8158 and 8159)</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="5" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ADMISSIONS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Admissions Office envisions itself as a provider of
                                    quality service in the intensive but selective recruitment
                                    and admission of students for the various academic units
                                    in accordance with the standards of the school. It is
                                    committed to achieving the school’s enrollment goals for
                                    new students while living up to the school’s Benedictine
                                    tradition of service to God and community.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Admissions Office dedicates itself in attracting,
                                    recruiting and admitting students who are promising in
                                    their academic, leadership and service orientation
                                    pursuits through providing the highest quality of service
                                    that fosters a customer-centric atmosphere, respectful
                                    and efficient professional services meant to assist and
                                    guide prospective and current students and their families
                                    as they go through the admissions process and explore
                                    the learning opportunities that St. Scholastica’s College
                                    Manila can offer.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Admissions and Recruitment</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>sscadmit@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-mobile"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>0968 610 9119</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>(02) 8567 7686 loc. 8169</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="7" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;BUSINESS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="9" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CAREER AND PLACEMENT OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <center><span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>ST. SCHOLASTICA’S COLLEGE MANILA CAREER AND PLACEMENT OFFICE MANUAL OF OPERATIONS</strong></span></center>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Introduction</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Career and Placement Office is one of the support offices
                                    of the College Unit. Its main task is to address the pre-
                                    employment and employment needs of graduating students
                                    and alumnae. It also considers as one of the major functions
                                    networking with the different market sectors for the needed
                                    feedback that will assist College administration in curricular
                                    improvement and the development of the student’s
                                    competency needed in the workplace.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Mission Statement</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Career and Placement Office of St. Scholastica’s College
                                    Manila is committed to assist by facilitating programs, create
                                    relationship and imbued with Benedictine values to connect
                                    students with the different market sectors and generate
                                    internship and full time work opportunities.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;To provide innovative career services filled with Benedictine
                                    values to equip students for lifelong career development and
                                    prepare them to become agents of change.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Goals</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    <ol style="font-family: Arial;" class="ssa-text-color">
                                        <li>
                                            To educate and assist students in their career exploration, identification, pursuit and integration of Benedictine, personal and professional career goals.
                                        </li>
                                        <li>
                                            To teach / train students effective career strategies, skills and techniques.
                                        </li>
                                        <li>
                                            To inform students about career options, job market trends, and workplace paradigms.
                                        </li>
                                        <li>
                                            To identify networking with the different market sectors for internship and future employment opportunities by developing effective long-term relationships with the different market sectors.
                                        </li>
                                        <li>
                                            To enhance services and continue to develop appropriate strategies as per needs of the student and the market sector.
                                        </li>
                                    </ol>
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Career and Placement Office</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    <ol style="font-family: Arial; list-style-type: upper-alpha;" class="ssa-text-color">
                                        <li>
                                            <b>OBJECTIVES OF THE CAREER AND PLACEMENT OFFICE</b>
                                            <br>
                                            <br>
                                            The Career and Placement Office is one of the support services of the College Unit.
                                            Its main task is to address the pre-employment and employment needs of the graduating
                                            students and alumnae.
                                            <br>
                                            <br>
                                            The objectives of the Career and Placement Office are the following:
                                            <ol style="font-family: Arial; list-style-type: lower-alpha;" class="ssa-text-color">
                                                <li>
                                                    To establish an effective, dynamic and functional linkage between the school and industry;
                                                </li>
                                                <li>
                                                    To assist graduating students / alumnae in their employment needs and facilitate their entry into the world of work;
                                                </li>
                                                <li>
                                                    To disseminate to graduating students / alumnae information on the job openings and requirements of the different companies;
                                                </li>
                                                <li>
                                                    To coordinate with the different Practicum Coordinators on areas needing the assistance of the Career and Placement Officer;
                                                </li>
                                                <li>
                                                    To conduct follow-up studies of the graduates in coordination with the Alumnae, Guidance and Research and Academic Development Office; and
                                                </li>
                                                <li>
                                                    To schedule annual career talks and job fairs for the graduating students, third year students and alumnae.
                                                </li>
                                            </ol>
                                        </li>
                                        <br>
                                        <li>
                                            <b>JOB PLACEMENT PROGRAMS / SERVICES</b>
                                            <br>
                                            <br>
                                            In line with the objectives, the Career and Placement Office provides the following services to its stakeholders:
                                            <br>
                                            <br>

                                            <b>Job Vacancies/OJT Requests</b> - The Career and Placement Office receives information regarding job openings,
                                            OJT requests and even post-graduate studies for students and alumnae who are interested.
                                            The openings are posted in the bulletin board for one month and will be extended upon the request of the company.
                                            OJT requests are also posted along with a sign-up sheet for the students to sign up at the same time it will be distributed
                                            to the different academic departments concerned.
                                            <br>
                                            <br>

                                            <b>Job Fair</b> - Annually, the Office schedules job fair that caters to the graduating students, alumnae and third year
                                            students looking for practicum. Normally, the schedule of the Job Fair is held every February. 
                                            Companies representing various industries are invited as part of the school’s on campus recruitment program.
                                            <br>
                                            <br>

                                            <b>Graduates CD and Directory</b> - The graduates’ CD and Directory serves as the directory of the graduating batch
                                            which companies may want to avail of as future source of potential applicants to fill job vacancies. The release of said
                                            Directory is done during the summer period right after the graduation.
                                            <br>
                                            <br>

                                            <b>Tracer/Follow-up Studies</b> - Tracer Studies are periodically conducted to determine the employment profile as well
                                            as career development of the alumnae. These studies are done in coordination with various offices such as Alumnae,
                                            Guidance and Research and Academic Development Offices (RAD).
                                            <br>
                                            <br>

                                            <b>Industry-Academe Dialogue</b> - The CPO schedules dialogues between key industry executives and school administrators.
                                            These dialogues provide the avenues for important feedback necessary to create and design relevant and updated
                                            curricular offerings.
                                        </li>
                                        <br>
                                        <li>
                                            <b>CAREER EDUCATION PROGRAMS / SERVICES</b>
                                            <br>
                                            <br>
                                            The following Career Education Programs / services are geared towards its main stakeholders, namely the students.
                                            <br>
                                            <br>

                                            <b>Career Education</b> - Graduating students undergo this orientation with the following topics: Quality and You,
                                            Your Winning Attitude at Work, Business Ethics and Basic Project Management. Career Orientation is done during the
                                            first semester of the school year. Likewise, they are given talks with regard to Financial Literacy being conducted
                                            by a Financial Consultant and Pre-Departure Orientation Seminar (PDOS) of the Philippine Overseas Employment Administration
                                            for possible career opportunities abroad. The Office also invites speakers from the different industries to share
                                            their career opportunities as well as their expectations to the fresh graduates / newly hired employees.
                                            <br>
                                            <br>

                                            <b>OJT Orientation and Mock Interview</b> - This is given to third years students prior to their deployment for their practicum.
                                            Topics discussed relate to areas such as choice for career, resume writing and corporate dressing. Mock Interviews are done by
                                            HR professionals to better prepare the students for actual interviews.
                                        </li>
                                    </ol>
                                </span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="10" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CENTER FORT CONTINUING EDUCATION AND DEVELOPMENT (CCED)</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;A leading center for continuing education for various
                                    stakeholders of St. Scholastica’s College Manila that
                                    offers life-long learning programs.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;We are committed to providing life-long education
                                    and training programs to various individuals,
                                    organizations and communities that address
                                    improved capabilities and skills for employment and
                                    self-development for citizenship and/or social responsibility.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>GOALS</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Center for Continuing Education and Development (CCED) of the College Unit aims to
                                </span>
                                <ol style="font-family: Arial;" class="ssa-text-color">
                                    <li>Provide opportunities for individuals and organizations / communities to improve one’s capabilities and competence through the special program offering.</li>
                                    <li>Establish partnership and networks with local and international organizations and academic institutions relative to the implementation of its programs.</li>
                                    <li>Develop innovative programs that will promote SSC as a lead provider of life-long learning education</li>
                                </ol>
                                <br>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>MON-FRI 7:30-4:30</li>
                                            <li>SAT 7:30-11:30</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>cced@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567-7686 loc. 2296</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CENTER FOR RESEARCH AND EVALUATION</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="11" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;FOOD SERVICES AND BOOKSTORE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="13" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;GUIDANCE AND COUNSELING OFFICES</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="13" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;FRIENDENSHAUS HOTEL SCHOOL</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;FHS as a leading Hotel School that showcases a rich
                                    German Benedictine heritage in terms of food, music and
                                    the arts  while maintaining a quality standard
                                    characterized by exceptional and personalized services
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;FHS is committed to provide a practicum venue for
                                    SHLRM students of St. Scholastica’s College Manila
                                    where the rich German tradition of the Congregation of
                                    the Missionary Benedictine Sisters of Tutzing, Germany
                                    and as advocates of peace, love for music and deep appreciation
                                    of the arts will be experienced.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>GOALS</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">The mission-vision of the FHS is realized through the following:</span>
                                <ul class="ssa-text-color" style="font-family: Arial;">
                                    <li>Be a venue for the industry immersion (practicum site) of BSHM and BSTM students of the School of Hotel;</li>
                                    <li>Leisure, and Restaurant Management of SSC where students gain hands-on experiences in various hospitality and tourism operations;</li>
                                    <li>Showcase a rich German Benedictine heritage in terms of food, music and the arts;</li>
                                    <li>Be a marketing arm of the SHLRM program as clients and hotel patrons experience the warm Benedictine hospitality through quality service.</li>
                                </ul>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>SERVICES</strong></span>
                                <ul style="list-style-type: none;" class="ssa-text-color">
                                    <li>Room Accommodation</li>
                                    <li>Dormitory for College Students and SSC Employees</li>
                                    <li>Coffee Shop</li>
                                    <li>Function Room</li>
                                    <li>Board Room</li>
                                    <li>Kitchen Demonstration Facilities</li>
                                    <li>Gym</li>
                                    <li>Spa</li>
                                    <li>Travel Desk</li>
                                    <li>Laundry</li>
                                </ul>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Staff</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Prof. Lailie Uy-Sicdawag – Hotel Director</li>
                                            <li>Ms. Carlyn Bacalla – FHS Admin Secretary</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>8:00 AM-10:00PM</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>Adminfhs@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>infofhs@ssc.edu,ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567-7686 loc.7110 or 7111</strong></span>
                                        <br>
                                        <i class="fa fa-home"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>2560 Leon Guinto Street corner Estrada Street, Malate, Maila. 1004</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="15" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;HUMAN RESOURCE & DEVELOPMENT OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="17" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INFORMATION & COMMUNICATION TECHNOLOGY OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The ICTO envisions to be an integral part of the academic and administrative thrusts
                                    toward 21st-century learning through an efficient and effective communication network and an
                                    efficient and effective school management system (e.g., student records, human resource records).
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Information and Communication Technology Office (ICTO) provides quality
                                    information technology and telecommunication services through the most cost-effective
                                    management of resources grounded on the Benedictine value of stewardship.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ol style="font-family: Arial;" class="ssa-text-color">
                                            <li>Development and Maintenance of Information Systems</li>
                                            <li>Software and Hardware Acquisition, Installation, Maintenance and Troubleshooting</li>
                                            <li>Student and Employee Services Management</li>
                                            <li>Management of Computer Laboratories</li>
                                            <li>Maintenance and Update of the SSC Website</li>
                                            <li>ICT Training and Staff Development</li>
                                        </ol>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>icto@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>ictmanager@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local 8111)</strong></span>
                                        <br>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">Online Service Desk: <a href="https://bit.ly/ICTO-OSD">bit.ly/ICTO-OSD</a></span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">Password Reset: <a href="https://bit.ly/SSCMlaForgotPassword">https://bit.ly/SSCMlaForgotPassword</a></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;MARKETING AND COMMUNICATIONS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;Our vision is to be a dynamic and influential force in promoting St. Scholastica's College Manila as a premier
                                    educational institution, producing self-fulfilled Scholastican women and men of character and integrity. We aim
                                    to showcase the Scholastican’s Filipino, Christian, and Benedictine values and inspire a passion for service,
                                    commitment to truth and justice, and the transformation of society.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Marketing and Communications Office of St. Scholastica's College Manila is dedicated to promoting and
                                    enhancing the institution's mission of providing holistic education to young women and women rooted in
                                    academic excellence and Catholic Benedictine values. We strive to engage and inform internal and external
                                    stakeholders through effective marketing and communication strategies, fostering a strong sense of community
                                    and empowering our students to become socially responsible agents of change.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Objectives</strong></span>
                                <hr>
                                <ol style="font-family: Arial;" class="ssa-text-color">
                                    <li>
                                        <b>Raise Awareness:</b> Increase awareness and understanding of St. Scholastica's College Manila among
                                        target audiences, including prospective students, parents, alumni, and the wider community, through
                                        strategic marketing and communications initiatives.
                                    </li>
                                    <li>
                                        <b>Enhance Brand Reputation:</b> Promote and enhance SSC Manila’s brand reputation by consistently
                                        communicating its unique value proposition, academic excellence, and commitment to holistic education.
                                    </li>
                                    <li>
                                        <b>Strengthen Stakeholder Engagement:</b> Foster strong connections and engagement with internal and
                                        external stakeholders, including students, faculty, staff, alumni, parents, and community partners,
                                        through effective communication channels and platforms.
                                    </li>
                                    <li>
                                        <b>Showcase Achievements and Successes:</b> Highlight the achievements, success stories, and positive
                                        impact of St. Scholastica's College Manila in shaping students into critically aware and socially
                                        responsible individuals, showcasing the institution's transformative education.
                                    </li>
                                    <li>
                                        <b>Support Recruitment and Retention:</b> Support student recruitment and retention efforts by developing
                                        and implementing targeted marketing strategies, tailored to the needs and preferences of prospective students
                                        and their families,
                                    </li>
                                    <li>
                                        <b>Cultivate a Culture of Communication:</b> Foster a culture of effective communication and collaboration
                                        within the institution, promoting transparency, inclusivity, and participation in decision-making processes.
                                    </li>
                                    <li>
                                        <b>Embrace Digital Innovation:</b> Embrace emerging technologies and digital platforms to enhance
                                        marketing and communications strategies, ensuring effective reach and engagement with diverse audiences.
                                    </li>
                                    <li>
                                        <b>Foster Environmental Stewardship:</b> Collaborate with the institution's sustainability initiatives
                                        to promote environmental stewardship and awareness among the school community, aligning with the goal of
                                        caring for Mother Earth.
                                    </li>
                                    <li>
                                        <b>Measure Impact and Effectiveness:</b> Continuously evaluate and measure the impact and effectiveness
                                        of marketing and communications efforts through data analysis, feedback mechanisms, and stakeholder.
                                    </li>
                                </ol>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>marketing @ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567-76-86 (local 8556)</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <!-- <el-collapse>
                        <el-collapse-item name="10" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CENTER FORT CONTINUING EDUCATION AND DEVELOPMENT (CCED)</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;A leading center for continuing education for various
                                    stakeholders of St. Scholastica’s College Manila that
                                    offers life-long learning programs.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;We are committed to providing life-long education
                                    and training programs to various individuals,
                                    organizations and communities that address
                                    improved capabilities and skills for employment and
                                    self-development for citizenship and/or social responsibility.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>GOALS</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Center for Continuing Education and Development (CCED) of the College Unit aims to
                                </span>
                                <ol style="font-family: Arial;" class="ssa-text-color">
                                    <li>Provide opportunities for individuals and organizations / communities to improve one’s capabilities and competence through the special program offering.</li>
                                    <li>Establish partnership and networks with local and international organizations and academic institutions relative to the implementation of its programs.</li>
                                    <li>Develop innovative programs that will promote SSC as a lead provider of life-long learning education</li>
                                </ol>
                                <br>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>MON-FRI 7:30-4:30</li>
                                            <li>SAT 7:30-11:30</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>cced@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567-7686 loc. 2296</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse> -->
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="12" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;MEDICAL & DENTAL HEALTH SERVICES</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;To conduct health care services with the highest
                                    standards of professionalism guided by love of work,
                                    commitment and utmost competence.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">&nbsp;&nbsp;We envision a healthy and health-conscious School Community.</span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Goals</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    <ol style="font-family: Arial;" class="ssa-text-color">
                                        <li>
                                            To provide health services to the school community
                                            through: annual health check-up of faculty, staff and
                                            students, emergency and first aid care, consultation and
                                            treatment of common school related illnesses and
                                            formulation of prevention programs.
                                        </li>
                                        <li>
                                            To motivate and assist students and staff to maintain
                                            and improve their health through the following health
                                            measure activities: health awareness lectures on various
                                            medical and dental concerns of the time, pamphlet
                                            distribution and bulletin board information.
                                        </li>
                                        <li>
                                            To maintain a healthy school environment through the
                                            supervision of the implementation of the regular cleaning
                                            of classrooms and surroundings, quarterly water analysis
                                            and weekly inspection of the canteen: personnel, facility,
                                            and food being served.
                                        </li>
                                        <li>
                                            To improve the health education of the community by
                                            participating in planned instruction, collaboration with
                                            teachers by providing interactive learning experiences as
                                            resource persons and preparation of instructional
                                            materials.
                                        </li>
                                        <li>
                                            To aid school community programs through medical
                                            and dental services to the community centers, participate
                                            and cooperate in school activities needing medical
                                            assistance
                                        </li>
                                    </ol>
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Services</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">The services provided by the medical and dental clinic is divided into four key areas:</span>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    <ol style="font-family: Arial;" class="ssa-text-color">
                                        <li>Outpatient Services (Medical and Dental)</li>
                                        <li>Emergency Services (Medical and Dental)</li>
                                        <li>Health Information Campaign</li>
                                        <li>Preventive Measures</li>
                                    </ol>
                                </span>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial;">The school clinic renders the following services:</span>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    <ol style="font-family: Arial;" class="ssa-text-color">
                                        <li>Annual medical and dental examination of all students.</li>
                                        <li>Out-Patient consultation</li>
                                        <li>Initial treatment of emergency cases</li>
                                        <li>Issuance of medical certificate for OJT, off campus activities, sports meet</li>
                                        <li>
                                            Health Measure Activities
                                            <ol style="font-family: Arial; list-style: lower-alpha;" class="ssa-text-color">
                                                <li>Lectures/symposia on medical concerns of the time</li>
                                                <li>Issuance of advisories on current health issues</li>
                                                <li>Bulletin board information</li>
                                            </ol>
                                        </li>
                                        <li>
                                            Preventive Measure
                                            <ol style="font-family: Arial; list-style: lower-alpha;" class="ssa-text-color">
                                                <li>Water analysis</li>
                                                <li>Chest X-ray</li>
                                                <li>Drug Test</li>
                                                <li>Vaccination</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </span>
                                <br>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <center>
                                            <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Staff</strong></span>
                                            <br>
                                            <span class="ssa-text-color" style="font-family: Arial;"><b>SCHOOL PHYSICIANS</b></span>
                                            <br>
                                            <span style="font-family: Arial;" class="ssa-text-color">
                                                Maria Michaela Fatima C. Gabriel, MD<br>
                                                Sharlyn Y. Patigas, MD<br>
                                                Asela C. Miranda, MD
                                            </span>
                                            <br>
                                            <br>
                                            <span class="ssa-text-color" style="font-family: Arial;"><b>SCHOOL DENTIST</b></span>
                                            <br>
                                            <span style="font-family: Arial;" class="ssa-text-color">
                                                Maria Luisa B. Lucero, DMD
                                            </span>
                                            <br>
                                            <br>
                                            <span class="ssa-text-color" style="font-family: Arial;"><b>SCHOOL NURSES</b></span>
                                            <br>
                                            <span style="font-family: Arial;" class="ssa-text-color">
                                                Maria Nape A. Alonzo, RN<br>
                                                Mary Loraine V. Navasquez, RN
                                            </span>
                                        </center>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Monday - Friday: 7:30 AM - 5:30 PM</li>
                                            <li>Saturday: 7:00 AM - 4:00 PM</li>
                                        </ul>
                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Gallery</strong></span>
                                        <div class="row">
                                            <div class="col-md-8">
                                                <a href="#" @click.prevent="showGallery(resources.medical_and_dental)">
                                                    <img :src="resources.medical_and_dental.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;PLANNING AND QUALITY ASSURANCE OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Planning and Quality Assurance Office (PQAO) envisions an efficient and effective educational organizational management system (EOMS)
                                    for all school’s processes that employs data-based strategic planning process leading
                                    to excellent performance standards and continuous quality improvement.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Mission</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Planning and Quality Assurance Office (PQAO) serves as the organizational unit of
                                    St. Scholastica’s College in maintaining quality in the delivery of educational programs and services.
                                    Additionally, the PQAO facilitates the strategic thinking and planning process leading to data-based strategic plans. 
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Functions (Job Summary)</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Planning and Quality Assurance Director is the partner of the school’s academic and administrative officials
                                    in developing and implementing quality assurance and improving productivity in school operations.
                                    The Planning and Quality Assurance Director likewise facilitates the drawing up of institutional and sectoral
                                    plans and implementing an effective planning and performance monitoring system that will ensure a negotiated and
                                    communicated planning process consistent with the approved budget cycle that will provide an effective tool for evaluating performance. 
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial;">Email: rpulmones@ssc.edu.ph</span>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial;">Tel: 85677686 local 8291 (office assistant) and 8538 (PQAO Director)</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <!-- <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INST’L QUALITY ASSURANCE OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse> -->
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="14" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;REGISTRAR’S OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Registrar’s Office envisions full automation of the major functions
                                    of the Office to ensure accurate management of records and prompt delivery
                                    of its various services to all stakeholders.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Mission</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Registrar’s Office aims to improve its delivery of administrative and professional
                                    services to all stakeholders promptly, accurately, and effectively through full
                                    utilization of human and technological resources to manage, maintain, and update
                                    student records from admission until after graduation. It also aims to further
                                    progress in its information dissemination on enrollment, academic performance,
                                    and related matters to aid the administration in program evaluation and development.
                                </span>
                                <br>
                                <br>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Service Hours</strong></span>
                                        <ul style="font-family: Arial; list-style: none; padding-left: 5px;" class="ssa-text-color">
                                            <li>MONDAY TO FRIDAY FROM 7:00am to 5:00pm (No Lunch Break)</li>
                                            <li>SATURDAY FROM 7:00am to 12:00 noon</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-home"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>St. Scholastica's College Manila 2560 Leon Guinto Street, Malate, Metro Manila PHILIPPINES 1004</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>TRUNK LINE:(632) 8567-7686, local 8167</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>(College & Graduate School) / 8165 (Basic Education)</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>09278107384</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>sscregistrar@ssc.edu.ph</strong></span>
                                        <br>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;"><strong>Note: Please observe office hours for texts and calls.</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="16" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;SAFETY AND SECURITY OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Safety and Security Office envisions a safe and fully protected Scholastican community,
                                    its members, stakeholders, facilities, and properties, carried out by trained and licensed
                                    personnel of an accredited security agency, whose service is dedicated and reliable.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Safety and Security Office is dedicated to ensuring a safe and secure environment for all
                                    stakeholders in cooperation with the Scholastican community and its immediate surroundings,
                                    employing a professional and trustworthy outsourced security agency.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>GOALS</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Safety and Security Office carries out its mission and vision through the following goals:
                                    <br>
                                    <ul style="font-family: Arial;" class="ssa-text-color">
                                        <li>
                                            Execute and implement appropriate security plans and measures through the security
                                            agency in coordination with the units.
                                        </li>
                                        <li>Ensure total protection for all members and guests of the Scholastican community.</li>
                                        <li>
                                            Establish coordination with the PNP, Barangay officials, the Task Force Safe School,
                                            MTPB, and the Emergency Response Team to create measures and design plans to
                                            anticipate and mitigate any type of security situation and school emergency.
                                        </li>
                                        <li>Receive and take appropriate action on reported security breaches.</li>
                                        <li>
                                            Regularly monitor and manage the CCTV system and stay informed of prevailing
                                            security circumstances as a basis for analyzing and forecasting security situations.
                                        </li>
                                        <li>Assist in the selection process for the renewal of contracts or changes in the security agency.</li>
                                    </ul>
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>safetysecurity@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local 8101, 8108)</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="18" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;STUDENT AFFAIRS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;We envision an effective and efficient delivery of Student
                                    Affairs Programs and Services supportive of the holistic
                                    formation and development of each Scholastican in the
                                    light of a Benedictine, Christian, and socially-oriented
                                    school.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Student Affairs Office is an academic support office
                                    that seeks to contribute to the holistic formation and
                                    development of Scholasticans through relevant, quality,
                                    and high-impact programs and services that enhance
                                    Scholasticans’ educational experiences; develop
                                    them to be well-rounded and able to adapt to any milieu
                                    and be creative, resourceful, socially responsible,
                                    responsive, disciplined, and with unquestioned integrity.

                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Staff</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Prof. Victor S. Vigo Jr. - Dean of Student Affairs</li>
                                            <li>Ms. Darlyn Nicolas - Office Staff</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Monday - Friday: 7:30 AM - 4:30 PM</li>
                                            <li>Saturday: 8:00 - 12:00 NN</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ol style="font-family: Arial;" class="ssa-text-color">
                                            <li>
                                                a series of assemblies that aims to welcome Scholasticans
                                                to their second home via featured talks on the school’s vision-mission,
                                                thrust and theme, academic and non-academic policies, programs and services,
                                                campus tours and events designed to build community and develop school spirit.
                                                All freshwomen/men and other new students undergo a three-day Orientation Program,
                                                while upper-year levels hold annual Reorientation Programs.
                                                <br>
                                                <br>
                                                A highlight of the Orientation Program is the staging of Ora et Labora
                                                by Sining Tanghalan, the school’s resident theater group. Ora et
                                                Labora is a pageant on the lives of the twin saints – St. Benedict and
                                                St. Scholastica.
                                            </li>
                                            <li>
                                                a comprehensive leadership formation program that caters primarily to
                                                the student organization leaders, officers of the Student Council, and
                                                the class representatives.  It is composed of four modules: Module 1
                                                – Defining Benedictine Leadership in the light of the National
                                                Situation, Module 2 – Basic Leadership Skills, Module 3 –
                                                Community Organizing and Immersion, and Module 4 – Mentoring and
                                                Coaching.
                                                <br>
                                                <br>
                                                Out-of-school training programs are also availed of by student
                                                leaders with appropriate support extended by the SAO. These
                                                programs are sponsored by the student organizations’ affiliates, SSC
                                                consortia partners, or government/non-government organizations.
                                            </li>
                                            <li>
                                                a culminating activity that aims to recognize outstanding student leaders,
                                                organizations, and moderators during a particular school year.
                                                It also serves as the turnover ceremony for the Student Council
                                                and all accredited student organizations.
                                            </li>
                                        </ol>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Gallery</strong></span>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.student_orientation)">
                                                    <img :src="resources.student_affairs.student_orientation.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.student_activity)">
                                                    <img :src="resources.student_affairs.student_activity.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.scholastican_leadership)">
                                                    <img :src="resources.student_affairs.scholastican_leadership.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.blue_and_white)">
                                                    <img :src="resources.student_affairs.blue_and_white.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="19" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;SCHOLARSHIPS AND GRANTS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        <el-dialog
            :title="gallery.title"
            :visible.sync="showContent"
            width="50%">
            <el-carousel trigger="click" height="700px" :autoplay="false" indicator-position="none">
                <el-carousel-item v-for="(item, index) in gallery.pics" :key="index">
                    <img :src="item" style="height: 700px; width: 100%">
                </el-carousel-item>
            </el-carousel>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeGallery()">Close</el-button>
            </span>
        </el-dialog>
        <div style="margin-top: 75px;">
            &nbsp;
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
    </div>
</template>
<style>
    .el-collapse-item__header{
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        background-color: #012376;
        color: #ffffff;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        font-size: 13px;
        font-weight: 500;
        transition: border-bottom-color .3s;
        outline: 0;
    }
    .el-collapse-item__content {
        padding-bottom: 25px;
        font-size: 13px;
        color: #303133;
        line-height: 1.769230769230769;
        margin-left: 25px;
    }
</style>
<script>
    import SiteHeader from "../../../components/general/SiteHeader";
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Object,
        },
        components: { SiteHeader,NavbarStatic,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                gallery: {},
                showContent: false,
            }
        },
        mounted() {

        },
        created() {
            this.addSsaColorClass();
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            addSsaColorClass(){
                $(".el-collapse-item__header").css({
                    "background-color": "#012376",
                    "color": "#ffffff"
                });
            },
            showGallery(gallery){
                this.showContent = true;
                this.gallery = gallery;
            },
            closeGallery(){
               this.gallery = {};
               this.showContent = false; 
            }
        }
    }
</script>