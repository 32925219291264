<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="container my-5">
            <div class="text-center mb-4">
                <h1 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>GRADE SCHOOL</strong></h1>
            </div>
            <div class="row" style="margin-top: 200px;">
                <div class="col-md-6">
                    <h4 style="color: #012376; font-family: Arial; font-size: 1.25rem;"><strong>About our Grade School Program</strong></h4>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;">
                        Our Grade School program (Grades 1-6) offers a holistic formation anchored in Benedictine values and our core value of academic excellence as social responsibility. Through an interdisciplinary approach to instruction, we cultivate well-rounded individuals who are intellectually curious, spiritually grounded, and socially aware.
                    </p>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;"><b>A Catholic Benedictine Tradition</b></p>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;">
                        As a Catholic Benedictine school, we strive to form individuals who are committed to living a life of Ora et Labora (Prayer and Work). Our program integrates Catholic teachings and Benedictine values into the curriculum, fostering a strong character and spiritual foundation.
                    </p>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;"><b>A Rich Learning Experience</b></p>
                    <ul style="color: #012376; font-family: Arial; font-size: 1.05rem;">
                        <li><b>Academic Excellence:</b> Our rigorous curriculum emphasizes critical thinking, problem-solving, and creativity. We utilize innovative teaching methodologies, such as explicit instruction in English and the Singapore Math approach, to foster a deep understanding of mathematical concepts. We offer a wide range of enrichment programs to nurture students' talents and passions, including Robotics, Scholastic Lit Pro, and Drama and Theater in Education.</li>
                        <li><b>Holistic Development:</b> We nurture well-rounded individuals and equip our students for life by balancing academics with opportunities for artistic expression, athletic achievement, and spiritual reflection.</li>
                        <li><b>Social Responsibility:</b> We empower students to champion women empowerment, advocate for social justice, promote peace, raise environmental awareness, and prioritize the option for the poor, preparing them to make a positive impact in the world.</li>
                    </ul>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;"><b>EMPOWERING STUDENTS FOR CHANGE</b> is more than our tagline—it’s our mission. Start them young and join us in shaping the future leaders of tomorrow.</p>
                </div>
                <div class="col-md-6 position-relative">
                    <div class="blue-shape"></div>
                    <div class="image-container">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/grade_school_page/photo_2024-05-21_16-23-08.jpg'" alt="Image 1" class="image image1">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/grade_school_page/photo_2024-05-21_16-23-23.jpg'" alt="Image 2" class="image image2">
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 250px;">
            <center>
                <h1 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>OUR FACILITIES</strong></h1>
                <br><br>
                <el-carousel type="card" height="500px" :autoplay="false">
                    <el-carousel-item v-for="res in resources" :key="item">
                        <img :src="res" style="height: 500px; width: 500px;">
                    </el-carousel-item>
                </el-carousel>
            </center>
        </div>

        <div style="margin-top: 150px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>

<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .blue-shape {
        position: absolute;
        width: 350px;
        height: 350px;
        background-color: #002d72;
        transform: rotate(45deg);
        top: -10%;
        right: 10%;
        z-index: 0;
        left: 50%;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 50%; /* Maintain aspect ratio */
    }

    .image {
        position: absolute;
        border: 5px solid white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease;
    }

    .image1 {
        top: -10%;
        left: 50%;
        width: 60%;
        transform: rotate(-5deg);
        z-index: 2;
    }

    .image2 {
        top: 70%;
        left: 25%;
        width: 60%;
        transform: rotate(5deg);
        z-index: 3;
    }

    .image:hover {
        transform: scale(1.05);
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Cover the container */
        border: 2px solid white;
        border-radius: 5px;
    }
</style>

<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic, SiteHeader, SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp
            }
        },
        mounted() {

        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>
