<template>
    <div>
        <nav class="navbar navbar-light navbar-expand-lg" style="background-color: #012277; height: 115px;">
            <a class="navbar-brand" :href="env_wp.APP_URL" style="margin-right: 0px;">
                <img style="width: 100px; height: 100px;" :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/SSC Logo White Transparent (8).png'" alt="SSCMANILA LOGO">
            </a>
            <template v-if="isWindowOnDesktop">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </template>
            <template v-if="!isWindowOnDesktop">
                <form class="form-inline my-2 my-lg-0">
                    <a href="https://sscmanila.orangeapps.ph/landing/sscmanila/" type="button" class="rounded-btn btn btn-light"><span class="ssamanila_text_btn">APPLY NOW</span></a>
                    <a href="https://myisis2.ssc.edu.ph/myssc-is/PreLogin.html" type="button" class="rounded-btn btn btn-light" style="margin-left: 10px;"><span class="ssamanila_text_btn">MYSSC-IS</span></a>
                </form>
            </template>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <!--<a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>-->
                        <p class="ssamanila_text_nav" style="margin-bottom: -0.7rem;">St. Scholastica's College Manila</p><br>
                        <p style="color: #ffffff; margin-top: -1rem">Established in 1906</p>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#"></a>
                    </li>
                    <li class="nav-item dropdown">
                        &nbsp;
                    </li>
                    <li class="nav-item" style="margin-left: 50px;">
                        <br>
                        <p class="ssamanila_text_nav" style="margin-bottom: 0px;">8567-7686(local 8000-General Information) |  sscadmit@ssc.edu.ph</p>
                    </li>
                    <li class="nav-item" style="margin-left: 20px;">
                        <br>
                        <a style="margin-bottom: 0px; font-size: 1.15rem;" class="non-a-tag" href="https://www.facebook.com/sscmnl/" target="_blank"><i class="fa fa-facebook"></i></a>&nbsp;&nbsp;&nbsp;<a style="margin-bottom: 0px; font-size: 1.15rem;" class="non-a-tag" href="https://www.instagram.com/sscmnlofficial/" target="_blank"><i class="fa fa-instagram"></i></a>
                    </li>
                </ul>
                <form class="form-inline my-2 my-lg-0">
                    <a href="https://sscmanila.orangeapps.ph/landing/sscmanila/" type="button" class="rounded-btn btn btn-light"><span class="ssamanila_text_btn">APPLY NOW</span></a>
                    <a href="https://myisis2.ssc.edu.ph/myssc-is/PreLogin.html" type="button" class="rounded-btn btn btn-light" style="margin-left: 10px;"><span class="ssamanila_text_btn">MYSSC-IS</span></a>
                </form>
            </div>
        </nav>
    </div>
</template>
<style>
    .ssamanila_text_btn{
        color: #012277;
        font-weight: bold;
        font-size: 1.05rem;
    }
    .ssamanila_text_nav{
        color: #ffffff;
        font-weight: bold;
        font-size: 1.10rem;
    }
    .ssamanila_text_nav2{
        color: #012277;
        font-weight: bold;
        font-size: 1.05rem;
    }
    /* Reset default styles for anchor tag with class .non-a-tag */
    a.non-a-tag {
        display: inline; /* Make it behave like inline element */
        text-decoration: none; /* Remove underline */
        color: #ffffff; /* Inherit color from parent */
        cursor: pointer; /* Change cursor to default */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
        border: none; /* Remove border */
        background: none; /* Remove background */
        outline: none; /* Remove outline */
    }

    /* Optionally, you can specify styles for visited and hover states */
    a.non-a-tag:hover,
    a.non-a-tag:active,
    a.non-a-tag:focus {
        color: #ffffff; /* Inherit color from parent */
        text-decoration: none; /* Remove underline */
    }

</style>
<script>
    export default {
        props: {
            title: String,
        },
        data () {
            return {
                nav_color: '#F8FAFC',
                nav_txt_color: '',
                defaultActiveIndex: '0',
                notifications: [],
                folder_name: '',
                nav_menus_pages: [],
                picture: '',
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.folder_name = this.$root.folder_name;
            // this.getNavMenusPagesAndSetup();
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        created() {
            this.$root.$refs.NavBar = this;
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            getNavMenusPagesAndSetup(){
                axios.get(this.folder_name+ '/cms/get_nav_menus_pages')
                    .then(res => {
                        this.nav_menus_pages = res.data.menus_pages;
                        this.nav_color = res.data.setup.navbar_color;
                        this.nav_txt_color = res.data.setup.navbar_text_color;
                        this.picture = res.data.picture.full_path;
                    })
            },
            navigate(URL) {
                if (URL == 'mark_all_as_read') this.notifMarkAsRead()
                else if (!URL) return
                else if (URL != 'mark_all_as_read') window.location = URL
            },
            backHome(){
                window.location.href = this.folder_name + '/home';
            },
            cms(){
                window.location.href = this.folder_name + '/cms';
            },
            gotoLink(link){
                window.open(link, '_blank');
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>