<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div style="margin-top: 75px;">
            <center>
                <h1 class="ssa-text-color" style="font-size: 3rem; font-family: Arial;"><strong>ADVOCACY AND OUTREACH CENTER</strong></h1>
            </center>

            <div class="container" style="margin-top: 50px;">
                <h4 class="ssa-text-color" style="font-size: 1.5rem; font-family: Arial;"><strong>Mission:</strong></h4>
                <span class="ssa-text-color" style="font-size: 1.25rem;">
                    The Advocacy and Outreach Center (AOC) engages the school community in relevant advocacy and outreach/external services that focus on the Institution’s advocacies namely: Empowerment of Women (Gender Equality) Social Justice and Peace (Social Consciousness), Poverty Alleviation (Support for Indigenous People), and Environmental Concern/Protection (Care for Mother Earth)
                </span>
            </div>

            <div class="container" style="margin-top: 50px;">
                <h4 class="ssa-text-color" style="font-size: 1.5rem; font-family: Arial;"><strong>Vision:</strong></h4>
                <span class="ssa-text-color" style="font-size: 1.25rem;">
                    The AOC envisions a dynamic and prophetic source of extension services to the school community and her stakeholders for them to be imbued with social consciousness and responsibility, upholding human dignity and enabling social transformation.
                </span>
            </div>

            <div class="container" style="margin-top: 100px;">
                <h4 class="ssa-text-color" style="font-size: 1.5rem; font-family: Arial;"><strong>Goals:</strong></h4>
                <span class="ssa-text-color" style="font-size: 1.25rem;">
                    To this end AOC shall:
                    <ol>
                        <li>Set the direction of the institutional advocacy and outreach program</li>
                        <li>Conduct social consciousness activities for the entire community, other stakeholders, and community partners to support the school’s advocacies.</li>
                        <li>Establish linkages with new communities, NGOs, and civic organizations for future areas for advocacy and outreach activity.</li>
                        <li>Supervise and conduct relief operations for calamity-stricken areas in coordination with the Diocesan Social Action Centers, religious congregations, and parishes.</li>
                    </ol>
                </span>
            </div>

            <div class="container" style="margin-top: 50px;">
                <h4 class="ssa-text-color" style="font-size: 1.5rem; font-family: Arial;"><strong>Programs and Services</strong></h4>
                <span class="ssa-text-color" style="font-size: 1.25rem;">
                    <ol>
                        <li>Planning, development, and evaluation of Institutional advocacy and outreach plan</li>
                        <li>Management of the School’s disaster and relief operations in coordination with the academic units and support service offices</li>
                    </ol>
                </span>
            </div>
        </div>

        <div style="margin-top: 75px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div style="background: #012376;">
            <div class="row">
                &nbsp;
            </div>
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .blue-shape {
        position: absolute;
        width: 350px;
        height: 350px;
        background-color: #002d72;
        transform: rotate(45deg);
        top: -10%;
        right: 10%;
        z-index: 0;
        left: 50%;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 50%; /* Maintain aspect ratio */
    }

    .image {
        position: absolute;
        border: 5px solid white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease;
    }

    .image1 {
        top: -10%;
        left: 50%;
        width: 60%;
        transform: rotate(-5deg);
        z-index: 2;
    }

    .image2 {
        top: 70%;
        left: 25%;
        width: 60%;
        transform: rotate(5deg);
        z-index: 3;
    }

    .image:hover {
        transform: scale(1.05);
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Cover the container */
        border: 2px solid white;
        border-radius: 5px;
    }

</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp
            }
        },
        mounted() {

        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>