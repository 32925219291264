<template>
    <el-menu :default-active="defaultActiveIndex" class="el-top-nav" mode="horizontal" :background-color="nav_color" :text-color="nav_txt_color">
<!--        <el-menu-item class="title" index="0">{{ title }}</el-menu-item>-->
        <el-menu-item index="0" @click="backHome()" style="padding-right: 20px;">
            <figure style="height: 50px; width: 190px;">
<!--                <img src="https://www.esps.edu.ph/assets/logo/esps_logo.png" style="height: 50px; width: 190px;">-->
                <template v-if="picture !== ''">
                    <img :src="picture" style="height: 50px; width: 190px;">
                </template>
                <template v-else>
                    <img :src="this.folder_name+ '/public/assets/img/powered.png'" style="height: 50px; width: 190px;">
                </template>

            </figure>
        </el-menu-item>
        <template v-if="nav_menus_pages.length > 0" v-for="(mp, index) in nav_menus_pages">
            <template v-if="mp.type == 1">
                <template v-if="mp.is_dropdown == 0">
                    <el-menu-item :index="`${mp.order.toString()}`">
                        <a :href="folder_name+'/site/'+mp.slug"><p style="font-family: Arial; font-weight: bold;">{{ mp.menu_page_name }}</p></a>
                    </el-menu-item>
                </template>
                <template v-else>
                    <el-submenu :index="`${mp.order.toString()}`">
                        <template slot="title"><p style="font-family: Arial; font-weight: bold;">{{ mp.menu_page_name }}</p></template>
                        <template v-for="(sm,index2) in mp.sub_menus">
                            <el-menu-item :index="`${mp.order.toString()}-${sm.order.toString()}`">
                                <a class="a_tag" :href="folder_name+'/subsite/'+sm.slug"><p style="font-family: Arial; font-weight: bold;">{{ sm.sub_menu_page_name }}</p></a>
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
            </template>
            <template v-else>
                <el-menu-item :index="`${mp.order.toString()}`" class="btn-type">
                    <button type="button" class="btn btn-warning btn-md" @click="gotoLink(mp.link_address)">{{ mp.menu_page_name }}</button>
                </el-menu-item>
            </template>
        </template>
        <el-submenu v-if="$root.auth.info !== null" class="user" index="1000">
            <template slot="title">
<!--                <img src="https://picsum.photos/200" class="user-avatar">-->
                <p style="font-family: Arial; font-weight: bold;">{{ $root.auth.info.fname }}</p>
            </template>
            <el-menu-item @click="cms()"><i class="fa fa-cogs"></i> CMS</el-menu-item>
            <el-menu-item @click="$root.logout()"><i class="fa fa-sign-out"></i> Logout</el-menu-item>
        </el-submenu>
    </el-menu>
</template>

<style scoped>
    .el-menu--horizontal > .el-menu-item.dock-right {
        float: right;
    }
    .el-menu--horizontal > .el-submenu.dock-right {
        float: right;
    }
    .isNew {
        background-color: #e6f4ff;
    }
    .truncate {
        line-height: 25px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .dropdown-notification {
        width: 450px;
        position: relative;
        max-height: 500px;
        overflow-y: auto;
    }
    /* Style the anchor tag */
    .a_tag {
        text-decoration: none; /* Remove default underline */
        color: inherit; /* Inherit text color */
    }
</style>

<script>
    export default {
        props: {
            title: String,
        },
        data () {
            return {
                nav_color: '#F8FAFC',
                nav_txt_color: '',
                defaultActiveIndex: '0',
                notifications: [],
                folder_name: '',
                nav_menus_pages: [],
                picture: '',
            }
        },
        mounted() {
            this.folder_name = this.$root.folder_name;
            this.getNavMenusPagesAndSetup();
            // this.getNotifications();
        },
        created() {
            this.$root.$refs.NavBar = this;
        },
        methods: {
            getNavMenusPagesAndSetup(){
                axios.get(this.folder_name+ '/cms/get_nav_menus_pages')
                .then(res => {
                    this.nav_menus_pages = res.data.menus_pages;
                    this.nav_color = res.data.setup.navbar_color;
                    this.nav_txt_color = res.data.setup.navbar_text_color;
                    this.picture = res.data.picture.full_path;
                })
            },
            // getNotifications() {
            //     axios({
            //         method: 'GET',
            //         type: 'JSON',
            //         url: this.$root.folder_name + '/notifications/get'
            //     }).then(response => {
            //         this.notifications = response.data
            //     }).catch(error => {
            //         this.$root.defaultError()
            //     })
            // },
            navigate(URL) {
                if (URL == 'mark_all_as_read') this.notifMarkAsRead()
                else if (!URL) return
                else if (URL != 'mark_all_as_read') window.location = URL
            },
            // notifMarkAsRead() {
            //     axios({
            //         method: 'GET',
            //         type: 'JSON',
            //         url: this.$root.folder_name + '/notifications/mark_as_read'
            //     }).then(response => {
            //         this.notifications = []
            //     }).catch(error => {
            //         this.$root.defaultError()
            //     })
            // },
            backHome(){
                window.location.href = this.folder_name + '/home';
            },
            cms(){
                window.location.href = this.folder_name + '/cms';
            },
            gotoLink(link){
                window.open(link, '_blank');
            },
        }
    }
</script>
