<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="student-life"></div>

        <div class="container" style="margin-top: 75px;">
            <div class="row">
                <div class="col-md-4">
                    <el-menu
                            ref="navbar1"
                            default-active="1"
                            @select="handleSelected"
                            background-color="#fff"
                            text-color="#012376">
                        <el-menu-item index="1">
                            <i class="fa fa-picture-o"></i>&nbsp;<span>Gallery of Events</span>
                        </el-menu-item>
                        <el-menu-item index="2">
                            <i class="fa fa-bars"></i>&nbsp;<span>College Organizations</span>
                        </el-menu-item>
                        <el-menu-item index="3">
                            <i class="fa fa-file-text-o"></i>&nbsp;<span>High School Clubs/Organizations</span>
                        </el-menu-item>
                        <el-menu-item index="4">
                            <i class="fa fa-pencil"></i>&nbsp;<span>Grade School Clubs/Organizations</span>
                        </el-menu-item>
                    </el-menu>
                </div>
                <div class="col-md-8">
                    <template v-if="activeTab == 1">
                        <template v-if="isWindowOnDesktop">
                            <div class="row justify-content-center">
                                <div class="col-md-4 animated-card" @click="showDialog(resources.glimpse)">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>A Glimpse of HERstory (December 3 - December 6, 2024 | School Archives Exhibit)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.glimpse.pics" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">12.03.2024 - 12.06.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                                <div class="col-md-4 animated-card" style="margin-left: 150px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>St. Scholastica’s College Manila 118th Foundation Day (December 3, 2024)</strong></span>
                                        </div>
                                        <div>
                                            <template v-for="(item, index) in resources.community_day_2024">
                                                <video width="100%" height="270px" controls>
                                                    <source :src="item" type="video/mp4">
                                                    <source :src="item" type="video/ogg">
                                                </video>
                                            </template>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">12.03.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>

                            <div class="row justify-content-center" style="margin-top: 50px;">
                                <div class="col-md-4 animated-card">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>ALON: Hiraya ng Disenyong Upuan (May 27 - May 30, 2024 | Ayala Malls Circuit - Makati City)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.alon" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">05.27.2024 - 05.30.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                                <div class="col-md-4 animated-card" style="margin-left: 150px;" @click="showDialog(resources.schommunity)">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>Schommunity Day! (December 1, 2024 | St. Cecilia’s Hall)</strong></span>
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="275px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.schommunity.pics" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">12.01.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>

                            <div class="row justify-content-center"  style="margin-top: 50px;">
                                <div class="col-md-4 animated-card">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>Ora et Labora Awards for Student Achievement (May 03, 2024 | Sr. Kuniberta Hall)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.ora_et" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">05.03.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                                <div class="col-md-4 animated-card" style="margin-left: 150px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <el-popover placement="top" width="500" trigger="hover" content="Fine Arts Graduation Exhibit Opening and Interior Design High School Lounge Blessing (May 18, 2024 | Maria Fe Perez - Agudo Center for Leadership Excellence (Social Hall)">
                                        <span slot="reference">
                                            <span style="color: #012376;"><strong>Fine Arts Graduation Exhibit Opening and Interior …udo Center for Leadership Excellence (Social Hall)</strong></span>
                                        </span>
                                            </el-popover>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.fine_arts" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">05.18.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                            <div class="row justify-content-center"  style="margin-top: 50px;">
                                <div class="col-md-4 animated-card">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>1 Billion Rising: Rise for Freedom “Be The New World” (February 15, 2024 | SSC Manila Field)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.one_b" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">02.15.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </template>

                        <template v-if="!isWindowOnDesktop">
                            <div class="row justify-content-center" style="margin-top: 50px;">
                                <div class="col-md-4 animated-card" style="margin-left: 30px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>A Glimpse of HERstory (December 3 - December 6, 2024 | School Archives Exhibit)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.glimpse.pics" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">12.03.2024 - 12.06.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                            <div class="row justify-content-center" style="margin-top: 50px;">
                                <div class="col-md-4 animated-card" style="margin-left: 30px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>St. Scholastica’s College Manila 118th Foundation Day (December 3, 2024)</strong></span>
                                        </div>
                                        <div>
                                            <template v-for="(item, index) in resources.community_day_2024">
                                                <video width="100%" height="270px" controls>
                                                    <source :src="item" type="video/mp4">
                                                    <source :src="item" type="video/ogg">
                                                </video>
                                            </template>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">12.03.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                                <div class="col-md-4 animated-card" style="margin-left: 30px; margin-top: 50px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>Schommunity Day! (December 1, 2024 | St. Cecilia’s Hall)</strong></span>
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="275px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.schommunity.pics" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">12.01.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                            <div class="row justify-content-center" style="margin-top: 50px;">
                                <div class="col-md-4 animated-card" style="margin-left: 30px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>ALON: Hiraya ng Disenyong Upuan (May 27 - May 30, 2024 | Ayala Malls Circuit - Makati City)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.alon" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">05.27.2024 - 05.30.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                                <div class="col-md-4 animated-card" style="margin-left: 30px; margin-top: 50px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <el-popover placement="top" width="500" trigger="hover" content="Fine Arts Graduation Exhibit Opening and Interior Design High School Lounge Blessing (May 18, 2024 | Maria Fe Perez - Agudo Center for Leadership Excellence (Social Hall)">
                                        <span slot="reference">
                                            <span style="color: #012376;"><strong>Fine Arts Graduation Exhibit Opening and Interior …udo Center for Leadership Excellence (Social Hall)</strong></span>
                                        </span>
                                            </el-popover>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.fine_arts" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">05.18.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>

                            <div class="row justify-content-center" style="margin-top: 50px;">
                                <div class="col-md-4 animated-card" style="margin-left: 30px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>Ora et Labora Awards for Student Achievement (May 03, 2024 | Sr. Kuniberta Hall)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.ora_et" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">05.03.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                                <div class="col-md-4 animated-card" style="margin-left: 30px; margin-top: 50px;">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix">
                                            <span style="color: #012376;"><strong>1 Billion Rising: Rise for Freedom “Be The New World” (February 15, 2024 | SSC Manila Field)</strong></span>
                                            <!-- <el-button style="float: right; padding: 3px 0" type="text"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></el-button> -->
                                        </div>
                                        <div>
                                            <el-carousel trigger="click" height="250px" :autoplay="false" indicator-position="none">
                                                <el-carousel-item v-for="(item, index) in resources.one_b" :key="index">
                                                    <img :src="item" style="height: 100%; width: 100%">
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                        <div class="bottom clearfix">
                                            <center><span style="color: #012376;">02.15.2024</span></center>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-if="activeTab == 2">
                        <div class="row justify-content-center" :style="!isWindowOnDesktop ? 'margin-top: 50px;' : ''">
                            <div class="col-md-12">
                                <template v-if="isWindowOnDesktop">
                                    <el-collapse v-model="activeCollapse">
                                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff; overflow-y: scroll; max-height: 450px; min-width: 875px;">
                                            <template slot="title">
                                                <strong style="font-family: Poppins;">&nbsp;&nbsp;&nbsp;COLLEGE ORGANIZATIONS</strong>
                                            </template>
                                            <div>&nbsp;</div>
                                            <div v-for="(college, index) in resources.college_org" :key="index">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <img :src="college.img" style="width: 100%; height: 100%;">
                                                    </div>
                                                    <div class="col-md-8">
                                                        <h1 class="ssa-text-color" style="font-size: 2rem; font-family: Poppins;">{{ college.title }}</h1>
                                                        <span class="ssa-text-color" style="font-family: Poppins;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. At atque consequatur ea eos esse harum hic ipsa necessitatibus nesciunt, nostrum pariatur porro qui repellendus reprehenderit sint soluta sunt ullam. Assumenda?</span>
                                                    </div>
                                                </div>
                                                <hr v-if="index+1 != resources.college_org.length">
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </template>

                                <template v-if="!isWindowOnDesktop">
                                    <el-collapse v-model="activeCollapse">
                                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff; overflow-y: scroll; max-height: 450px; min-width: 350px;">
                                            <template slot="title">
                                                <strong style="font-family: Poppins;">&nbsp;&nbsp;&nbsp;COLLEGE ORGANIZATIONS</strong>
                                            </template>
                                            <div>&nbsp;</div>
                                            <div v-for="(college, index) in resources.college_org" :key="index">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <img :src="college.img" style="'width: 250px; height: 250px;">
                                                    </div>
                                                    <div class="col-md-2">
                                                        <h1 class="ssa-text-color" style="font-size: 2rem; font-family: Poppins;">{{ college.title }}</h1>
                                                        <span class="ssa-text-color" style="font-family: Poppins;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. At atque consequatur ea eos esse harum hic ipsa necessitatibus nesciunt, nostrum pariatur porro qui repellendus reprehenderit sint soluta sunt ullam. Assumenda?</span>
                                                    </div>
                                                </div>
                                                <hr v-if="index+1 != resources.college_org.length">
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <el-dialog
            :title="content.title"
            :visible.sync="contentDialog"
            width="30%">
            <span>{{ content.date }}</span>
            <br>
            <br>
            <span>{{ content.title }}</span>
            <br>
            <!-- <span>{{ content.content }}</span> -->
            <span v-html="content.content"></span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog()">Close</el-button>
            </span>
            </el-dialog>

<!--            <div class="row justify-content-center" style="margin-top: 25px;">-->
<!--                <div class="col-md-12 animated-card">-->
<!--                    <el-collapse>-->
<!--                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff; overflow-y: scroll; max-height: 400px;">-->
<!--                            <template slot="title">-->
<!--                                <strong style="font-family: Poppins;">&nbsp;&nbsp;&nbsp;HIGH SCHOOL CLUBS/ORGANIZATIONS</strong>-->
<!--                            </template>-->
<!--                            <div>&nbsp;</div>-->
<!--                            <div>-->
<!--                                <span class="ssa-text-color" style="font-family: Poppins;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. At atque consequatur ea eos esse harum hic ipsa necessitatibus nesciunt, nostrum pariatur porro qui repellendus reprehenderit sint soluta sunt ullam. Assumenda?</span>-->
<!--                            </div>-->
<!--                        </el-collapse-item>-->
<!--                    </el-collapse>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="row justify-content-center" style="margin-top: 25px;">-->
<!--                <div class="col-md-12 animated-card">-->
<!--                    <el-collapse>-->
<!--                        <el-collapse-item name="3" style="background-color: #012376; text-color: #ffffff; overflow-y: scroll; max-height: 400px;">-->
<!--                            <template slot="title">-->
<!--                                <strong style="font-family: Poppins;">&nbsp;&nbsp;&nbsp;GRADE SCHOOL CLUBS/ORGANIZATIONS</strong>-->
<!--                            </template>-->
<!--                            <div>&nbsp;</div>-->
<!--                            <div>-->
<!--                                <span class="ssa-text-color" style="font-family: Poppins;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. At atque consequatur ea eos esse harum hic ipsa necessitatibus nesciunt, nostrum pariatur porro qui repellendus reprehenderit sint soluta sunt ullam. Assumenda?</span>-->
<!--                            </div>-->
<!--                        </el-collapse-item>-->
<!--                    </el-collapse>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div style="margin-top: 150px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/student_life/bottom_banner.png'">
        </div>
        <div style="background: #012376;">
            <div class="row" style="margin-left: 0; margin-right: 0;">
                &nbsp;
            </div>
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .blue-shape {
        position: absolute;
        width: 350px;
        height: 350px;
        background-color: #002d72;
        transform: rotate(45deg);
        top: -10%;
        right: 10%;
        z-index: 0;
        left: 50%;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 50%; /* Maintain aspect ratio */
    }

    .image {
        position: absolute;
        border: 5px solid white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease;
    }

    .image1 {
        top: -10%;
        left: 50%;
        width: 60%;
        transform: rotate(-5deg);
        z-index: 2;
    }

    .image2 {
        top: 70%;
        left: 25%;
        width: 60%;
        transform: rotate(5deg);
        z-index: 3;
    }

    .image:hover {
        transform: scale(1.05);
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Cover the container */
        border: 2px solid white;
        border-radius: 5px;
    }

    .student-life {
        position: relative;
        background: url('https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/student_life/top_banner.png') no-repeat center center;
        /*https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/student_life/top_banner.png*/
        background-size: cover;
        color: white;
        text-align: center;
        padding: 50px 0;
    }
    .student-life::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background: linear-gradient(rgba(1, 35, 118, 0.7), rgba(1, 35, 118, 0.7));*/
        z-index: 1;
    }
    .student-life h1 {
        position: relative;
        z-index: 2;
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .box-card{
        width: 350px;
    }

    @keyframes slideInFromTop {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .animated-card {
        animation: slideInFromTop 1s ease-out;
    }

</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                activeTab: 1,
                activeCollapse: '1',
                screenWidth: 0,
                isWindowOnDesktop: true,
                contentDialog: false,
                content: {},
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('in-view');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            document.querySelectorAll('.slide-down').forEach(img => {
                observer.observe(img);
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        created() {

        },
        computed:{
            transformedData(){
                const result = [];
                const items = this.resources.home_of_first;
                const len = items.length;

                for(let i = 0; i < Math.ceil(len / 2); i++){
                    const row = [];
                    for(let j = 0; j < 2; j++){
                        if (items[i + j * Math.ceil(len / 2)]) {
                            row.push(items[i + j * Math.ceil(len / 2)]);
                        }
                    }
                    result.push(row);
                }
                return result;
            }
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            handleSelected(key) {
                this.activeTab = Number(key);
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
            showDialog(row){
                this.contentDialog = true;
                this.content = row;
            },
            closeDialog(){
                this.contentDialog = false;
                this.content = {};
            }
        }
    }
</script>