import ElementUI from 'element-ui'
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import draggable from 'vuedraggable'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import CurrencyFormat from './filters/CurrencyFormat'
import FormatDate from './filters/FormatDate'
import SnakeCaseToTitleCase from './filters/SnakeCaseToTitleCase'
import TrimString from './filters/TrimString'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import moment from 'moment';



require('./bootstrap')

window.Vue = require('vue')
Vue.use(ElementUI, { locale })
Vue.use(VueSweetalert2)
Vue.use(Datetime)
Vue.use(CKEditor);
Vue.component(VueQrcode.name, VueQrcode);

Vue.filter('currencyFormat', CurrencyFormat)
Vue.filter('formatDate', FormatDate)
Vue.filter('snakeToTitle', SnakeCaseToTitleCase)
Vue.filter('trimString', TrimString)

Vue.component('login', require('./pages/Login').default)
// Vue.component('admin-home', require('./pages/Admin/Home').default)
// Vue.component('admin-users', require('./pages/Admin/Users').default)
Vue.component('datetime', Datetime)
Vue.component('draggable', draggable)

// Vue.component('student-home', require('./pages/Student/ID/List').default)
// Vue.component('qr-code', require('./pages/Student/ID/Qr').default)
//
// Vue.component('id-setup', require('./pages/Admin/IDPrinting/setup').default)
// Vue.component('request-setup', require('./pages/Admin/IDPrinting/RequestSetup').default)
// Vue.component('id-requests', require('./pages/Admin/IDRequests/Requests').default)
//
// Vue.component('access-rights', require('./pages/Admin/AccessRights/List').default)
// Vue.component('edit-permission', require('./pages/Admin/AccessRights/Edit').default)
//
// Vue.component('admin-employees', require('./pages/Admin/Employees/List').default)
// Vue.component('admin-view-employee', require('./pages/Admin/Employees/View').default)
// Vue.component('admin-directory', require('./pages/Admin/Directory').default)
//
// Vue.component('departments', require('./pages/Admin/Department/Departments').default);
// Vue.component('edit-department', require('./pages/Admin/Department/EditDepartment').default);
//
// Vue.component('cms-menu', require('./pages/Admin/Cms/CmsMenu').default);
// Vue.component('view-sub-menus', require('./pages/Admin/Cms/ViewSubMenus').default);
// Vue.component('view-menu', require('./pages/Admin/Cms/ViewMenu').default);
// Vue.component('dynamic-menu-page', require('./pages/General/SiteMenuPage/DynamicMenuPage').default);
// Vue.component('home-new', require('./pages/General/SiteMenuPage/HomeNew').default);
Vue.component('home-static', require('./pages/General/StaticPages/Home').default);
Vue.component('history', require('./pages/General/StaticPages/History').default);
Vue.component('admission', require('./pages/General/StaticPages/Admission').default);
Vue.component('contact-us', require('./pages/General/StaticPages/ContactUs').default);
Vue.component('news-page', require('./pages/General/StaticPages/News').default);
Vue.component('pre-school', require('./pages/General/StaticPages/PreSchool').default);
Vue.component('grade-school', require('./pages/General/StaticPages/GradeSchool').default);
Vue.component('high-school', require('./pages/General/StaticPages/HighSchool').default);
Vue.component('college-page', require('./pages/General/StaticPages/College').default);
Vue.component('graduate-school', require('./pages/General/StaticPages/GraduateSchool').default);
Vue.component('institutional-office', require('./pages/General/StaticPages/InstitutionalOffice').default);
Vue.component('who-we-are', require('./pages/General/StaticPages/WhoWeAre').default);
//SCHOOL DEPT START
Vue.component('school-of-music', require('./pages/General/StaticPages/CollegeDepartments/SchoolOfMusic').default);
Vue.component('school-of-arts-and-science', require('./pages/General/StaticPages/CollegeDepartments/SchoolOfArtsAndScience').default);
Vue.component('school-of-hlrm', require('./pages/General/StaticPages/CollegeDepartments/SchoolOfHLRM').default);
Vue.component('school-of-baa', require('./pages/General/StaticPages/CollegeDepartments/SchoolOfBaa').default);
//SCHOOL DEPT END
Vue.component('give-page', require('./pages/General/StaticPages/GivePage').default);
Vue.component('advocacy', require('./pages/General/StaticPages/Advocacy').default);
Vue.component('student-life', require('./pages/General/StaticPages/StudentLife').default);

const app = new Vue({
    el: '#app',
    data() {
        return {
            auth: {
                branchCode: window.auth ? window.auth.branch_code : null,
                id: window.auth ? window.auth.user_id : null,
                info: window.auth ? window.auth.user_info : null
            },
            folder_name: process.env.MIX_FOLDER_NAME,
            schoolImg: window.auth ? window.auth.school_img : null,
            profileImage: window.auth && (window.auth.user_id !== null || window.auth.user_info !== null) ? window.auth.user_info.pic : null,
            isHRHead: window.auth ? window.auth.is_hr_head : false,
            permissions: window.auth ? window.auth.permissions : null,
            moment: moment,
            env_wp: env_wp,
        }
    },
    mounted(){

    },
    methods: {
        defaultError(msg = 'Oops! Something went wrong.') {
            Message({
                showClose: true,
                message: msg,
                type: 'error',
                duration: 0
            })
        },
        defaultSuccess(msg = 'Your request is executed successfully!') {
            Message({
                showClose: true,
                message: msg,
                type: 'success',
                duration: 3000
            })
        },
        arrayFind(array, condition) {
            const item = array.find(condition)
            return array.indexOf(item)
        },
        inArray(needle, arr) {
            var length = arr.length
            for (var i = 0; i < length; i++) {
                if (arr[i] == needle) return true
            }
            return false
        },
        logout() {
            axios.get(this.env_wp.env_wp.FOLDER_NAME + '/logout').then((data) => {
                window.location.href = data.data
            })
        }
    },
})

export default app