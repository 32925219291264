<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div style="margin-top: 20px;">
            <div class="justify-content-center">
                <center>
                    <div style="margin-bottom: 15px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>OUR HERSTORY</strong></h1>
                    </div>
                </center>
            </div>
            <div class="justify-content-center" style="margin-left: 80px;" v-if="isWindowOnDesktop">
                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1884</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 115px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                Fr. Andreas Amrhein, OSB founded the Missionary Benedictine Congregation of monks. He founded<br>
                                1884 the Missionary Benedictine Sisters of Tutzing to help in the mission work in the following year, 1885.
                            </strong>
                        </p>
                    </div>
                    <div class="col-md-2" style="padding-right: 75px;">
                        <el-image style="padding-bottom: 75px;" :src="resources.img.father_amrhein"></el-image>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1906</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 75px;">
                        <p class="ssa-text-color arial-text">
                            <strong>
                                The first five German Sisters: M. Ferdinanda Hoelzer, Sr. Petronilla Keller, Sr.Crescentia Veser, Sr. Winfrieda
                                Mueller, and Novice Alexia Ruedenauer arrived on Philippine soil on September 14, the Feast of the Exaltation of
                                the Cross.
                                <br>
                                <br>
                                The first school in a windowless wagon-shed along Moriones Street, Tondo opened for the first three grades on
                                December 3. The first enrolment was 8 children (2 boys and 6 girls) from well-to-do families and 50 boys and girls
                                in the “Free School.”
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1907</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The Sisters transferred from Morioness Street to their new home in San Marcelino Street.<br>
                                The lot was donated by the Most Rev. Jeremias Harty, hen Archbishop of Manila.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1909</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                Government recognition was granted to the new school with the official title: ST. SCHOLASTICA’S COLLEGE.<br>
                                The Conservatory of Music was opened by Sr. Baptista Battig, OSB which pioneered music education in the Philippines.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1914</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 115px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                Construction of the new building started on a bigger church lot in Singalong<br>
                                offered by Archbishop Harty. At early dawn on the day before Christmas, the Sisters,<br>
                                together with some student boarders, moved to the new address 1532 Pennsylvania Avenue.
                            </strong>
                        </p>
                    </div>
                    <div class="col-md-2" style="padding-right: 50px; padding-top: 5px;">
                        <el-image style="padding-bottom: 75px; width: 125%; height: 125%;" :src="resources.img.bldg"></el-image>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1919</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 110px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.25rem;">
                            <strong>
                                The first 3 AB students graduated.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1942</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                St. Scholastica’s College was sealed as “property of the Japanese Imperial Forces.” Part of the school was<br>
                                converted into a hospital and the school became a sanctuary for people who suffered from the terrors of war.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1945</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 115px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                On February 13, an incendiary bomb set St. Cecilia’s Conservatory of Music on fire. The other<br>
                                buildings were also razed to the ground. All the buildings were gone except the small wooden<br>
                                structures: the free school, the old home economics building, and kindergarten.
                            </strong>
                        </p>
                    </div>
                    <div class="col-md-2" style="padding-right: 75px; padding-top: 5px;">
                        <el-image style="padding-top: 50px;" :src="resources.img.str_batig"></el-image>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1947</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                After a period of reconstruction and renewal, the school re-opened its doors to the students.<br>
                                The pursued goal was ACADEMIC EXCELLENCE: EDUCATION OF FINISHED WOMEN OF CHARACTER.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1957</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The school was granted its first accreditation by The Philippine Accrediting Association of<br>
                                Schools, Colleges, and Universities (PAASCU) and was made a chartered member of the association.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1975</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                St. Scholastica’s College declared its commitment to social justice and peace, drew up<br>
                                a statement on the components of a socially-oriented school and adopted the theme: EDUCATION FOR JUSTICE.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1985</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                An Introductory Course in WOMEN’S STUDIES was incorporated in all academic programs.<br>
                                The Institute of Women’s Studies (IWS) was established and an ecofeminism model farm was put up in Mendez, Cavite.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2006</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.25rem;">
                            <strong>
                                St. Scholastica’s College turned 100 years old.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2007</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The National Historical Institute (NHI) approved the unveiling of the historical<br>
                                marker commemorating the school’s 100-year history.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2008</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                St. Scholastica’s College articulated its thrust for the next decade: Education of Transformative Women Leaders.<br>
                                The Ten Directions of SSC and The Ten Hallmarks of Benedictine Education (Benedictine Values) were formulated.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2009</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.25rem;">
                            <strong>
                                The Corporators decided that St. Scholastica’s College will remain a Women’s college.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2010</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.25rem;">
                            <strong>
                                A College graduate topped (Rank 1) the Licensure Examinations in Guidance and Counseling
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2011</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The new five-story St. Cecilia’s Building was blessed and inaugurated.It houses the School of Music, the College Library (Print and Non-Print Sections),<br>
                                the Music Library, the Corazon Aquino Recital Hall, the HRM Laboratories, and two function halls.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2012</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.25rem;">
                            <strong>
                                The Friedenshaus Hotel School was blessed on July 26.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2013</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The School of Music was named a Center of Excellence in Music Education by the Commission on Higher Education.<br>
                                Two College graduates ranked 5th and 8th in the Licensure Examination for Teachers.
                            </strong>
                        </p>
                    </div>
                </div>

                <hr class="ssa-line">

                <div class="row" style="margin: 0px;">
                    <div class="col-md-2" style="padding-left: 1px; padding-top: 100px;">
                        <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2024</strong></h1>
                    </div>
                    <div class="col-md-8" style="padding-top: 100px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The National Museum of the Philippines has recognized the St. Scholastica Building,<br>
                                St. Cecilia's Hall, St. Hildegard Building, St. Benedict Building, and St. Scholastica's<br>
                                Chapel at Saint Scholastica's College Manila as Important Cultural Property.
                            </strong>
                        </p>
                    </div>
                    <div class="col-md-2" style="padding-right: 50px; padding-top: 5px;">
                        <el-image style="padding-bottom: 75px; width: 125%; height: 125%;" :src="resources.img.unveiling"></el-image>
                    </div>
                </div>

                <hr class="ssa-line">
            </div>
            <!--MOBILE VIEWx23-->
            <div class="justify-content-center" style="margin-top: 75px;" v-if="!isWindowOnDesktop">
                <center>
                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1884</strong></h1>
                    <el-image style="margin-top: 25px;" :src="resources.img.father_amrhein"></el-image>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                Fr. Andreas Amrhein, OSB founded the Missionary Benedictine Congregation of monks. He founded<br>
                                1884 the Missionary Benedictine Sisters of Tutzing to help in the mission work in the following year, 1885.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1906</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The first five German Sisters: M. Ferdinanda Hoelzer, Sr. Petronilla Keller, Sr.Crescentia Veser, Sr. Winfrieda
                                Mueller, and Novice Alexia Ruedenauer arrived on Philippine soil on September 14, the Feast of the Exaltation of
                                the Cross.
                                <br>
                                <br>
                                The first school in a windowless wagon-shed along Moriones Street, Tondo opened for the first three grades on
                                December 3. The first enrolment was 8 children (2 boys and 6 girls) from well-to-do families and 50 boys and girls
                                in the “Free School.”
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1907</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The Sisters transferred from Morioness Street to their new home in San Marcelino Street.<br>
                                The lot was donated by the Most Rev. Jeremias Harty, hen Archbishop of Manila.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1909</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                Government recognition was granted to the new school with the official title: ST. SCHOLASTICA’S COLLEGE.<br>
                                The Conservatory of Music was opened by Sr. Baptista Battig, OSB which pioneered music education in the Philippines.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1914</strong></h1>
                    <el-image style="margin-top: 25px; width: 80%; height: 80%;" :src="resources.img.bldg"></el-image>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                Construction of the new building started on a bigger church lot in Singalong<br>
                                offered by Archbishop Harty. At early dawn on the day before Christmas, the Sisters,<br>
                                together with some student boarders, moved to the new address 1532 Pennsylvania Avenue.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1919</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The first 3 AB students graduated.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1942</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                St. Scholastica’s College was sealed as “property of the Japanese Imperial Forces.” Part of the school was<br>
                                converted into a hospital and the school became a sanctuary for people who suffered from the terrors of war.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1945</strong></h1>
                    <el-image style="padding-top: 25px;" :src="resources.img.str_batig"></el-image>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                On February 13, an incendiary bomb set St. Cecilia’s Conservatory of Music on fire. The other<br>
                                buildings were also razed to the ground. All the buildings were gone except the small wooden<br>
                                structures: the free school, the old home economics building, and kindergarten.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1947</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                After a period of reconstruction and renewal, the school re-opened its doors to the students.<br>
                                The pursued goal was ACADEMIC EXCELLENCE: EDUCATION OF FINISHED WOMEN OF CHARACTER.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1957</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The school was granted its first accreditation by The Philippine Accrediting Association of<br>
                                Schools, Colleges, and Universities (PAASCU) and was made a chartered member of the association.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1975</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                St. Scholastica’s College declared its commitment to social justice and peace, drew up<br>
                                a statement on the components of a socially-oriented school and adopted the theme: EDUCATION FOR JUSTICE.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>1985</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                An Introductory Course in WOMEN’S STUDIES was incorporated in all academic programs.<br>
                                The Institute of Women’s Studies (IWS) was established and an ecofeminism model farm was put up in Mendez, Cavite.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2006</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                St. Scholastica’s College turned 100 years old.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2007</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The National Historical Institute (NHI) approved the unveiling of the historical<br>
                                marker commemorating the school’s 100-year history.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2008</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                St. Scholastica’s College articulated its thrust for the next decade: Education of Transformative Women Leaders.<br>
                                The Ten Directions of SSC and The Ten Hallmarks of Benedictine Education (Benedictine Values) were formulated.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2009</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The Corporators decided that St. Scholastica’s College will remain a Women’s college.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2010</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.25rem;">
                            <strong>
                                A College graduate topped (Rank 1) the Licensure Examinations in Guidance and Counseling
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2011</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The new five-story St. Cecilia’s Building was blessed and inaugurated.It houses the School of Music, the College Library (Print and Non-Print Sections),<br>
                                the Music Library, the Corazon Aquino Recital Hall, the HRM Laboratories, and two function halls.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2012</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.25rem;">
                            <strong>
                                The Friedenshaus Hotel School was blessed on July 26.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2013</strong></h1>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The School of Music was named a Center of Excellence in Music Education by the Commission on Higher Education.<br>
                                Two College graduates ranked 5th and 8th in the Licensure Examination for Teachers.
                            </strong>
                        </p>
                    </div>

                    <hr class="ssa-line">

                    <h1 style="font-size: 3.5rem;" class="ssa-text-color arial-text"><strong>2024</strong></h1>
                    <el-image style="margin-top: 50px; width: 80%; height: 80%;" :src="resources.img.unveiling"></el-image>
                    <div style="margin-top: 50px;">
                        <p class="ssa-text-color arial-text" style="font-size: 1.05rem;">
                            <strong>
                                The National Museum of the Philippines has recognized the St. Scholastica Building,<br>
                                St. Cecilia's Hall, St. Hildegard Building, St. Benedict Building, and St. Scholastica's<br>
                                Chapel at Saint Scholastica's College Manila as Important Cultural Property.
                            </strong>
                        </p>
                    </div>
                </center>
            </div>
        </div>
        <!--CONTENTS END-->

        <!--BACK TO TOP AND FOOTER START-->
        <div style="margin-top: 100px; margin-bottom: 20px;">
            <div class="row" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" style="padding-right: 0px;">
                    &nbsp;
                </div>
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                    <button @click="scrollToTop()" class="btn btn-ssa pull-right"><span style="font-family: Arial; font-size: 2rem; color: #ffffff;"><strong><i class="fa fa-angle-double-up" aria-hidden="true"></i>Back to Top</strong></span></button>
                </div>
            </div>
        </div>
        <div style="background: #012376;">
            <div class="row" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                &nbsp;
            </div>
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--BACK TO TOP AND FOOTER END-->
    </div>
</template>
<style>
    .ssa-line{
        border: 0;
        height: 2.5px;
        background-color: #b1bed7;
    }
</style>
<script>
    import SiteHeader from "../../../components/general/SiteHeader";
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { SiteHeader,NavbarStatic,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>