<template>
    <aside class="wrapper__aside">
        <div><img :src="header_img" class="img-fluid" alt="Logo"></div>
        <a :href="folder_name + '/home'">
            <div class="card-header">
                <i class="circle-bg-icon fa fa-tachometer"></i>
                <span>&nbsp;Home</span>
            </div>
        </a>
        <template>
            <div class="card">
                <a data-toggle="collapse" href="#setup"  v-if="checkPermission('setup')">
                    <div class="card-header">
                        <i class="circle-bg-icon fa fa-cogs" aria-hidden="true"></i>
                        <span>&nbsp;Setup</span>
                    </div>
                </a>
                <div id="setup" class="collapse">
                     <template v-if="checkPermission('access rights')">
                        <a :href="folder_name + '/access_rights'">
                            <div class="card-header" style="padding-left: 2rem;">
                                <i class="circle-bg-icon fa fa-user"></i>
                                <span>&nbsp;Access Rights</span>
                            </div>
                        </a>
                    </template>
                    <template v-if="checkPermission('department privileges')">
                        <a :href="folder_name + '/departments'">
                            <div class="card-header" style="padding-left: 2rem;">
                                <i class="circle-bg-icon fa fa-users"></i>
                                <span>&nbsp;Departments</span>
                            </div>
                        </a>
                    </template>
                    <template v-if="checkPermission('catergory and item privileges')">
                        <a :href="folder_name + '/categrories_items'">
                            <div class="card-header" style="padding-left: 2rem;">
                                <i class="circle-bg-icon fa fa-list-alt"></i>
                                <span>&nbsp;Categories and Items</span>
                            </div>
                        </a>
                    </template>
<!--                   <template>-->
<!--                        <a :href="folder_name + '/id_printing/id_setup'">-->
<!--                            <div class="card-header" style="padding-left: 2rem;">-->
<!--                                <i class="circle-bg-icon fa fa-pencil-square-o" aria-hidden="true"></i>-->
<!--                                <span>&nbsp;ID setup</span>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </template>-->
<!--                     <template>-->
<!--                        <a :href="folder_name + '/request_setup/request-setup'">-->
<!--                            <div class="card-header" style="padding-left: 2rem;">-->
<!--                                <i class="circle-bg-icon fa fa-envelope-o" aria-hidden="true"></i>-->
<!--                                <span>&nbsp;Request Setup</span>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </template>-->
                </div>
            </div>
        </template>
<!--        <a :href="folder_name + '/directory'">-->
<!--            <div class="card-header">-->
<!--                <i class="circle-bg-icon fa fa-book" aria-hidden="true"></i>-->
<!--                <span>&nbsp;Directory</span>-->
<!--            </div>-->
<!--        </a>-->
<!--        <template>-->
<!--            <div class="card">-->
<!--                <a data-toggle="collapse" href="#data-rights"  v-if="checkPermission('data rights')">-->
<!--                    <div class="card-header">-->
<!--                        <i class="circle-bg-icon fa fa-cogs" aria-hidden="true"></i>-->
<!--                        <span>&nbsp;Data Rights</span>-->
<!--                    </div>-->
<!--                </a>-->
<!--                <div id="data-rights" class="collapse">-->
<!--                    <a :href="folder_name + '/gradelevel_data_rights'">-->
<!--                        <div class="card-header">-->
<!--                            <i class="circle-bg-icon fa fa-cogs" aria-hidden="true"></i>-->
<!--                            <span>&nbsp;Grade Level</span>-->
<!--                        </div>-->
<!--                    </a>-->
<!--                    <a :href="folder_name + '/directory_column_rights'">-->
<!--                        <div class="card-header">-->
<!--                            <i class="circle-bg-icon fa fa-cogs" aria-hidden="true"></i>-->
<!--                            <span>&nbsp;Directory Columns</span>-->
<!--                        </div>-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </template>-->
       <!--  <a :href="folder_name + '/blasting_history'" v-if="checkPermission('announcement history')">
            <div class="card-header">
                <i class="circle-bg-icon fa fa-book" aria-hidden="true"></i>
                <span>&nbsp;History</span>
            </div>
        </a>
        <a :href="folder_name + '/blasting_contacts'" v-if="checknPermission('contacts')"> 
            <div class="card-header">
                <i class="circle-bg-icon fa fa-book" aria-hidden="true"></i>
                <span>&nbsp;Contacts</span>
            </div>
        </a> -->
         <!-- <a :href="folder_name + '/id_requests/request/lists'" v-if="checkPermission('request')">
            <div class="card-header">
                <i class="circle-bg-icon fa fa-print" aria-hidden="true"></i>
                <span>&nbsp;Requests</span>
            </div>
        </a> -->
    </aside>
</template>

<script>
export default {
    data() {
        return {
            folder_name: this.$root.folder_name,
            header_img : this.$root.schoolImg,
            home_url: this.$root.folder_name + '/home',
            branch: this.$root.auth.branchCode,
        }
    },
    mounted() {
        this.schImg();
        this.checkDefaultHomeUrl();
    },
    methods: {
        checkPermission(permission) {
            return this.$root.inArray(permission, this.$root.permissions);
        },
        isAdmin() {
            return this.$root.auth.info.user_type === "App\\Services\\Users\\User";
        },
        schImg() {
          axios.get(this.folder_name + '/schImg')
          .then(res => {
            this.header_img = res.data;
          });
        },
        checkDefaultHomeUrl(){
            axios({
                method: 'POST',
                type: 'JSON',
                url: this.folder_name + '/get_default_home_url',
                data: {branch: this.branch}
            }).then(res => {
                this.home_url = res.data;
            }).catch(err => {
                // this.$root.defaultError();
            })
        },
    },
}
</script>
