<template>
    <div>
        <SiteHeader style="width: 100%;"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <div style="margin-top: 50px;">
            <div class="container" style="background-color: rgba(246, 246, 246, 0.80); padding-bottom: 50px;">
                <br>
                <center>
                    <h1 style="font-family: Arial; color: #012376;"><strong>ADMISSIONS PROCEDURES AND REQUIREMENTS</strong></h1>
                </center>
                <br>
                <span style="font-family: Arial;">The applicant (or parent) must comply with the following procedure and requirements:</span>
                <br>
                <br>
                <span style="font-family: Arial;">Should you have any questions on specific requirements/procedure from Preschool to Graduate School, please e-mail: sscadmit@ssc.edu.ph</span>
                <br>
                <br>
                <ol>
                    <li>
                        <span style="font-family: Arial;">Create an account via the Admissions Online Portal <a href="https://sscmanila.orangeapps.ph/landing/sscmanila/" target="_blank">(https://sscmanila.orangeapps.ph/landing/sscmanila/)</a> and upload the
                            required documents.</span>
                    </li>
                    <li>
                        <span style="font-family: Arial;">Wait for the notification (text message and/or email) notifying you of your eligibility to pay the application fee of Php 300.00.</span>
                    </li>
                    <li>
                        <span style="font-family: Arial;">Pay the Php300.00 application fee at the Business Office or through the following payment channels:</span>
                    </li>
                    <ul style="list-style-type: none;">
                        <li><strong style="font-family: Arial;">BANCO DE ORO</strong></li>
                        <ul style="list-style-type: none;">
                            <li><span style="font-family: Arial;"><strong>Account Name:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ST.SCHOLASTICA'S COLLEGE ,INC</span></li>
                            <li><span style="font-family: Arial;"><strong>Account Number:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;00-458-0000-210</span></li>
                            <li><span style="font-family: Arial;"><strong>Bank Name:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BDO-Taft Vito Cruz</span></li>
                            <li><span style="font-family: Arial;"><strong>Swift Code: </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BNORPHMM</span></li>
                        </ul>
                    </ul>
                    <ul style="list-style-type: none;">
                        <li><strong style="font-family: Arial;">BANK OF THE PHILIPPINES ISLANDS:</strong></li>
                        <ul style="list-style-type: none;">
                            <li><span style="font-family: Arial;"><strong>Account Name:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ST.SCHOLASTICA'S COLLEGE ,INC</span></li>
                            <li><span style="font-family: Arial;"><strong>Account Number:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2771-0016-99</span></li>
                            <li><span style="font-family: Arial;"><strong>Branch Name:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BPI-Vito Cruz St. Scholastica</span></li>
                        </ul>
                    </ul>
                    <ul style="list-style-type: none;">
                        <li><strong style="font-family: Arial;"><i>Note: For foreign applicants, you must pay the application fee of USD 20 and the one-time Foreign Student’s Fee of USD 500.</i></strong></li>
                    </ul>
                    <li><span style="font-family: Arial;">After payment of the application fee, wait for the notification (text message and/or email) for your interview schedule.</span></li>
                    <li><span style="font-family: Arial;">Release of Notice of Acceptance to successful applicants to be sent via email and SMS along with the notification to pay the Confirmation Fee.</span></li>
                    <ul>
                        <li><strong style="font-family: Arial;">Application and Confirmation Fees</strong></li>
                        <ul style="list-style-type: none;">
                            <li><span style="font-family: Arial;">Application Fee: Php 300.00 (Filipino applicants)</span></li>
                            <li><span style="font-family: Arial;">Application Fee: USD 20 (foreign applicants) and one-time foreign Student’s Fee of USD 500</span></li>
                            <li><span style="font-family: Arial;">Confirmation Fee: Php 2,000.00 (non-refundable and to be deducted from tuition fee for <b>SY 2025-2026</b>) upon successful completion of application</span></li>
                        </ul>
                    </ul>

                    <ul>
                        <li><strong style="font-family: Arial;">Recommendation Forms</strong></li>
                        <ul style="list-style-type: none;">
                            <li><span style="font-family: Arial;">GRADE SCHOOL: <a href="https://bit.ly/GS_SSCMNL_RECOMMENDATIONFORMS" target="_blank">https://bit.ly/GS_SSCMNL_RECOMMENDATIONFORMS</a></span></li>
                            <li><span style="font-family: Arial;">HIGH SCHOOL: <a href="https://bit.ly/HS_SSCMNL_RECOMMENDATIONFORMS" target="_blank">https://bit.ly/HS_SSCMNL_RECOMMENDATIONFORMS</a></span></li>
                            <li><span style="font-family: Arial;">COLLEGE: <a href="https://bit.ly/COLLEGE_SSCMNL_RECOMMENDATIONFORM" target="_blank">https://bit.ly/COLLEGE_SSCMNL_RECOMMENDATIONFORM</a></span></li>
                            <li>
                                <span style="font-family: Arial;">Recommendation letters from adviser / teacher / professor and guidance counselor (GS , HS and College) and employer (Graduate School) <b>may be sent either onsite or via online.</b> Failure to do so may result in <b>delays in processing of application or release of result.</b></span>
                                <ol style="list-style: lower-alpha; font-family: Arial;">
                                    <li><b>Onsite: placed in a sealed envelope addressed to the Admissions Office</b></li>
                                    <li><b>Online: sent directly by the recommender to the office email address at sscadmit@ssc.edu.ph</b></li>
                                </ol>
                            </li>
                        </ul>
                    </ul>

                    <ul>
                        <li><strong style="font-family: Arial;">Condition for College Transferees</strong></li>
                        <ul style="list-style-type: none;">
                            <li><span style="font-family: Arial;">Applicants should not have more than FOUR (4) semesters of collegiate work and should show satisfactory performance in their previous schools.  More
                                than TWO (2) failing marks in their previous academic work may disqualify applicants from admission to SSC.</span>
                            </li>
                        </ul>
                    </ul>

                    <ul>
                        <li><strong style="font-family: Arial;">Release of Results</strong></li>
                        <ul style="list-style-type: none;">
                            <li><strong style="font-family: Arial;"><i>INTERVIEW</i></strong> <span style="font-family: Arial;">will be scheduled upon submission of initial documents and receipt of recommendation letters from recommenders (class adviser/professor/
                                employer and guidance counselor).</span>
                            </li>
                            <li><strong style="font-family: Arial;"><i>RESULT of the APPLICATION</i></strong> <span style="font-family: Arial;">will be released THREE (3) DAYS after the scheduled interview. An Acceptance Letter / Notice of Acceptance will be sent
                                to successful applicants (notification via email and SMS).</span>
                            </li>
                        </ul>
                    </ul>
                </ol>
                <br>
                <br>
                <el-collapse>
                    <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;PRESCHOOL UNIT</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <span style="font-family: Arial;"><b>* Required documents if with previous schooling for Pre-Kinder and Kinder applicants</b></span>
                            <br>
                            <ul>
                                <li><span style="font-family: Arial;">PK age requirement: 4 years old by August. 2025</span></li>
                                <li><span style="font-family: Arial;">K age requirement: 5 years old by August. 2025</span></li>
                                <li><span style="font-family: Arial;">Recent 2×2 or Passport-Size Photo</span></li>
                                <li><span style="font-family: Arial;">PSA-Issued Birth Certificate</span></li>
                                <li><span style="font-family: Arial;">Recent Report Card *</span></li>
                                <li><span style="font-family: Arial;">Recommendation Form/s*</span></li>
                            </ul>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;GRADE SCHOOL UNIT</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <ul>
                                <li><span style="font-family: Arial;">Recent 2×2 or Passport-Size Photo</span></li>
                                <li><span style="font-family: Arial;">PSA-Issued Birth Certificate</span></li>
                                <li><span style="font-family: Arial;">Recent Report Card</span></li>
                                <li><span style="font-family: Arial;">Recommendation Forms (<a href="https://bit.ly/GS_SSCMNL_RECOMMENDATIONFORMS" target="_blank">https://bit.ly/GS_SSCMNL_RECOMMENDATIONFORMS</a>)
                                    <ul>
                                        <li><span style="font-family: Arial;">PS - Gr.6 (Guidance Counselor)</span></li>
                                        <li><span style="font-family: Arial;">PK-K (Principal/Adviser)</span></li>
                                        <li><span style="font-family: Arial;">Gr.1 (Principal/Adviser)</span></li>
                                        <li><span style="font-family: Arial;">Grs.2-6 (Principal/Adviser)</span></li>
                                    </ul>
                                </span>
                                </li>
                            </ul>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="3" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;JUNIOR HIGH SCHOOL UNIT</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <ul>
                                <li><span style="font-family: Arial;">Recent 2×2 or Passport-Size Photo</span></li>
                                <li><span style="font-family: Arial;">NSO/PSA-Issued Birth Certificate</span></li>
                                <li><span style="font-family: Arial;">Recent Report Card</span></li>
                                <li>
                                    <span style="font-family: Arial;">Recommendation Forms (<a href="https://bit.ly/HS_SSCMNL_RECOMMENDATIONFORMS" target="_blank">https://bit.ly/HS_SSCMNL_RECOMMENDATIONFORMS</a>)</span>
                                    <ul>
                                        <li><span style="font-family: Arial;">Adviser</span></li>
                                        <li><span style="font-family: Arial;">Counselor</span></li>
                                    </ul>
                                </li>
                                <!-- <li><span style="font-family: Arial;">Admissions Essay (guidelines via:  <a href="https://bit.ly/UpdatedSSCMNLHighSchoolAdmissionsEssay2024-2025" target="_blank">https://bit.ly/UpdatedSSCMNLHighSchoolAdmissionsEssay2024-2025</a>)</span></li> -->
                            </ul>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;SENIOR HIGH SCHOOL UNIT</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <span style="font-family: Arial;">SENIOR HIGH SCHOOL</span>
                            <br>
                            <ul>
                                <li><span style="font-family: Arial;">Recent 2×2 or Passport-Size Photo</span></li>
                                <li><span style="font-family: Arial;">NSO/PSA-Issued Birth Certificate</span></li>
                                <li><span style="font-family: Arial;">Recent Report Card</span></li>
                                <li>
                                    <span style="font-family: Arial;">Recommendation Forms (<a href="https://bit.ly/HS_SSCMNL_RECOMMENDATIONFORMS" target="_blank">https://bit.ly/HS_SSCMNL_RECOMMENDATIONFORMS</a>)</span>
                                    <ul>
                                        <li><span style="font-family: Arial;">Adviser</span></li>
                                        <li><span style="font-family: Arial;">Counselor</span></li>
                                    </ul>
                                </li>
                                <!-- <li><span style="font-family: Arial;">Admissions Essay (guidelines via:  <a href="https://bit.ly/UpdatedSSCMNLHighSchoolAdmissionsEssay2024-2025" target="_blank">https://bit.ly/UpdatedSSCMNLHighSchoolAdmissionsEssay2024-2025</a>)</span></li> -->
                                <li><span style="font-family: Arial;">Artwork for Visual Arts Applicants (guidelines via: <a href="https://bit.ly/SSCManilaSHSVisualArtsTalentTest2024" target="_blank">https://bit.ly/SSCManilaSHSVisualArtsTalentTest2024</a>)</span></li>
                                <li><span style="font-family: Arial;">Audition for SHS Music Applicants Only</span></li>
                            </ul>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="5" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;COLLEGE FRESHMEN</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <ul>
                                <li><span style="font-family: Arial;">Recent 2×2 or Passport-Size Photo</span></li>
                                <li><span style="font-family: Arial;">NSO/PSA-Issued Birth Certificate</span></li>
                                <li><span style="font-family: Arial;">Recent Report Card</span></li>
                                <li><span style="font-family: Arial;"><b>RECOMMENDATION FORM</b> (<a href="https://bit.ly/COLLEGE_SSCMNL_RECOMMENDATIONFORM" target="_blank">https://bit.ly/COLLEGE_SSCMNL_RECOMMENDATIONFORM</a>)</span>
                                    <ul>
                                        <li><span style="font-family: Arial;">Adviser / Faculty / Counselor</span></li>
                                    </ul>
                                </li>
                                <li><span style="font-family: Arial;">Admissions Essay (Optional; guidelines via: <a href="https://bit.ly/2025_SSCMNL_CollegeAdmissionsApplicationEssay" target="_blank">AdmissionsEssay2024-2025</a>)</span></li>
                                <li><span style="font-family: Arial;">Artworks for Fine Arts & Interior Design applicants only (guidelines via: <a href="https://bit.ly/SSCManilaBFAandBSIDTalentTest2024" target="_blank">https://bit.ly/SSCManilaBFAandBSIDTalentTest2024</a>)</span></li>
                                <li><span style="font-family: Arial;">Portfolio (for Music Production & Technology applicants only)</span></li>
                                <li><span style="font-family: Arial;">Audition (for other Music applicants only)</span></li>
                            </ul>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;COLLEGE TRANSFEREE / SECOND COURSER</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <ul>
                                <li><span style="font-family: Arial;">Letter of Intent To Transfer</span></li>
                                <li><span style="font-family: Arial;">Certified True Copy of Grades</span></li>
                                <li><span style="font-family: Arial;">Subject Description</span></li>
                                <li><span style="font-family: Arial;">Grading System</span></li>
                                <li><span style="font-family: Arial;">Original Copy of Honorable Dismissal / Transfer Credentials</span></li>
                            </ul>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="7" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;GRADUATE SCHOOL AND COGNATE PROGRAMS</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div>
                            <ul>
                                <li><span style="font-family: Arial;">Letter of Intent</span></li>
                                <li><span style="font-family: Arial;">Curriculum Vitae</span></li>
                                <li><span style="font-family: Arial;">Transcript of Records</span></li>
                                <li><span style="font-family: Arial;">Recommendation Form (<b>from employer</b>)</span></li>
                            </ul>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                        <template slot="title">
                            <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;FOREIGN APPLICANTS</strong>
                        </template>
                        <div>&nbsp;</div>
                        <div><span style="font-family: Arial;"><b>BASIC EDUCATION</b> to <b>GRADUATE SCHOOL</b></span></div>
                        <div>
                            <span style="font-family: Arial; text-decoration: underline;"><i><b>For Applicants who Studied Abroad (Filipino Citizen and Foreigner):</b></i></span>
                            <br>
                            <ul>
                                <li><span style="font-family: Arial;">Certificate of Eligibility from DepEd</span></li>
                                <li><span style="font-family: Arial;">Authenticated /Apostilled Transcript of Records with English translation</span></li>
                            </ul>
                        </div>
                        <div>
                            <span style="font-family: Arial; text-decoration: underline;"><i><b>Additional For Foreign Students:</b></i></span>
                            <br>
                            <ul>
                                <li><span style="font-family: Arial;">Passport</span></li>
                                <li><span style="font-family: Arial;">Alien Certificate of Registration</span></li>
                            </ul>
                        </div>
                        <div>
                            <span style="font-family: Arial; text-decoration: underline;"><i><b>For those with Dual Citizenship:</b></i></span>
                            <br>
                            <ul>
                                <li><span style="font-family: Arial;">Philippine Passport and other citizenship passport</span></li>
                            </ul>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div style="margin-top: 20px; margin-bottom: 20px;">
            <div class="container">
                <img :src="resources.ao_applicants_journey" style="width: 100%; height: 100%;">
            </div>
        </div>

        <div style="margin-top: 20px; margin-bottom: 20px;">
            <div class="row" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" style="padding-right: 0px;">
                    &nbsp;
                </div>
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                    <button @click="scrollToTop()" class="btn btn-ssa pull-right"><span style="font-family: Arial; font-size: 2rem; color: #ffffff;"><strong><i class="fa fa-angle-double-up" aria-hidden="true"></i>Back to Top</strong></span></button>
                </div>
            </div>
        </div>

        <div style="margin-top: 75px;">
            &nbsp;
        </div>
        <SiteFooter></SiteFooter>
    </div>
</template>
<style>
    .el-collapse-item__header{
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        background-color: #012376;
        color: #ffffff;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        font-size: 13px;
        font-weight: 500;
        transition: border-bottom-color .3s;
        outline: 0;
    }
    .el-collapse-item__content {
        padding-bottom: 25px;
        font-size: 13px;
        color: #303133;
        line-height: 1.769230769230769;
        margin-left: 25px;
    }
</style>
<script>
    import SiteHeader from "../../../components/general/SiteHeader";
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { SiteHeader,NavbarStatic,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {
            this.addSsaColorClass();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            addSsaColorClass(){
                $(".el-collapse-item__header").css({
                    "background-color": "#012376",
                    "color": "#ffffff"
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>
