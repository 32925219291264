<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown" style="margin-left: 45px;">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="ssamanila_text_nav2">ABOUT US</span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/our-herstory'"><span class="ssamanila_text_nav2">OUR HERSTORY</span></a>
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/who-we-are'"><span class="ssamanila_text_nav2">WHO WE ARE</span></a>
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/institutional-office'"><span class="ssamanila_text_nav2">INSTITUTIONAL OFFICE</span></a>
                        </div>
                    </li>
                    <li class="nav-item dropdown" style="margin-left: 45px;">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="ssamanila_text_nav2">PROGRAMS</span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/programs/pre-school'"><span class="ssamanila_text_nav2">PRE-KINDER AND KINDER</span></a>
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/programs/grade-school'"><span class="ssamanila_text_nav2">GRADE SCHOOL UNIT</span></a>
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/programs/high-school'"><span class="ssamanila_text_nav2">HIGH SCHOOL UNIT</span></a>
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/programs/college'"><span class="ssamanila_text_nav2">COLLEGE</span></a>
                            <a class="dropdown-item" :href="env_wp.APP_URL+'/programs/graduate-school'"><span class="ssamanila_text_nav2">GRADUATE SCHOOL</span></a>
                        </div>
                    </li>
                    <li class="nav-item" style="margin-left: 45px;">
                        <a class="nav-link" :href="env_wp.APP_URL+'/admission'"><span class="ssamanila_text_nav2">ADMISSION</span></a>
                    </li>
                    <li class="nav-item" style="margin-left: 45px;">
                        <a class="nav-link" :href="env_wp.APP_URL+'/student-life'"><span class="ssamanila_text_nav2">STUDENT LIFE</span></a>
                    </li>
                    <li class="nav-item" style="margin-left: 45px;">
                        <a class="nav-link" :href="env_wp.APP_URL+'/advocacy-and-outreach-center'"><span class="ssamanila_text_nav2">ADVOCACIES AND OUTREACH</span></a>
                    </li>
                    <li class="nav-item" style="margin-left: 45px;">
                        <a class="nav-link" href="#"><span class="ssamanila_text_nav2">NEWS</span></a>
                    </li>
                    <li class="nav-item" style="margin-left: 45px;">
                        <a class="nav-link" :href="env_wp.APP_URL+'/give'"><span class="ssamanila_text_nav2">GIVE</span></a>
                    </li>
                    <li class="nav-item" style="margin-left: 45px;">
                        <a class="nav-link" :href="env_wp.APP_URL+'/contact-us'"><span class="ssamanila_text_nav2">CONTACT US</span></a>
                    </li>
                    <li v-if="$root.auth.info !== null" class="nav-item dropdown" style="margin-left: 45px;">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="ssamanila_text_nav2">{{ $root.auth.info.name }}</span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
                            <a class="dropdown-item pointer" @click="$root.logout()"><span class="ssamanila_text_nav2">LOG OUT</span></a>
                            <a class="dropdown-item pointer" :href="env_wp.APP_URL+'/news-menu'"><span class="ssamanila_text_nav2">NEWS MENU</span></a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>
<style>
    .ssamanila_text_btn{
        color: #012277;
        font-weight: bold;
        font-size: 1.05rem;
    }
    .ssamanila_text_nav{
        color: #ffffff;
        font-weight: bold;
        font-size: 1.15rem;
    }
    .ssamanila_text_nav2{
        color: #012277;
        font-weight: bold;
        font-size: 1.05rem;
    }
    .rounded-btn{
        border-radius: 20px;
    }
    /* Reset default styles for anchor tag with class .non-a-tag */
    a.non-a-tag {
        display: inline; /* Make it behave like inline element */
        text-decoration: none; /* Remove underline */
        color: #ffffff; /* Inherit color from parent */
        cursor: pointer; /* Change cursor to default */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
        border: none; /* Remove border */
        background: none; /* Remove background */
        outline: none; /* Remove outline */
    }

    /* Optionally, you can specify styles for visited and hover states */
    a.non-a-tag:hover,
    a.non-a-tag:active,
    a.non-a-tag:focus {
        color: #ffffff; /* Inherit color from parent */
        text-decoration: none; /* Remove underline */
    }

</style>
<script>
    export default {
        props: {
            title: String,
        },
        data () {
            return {
                nav_color: '#F8FAFC',
                nav_txt_color: '',
                defaultActiveIndex: '0',
                notifications: [],
                folder_name: '',
                nav_menus_pages: [],
                picture: '',
                env_wp: this.$root.env_wp.env_wp
            }
        },
        mounted() {
            this.folder_name = this.$root.folder_name;
            console.log();
            // this.getNavMenusPagesAndSetup();
        },
        created() {
            this.$root.$refs.NavBar = this;
        },
        methods: {
            getNavMenusPagesAndSetup(){
                axios.get(this.folder_name+ '/cms/get_nav_menus_pages')
                    .then(res => {
                        this.nav_menus_pages = res.data.menus_pages;
                        this.nav_color = res.data.setup.navbar_color;
                        this.nav_txt_color = res.data.setup.navbar_text_color;
                        this.picture = res.data.picture.full_path;
                    })
            },
            navigate(URL) {
                if (URL == 'mark_all_as_read') this.notifMarkAsRead()
                else if (!URL) return
                else if (URL != 'mark_all_as_read') window.location = URL
            },
            backHome(){
                window.location.href = this.folder_name + '/home';
            },
            cms(){
                window.location.href = this.folder_name + '/cms';
            },
            gotoLink(link){
                window.open(link, '_blank');
            },
        }
    }
</script>