<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="college-backdrop-baa">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_business_and_accountancy/Business and Accountancy Logo.png'" alt="SHLRM" class="logo">
                    </div>
                    <div class="col-md-6 mt-5">
                        <h1 style="font-family: Arial; font-size: 2rem; color: #ffffff;">
                            SCHOOL OF
                        </h1>
                        <h1 style="font-family: Arial; font-size: 2.75rem; color: #ffffff;">
                            <b>BUSINESS and ACCOUNTANCY</b>
                        </h1>
                    </div>
                </div>
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h1 class="text-right justify-content-end" style="font-family: Arial; font-size: 3rem; color: #ffffff; margin-bottom: 25px;">About the School of<br>Business and Accountancy</h1>
                            <h4 class="text-left" style="color: #ffffff; font-size: 2rem;"><b>Vision:</b></h4>
                            <p class="text-left" style="color: #ffffff; font-size: 1.5rem;">
                                We are a business school committed to equip young women with professional education and life-long learning skills, through sound and meaningful pedagogical methodologies, and guided by the Benedictine values of Ora et Labora, to become competent managers, entrepreneurs and business professionals with local and global perspectives.
                            </p>
                            <br>
                            <p class="text-left" style="color: #ffffff; font-size: 1.5rem;">
                                We envision graduates who are
                                <ul style="font-size: 1.5rem; color: #ffffff;">
                                    <li>competent <b>Accounting and Business professionals,</b></li>
                                    <li>persons for others,</li>
                                    <li>deeply conscious of their social commitment with genuine concern for public interest and sensitivity to social responsibility,</li>
                                    <li>life-long learners who have the ability to work with others in a consultative process and in cross-  cultural and cross-border setting,</li>
                                    <li>broad minded individuals,</li>
                                    <li>committed to acting with integrity, honesty, professional competence and due care, and</li>
                                    <li>women and men of character imbued with Benedictine values with the ability to interact with diverse groups of people and dedicated to the continuous pursuit of academic excellence.</li>
                                </ul>
                            </p>
                            <br>
                            <h4 class="text-left" style="color: #ffffff; font-size: 2rem;"><b>Mission:</b></h4>
                            <p class="text-left" style="color: #ffffff; font-size: 1.5rem;">
                                We envision business graduates imbued with a high sense of professionalism, competent and globally competitive, socially and morally responsible, and capable of maintaining a sound work-life balance.
                                <br>
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;We at the SBA – Business Department ensures that apart of the inculcation of Ora et Labora to our business students, we implement outcomes-based education. Our dynamic curriculum in Bachelor of Science in Business Administration Major in Marketing Management and International Business Economics and Diplomacy synergize theoretical approach with real-world application. Our business students delve into core business principles, theories, lectures ergo sharpening their analytical skills preparing them to call for sound business decisions in the future. We encourage and train our business students to think out of the box equipping them to be industry ready anytime soon. We practice mentoring and shadowing for potential student leaders helping them reach their full potential.
                                <br>
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;Our business faculty members are all master’s degree holder while majority of them are taking up their Doctoral Degree. Hence, are business students are being honed by top caliber Professors and industry practitioners. Their relentless passion for teaching motivates our business students to spread their wings and conquer their self-doubt. Thus, allowing them to reach several milestones at a young age.
                            </p>
                            <br>
                            <p class="text-left" style="color: #FAF9F6; font-size: 1.5rem;">
                                The School of Business and Accountancy provides holistic professional education that enables the students to become
                                <ul style="font-size: 1.5rem; color: #FAF9F6;">
                                    <li>liberally educated,</li>
                                    <li>Competent, and</li>
                                    <li>socially responsible <b>Accounting and Business professionals,</b></li>
                                    <li>living out Ora et Labora,</li>
                                    <li>capable of making a positive contribution to the profession and society they serve.</li>
                                </ul>
                            </p>
                            <br>
                            <h4 class="text-left" style="color: #FAF9F6; font-size: 2rem;"><b>Goals:</b></h4>
                            <p class="text-left" style="color: #FAF9F6; font-size: 1.5rem;">
                                The goals of the School is to provide:
                                <ul style="font-size: 1.5rem; color: #FAF9F6;">
                                    <li>ample training that would sharpen the learner’s critical thinking, creative and innovative thinking as well as meta-cognitive skills;</li>
                                    <li>adequate instruction that would hone their ability to create new knowledge and prescribe new <b>business and accounting systems and models</b> through research;</li>
                                    <li>opportunity and access for students to the most up to  date concepts, theories, frameworks and praxis in the field of business, accountancy and technology;</li>
                                    <li>high quality industry-responsive curriculum and implement them effectively;</li>
                                    <li>valid assessment of learning for students;</li>
                                    <li>adequate and authentic learning experiences that would promote and fortify Benedictine values and hallmarks to its graduates.</li>
                                </ul>
                            </p>
                            <center>
                                <h4 class="text-left" style="color: #FAF9F6; font-size: 1.5rem;"><b>Join us and embark on a journey towards becoming a top notched future leader in the local and global business landscape.</b></h4>
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row" style="margin-left: 15px;" v-if="isWindowOnDesktop">
                        <div class="col-md-12">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">
                                            &nbsp;&nbsp;&nbsp;Bachelor of Science in Accountancy
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 120%;">
                                        The degree program provides general accounting education to students wanting to pursue a<br>
                                        professional career in Accountancy in general and in Public Accounting in particular (CMO 27, Sec 5.2)
                                        <br>
                                        <br>
                                        <b>Curriculum Highlights:</b>
                                        <ul>
                                            <li>
                                                There are 223 total course  units offered under the BSA Program.<br>
                                                There are a total of 173 units of common Business and Management, Accounting and Professional Courses as prescribed by CHED (CMO 27). The remainder is covered by General Education and Institution Courses.<b>We may need to break this down through a matrix?</b>
                                            </li>
                                            <li>
                                                The courses are systematically arranged to ensure seamless transition of developmental learning.
                                            </li>
                                            <li>
                                                The curriculum offers a milieu of  well designed learning activities and experiences all necessary to endow the students with<br>
                                                the necessary expected  attributes of a St. Scholastica’s College Bachelor of Science in Accountancy graduate. 
                                            </li>
                                            <li>
                                                A globally competent, socially and morally  responsible accounting professional,  imbued with the hallmarks of a true Benedictine citizen leader is its true trademark.
                                            </li>
                                        </ul>
                                        <br>
                                        <b>Course Highlights:</b>
                                        <ul>
                                            <li>The BSA program of SSC has garnered Level 3 Re-accreditation Status and has a number of outstanding<br>
                                                alumni that have made their important mark in the local and global Accountancy Profession.</li>
                                            <li>
                                                The School of Business and Accountancy has a dynamic industry-responsive BSA curriculum which provides<br>
                                                and meets  the volatile requirements of the  Industry. 
                                            </li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff;">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">
                                            &nbsp;&nbsp;&nbsp;Bachelor of Science in Business Administration Major in Marketing Management
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Marketing Management Program focuses on molding young minds into highly skilled and practical<br>
                                        marketing professionals who are equally at home in the rigorous, corporate business environment and in the<br>
                                        highly competitive, entrepreneurial and corporate business setting.  This is achieved by developing<br>
                                        the students’ knowledge and skills competence in both the functional, analytical and strategically service–minded marketing professional.
                                        <br>
                                        <br>
                                        <b>Jobs directly related to the degree include:</b>
                                        <ul>
                                            <li>Marketing executive/manager</li>
                                            <li>Advertising account executive</li>
                                            <li>Advertising art director</li>
                                            <li>Digital marketer</li>
                                            <li>Events Manager</li>
                                            <li>Market researcher</li>
                                            <li>Media buyer</li>
                                            <li>PPC (pay-per-click or internet ads) specialist</li>
                                            <li>Public relations officer</li>
                                            <li>Sales promotion account executive</li>
                                            <li>Social media manager</li>
                                        </ul>
                                        <br>
                                        <b>Jobs where the degree would be useful include:</b>
                                        <ul>
                                            <li>Business adviser</li>
                                            <li>Digital copywriter</li>
                                            <li>Product manager</li>
                                            <li>Publishing rights manager</li>
                                            <li>Web content manager</li>
                                            <li>Bank teller</li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Business Administration Major in International Business and Diplomacy</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The International Business Economics & Diplomacy Program develops the competency of students<br>
                                        in international economics and foreign policy analysis and enhances their qualities and skills<br>
                                        for foreign relations, negotiations, and diplomatic protocol.  It produces economic diplomats<br>
                                        with the ability to analyze how the economy and business work and identify changes and trends<br>
                                        in the national and international markets and understand how these shape the plans, prospects<br>
                                        and performance of enterprises, industries and economic sectors.  It also enables the students<br>
                                        to access strategic research information and apply business economic tools to create<br>
                                        sustainable competitive advantage in their organization.
                                        <br>
                                        <br>
                                        <b>Jobs directly related to the degree include:</b>
                                        <div class="row">
                                            <div class="col">
                                                <ul>
                                                    <li>Diplomat (upon passing the Foreign Service Officer Exam by the Dept of Foreign Affairs)</li>
                                                    <li>Actuarial analyst</li>
                                                    <li>Compliance officer</li>
                                                    <li>Data analyst</li>
                                                    <li>Economist</li>
                                                    <li>Financial risk analyst</li>
                                                </ul>
                                            </div>
                                            <div class="col">
                                                <ul>
                                                    <li>Investment analyst</li>
                                                    <li>Political risk analyst</li>
                                                    <li>Risk Manager</li>
                                                    <li>Statistician </li>
                                                    <li>Stockbroker</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br>
                                        <b>Jobs where the degree would be useful include:</b>
                                        <ul>
                                            <li>Business development manager</li>
                                            <li>Civil Service Officer</li>
                                            <li>Data scientist</li>
                                            <li>Diplomatic service officer</li>
                                            <li>Local government officer</li>
                                            <li>Management consultant</li>
                                            <li>Policy officer</li>
                                            <li>Quantity surveyor</li>
                                            <li>Bank teller</li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" v-if="!isWindowOnDesktop">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">
                                            &nbsp;&nbsp;&nbsp;Bachelor of Science in Accountancy
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff;">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">
                                            &nbsp;&nbsp;&nbsp;BSBA Major in Marketing Management
                                        </strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;BSBA Major in International Business and Diplomacy</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </div>
            </div>
            <!-- style="margin-top: 150px; margin-bottom: 150px;" -->
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="text-left justify-content-start ssa-text-color" style="font-family: Arial; font-size: 5rem; margin-bottom: 25px;">CONTACT US:</h1>
                        <h4 class="text-left justify-content-start ssa-text-color" style="font-size: 2rem;">
                            <strong>School of Business and Accountancy</strong><br>
                            local 2298 (Dean of School of Business and Accountancy Secretary)
                        </h4>
                    </div>
                </div>
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
            <div class="container">
                &nbsp;
            </div>
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }
    .logo{
        width: 200px;
        height: 200px;
        margin-right: 10px;
    }
</style>
<script>
    import NavbarStatic from "../../../../components/general/NavbarStatic";
    import SiteHeader from "../../../../components/general/SiteHeader";
    import SiteFooter from "../../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>