<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="who-we-are">
            <h1>WHO WE ARE</h1>
        </div>

        <div class="container py-5" style="background-color: #f8f9fa;">
            <template v-if="isWindowOnDesktop">
                <div class="row">
                    <div class="col-md-12">
                        <h1 style="font-family: Arial; font-size: 2rem; color: #012376; float: left;"><strong><i>VISION</i></strong></h1>
                        <br>
                        <br>
                        <br>
                        <p style="font-family: Arial; font-size: 1.5rem; color: #012376; float: left;">
                            We envision empowered Scholasticans of character and integrity,
                        </p>
                        <br>
                        <br>
                        <br>
                        <ul style="list-style-type: none;">
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376; float: left;"><strong>- imbued with Filipino, Christian and Benedictine values,</strong></li>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376; float: left;"><strong>- live out Ora et Labora with passion for service, and</strong></li>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376; float: left;"><strong>- commit themselves to truth and justice to transform society.</strong></li>
                        </ul>
                        <br>
                        <br>
                        <br>
                        <div style="margin-bottom: 3rem;"></div>
                        <p style="font-family: Arial; font-size: 1.5rem; color: #012376; float: left;">
                            Responsive to the needs of the changing times, our graduates are globally competent lifelong learners
                            who are able to embrace life in its totality.
                        </p>
                    </div>
                    <br>
                    <br>
                    <br>
                    <div style="margin-bottom: 3rem;"></div>
                    <div class="col-md-12">
                        <h1 style="font-family: Arial; font-size: 2rem; color: #012376; float: left;"><strong><i>MISSION</i></strong></h1>
                        <br>
                        <br>
                        <br>
                        <p style="font-family: Arial; font-size: 1.5rem; color: #012376; float: left;">
                            We are a Catholic Benedictine educational institution for Scholasticans, committed to providing holistic
                            formation anchored on academic excellence to mold our students into critically aware and socially
                            responsible agents of change towards the transformation to a just and equitable society.
                        </p>
                        <br>
                        <br>
                        <p style="font-family: Arial; font-size: 1.5rem; color: #012376; float: left;">
                            The mission is done in the context of a socially-oriented school:
                        </p>
                        <br>
                        <br>
                        <br>
                        <div style="margin-bottom: 7rem;"></div>
                        <ul>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                                <strong>
                                    Whose administration, faculty, students, and personnel are conversant with and adaptable to
                                    the current economic, political, socio-cultural, and ecological conditions of the Philippines and
                                    the world;
                                </strong>
                            </li>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                                <strong>
                                    Where the school community is actively involved in working with the poor and vulnerable
                                    toward self-reliance and total human liberation for a more inclusive and compassionate society;
                                </strong>
                            </li>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                                <strong>
                                    Where curricular, co-curricular, and extra-curricular activities foster the development of social
                                    awareness and involvement of the different sectors of the community with chosen programs of
                                    action that promote genuine democracy and nationalism;
                                </strong>
                            </li>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                                <strong>
                                    Whose systems of evaluation recognize the uniqueness of every person, develop critical thinking,
                                    and cultivate social responsibility;
                                </strong>
                            </li>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                                <strong>
                                    Where policies of employment and interrelationships reflect social values, especially justice,
                                    equity, and respect for human dignity, and
                                </strong>
                            </li>
                            <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                                <strong>
                                    Where the various sectors participate in making the decisions and policies which affect them.
                                </strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>

            <template v-if="!isWindowOnDesktop">
                <center>
                    <h1 style="font-family: Arial; font-size: 2rem; color: #012376;"><strong><i>VISION</i></strong></h1>
                    <br>
                    <br>
                    <br>
                    <p style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                        We envision empowered Scholasticans of character and integrity,
                    </p>
                    <br>
                    <ul style="list-style-type: none; padding:0%;">
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;"><strong>- imbued with Filipino, Christian and Benedictine values,</strong></li>
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;"><strong>- live out Ora et Labora with passion for service, and</strong></li>
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;"><strong>- commit themselves to truth and justice to transform society.</strong></li>
                    </ul>
                    <br>
                    <p style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                        Responsive to the needs of the changing times, our graduates are globally competent lifelong learners
                        who are able to embrace life in its totality.
                    </p>
                    <hr>
                    <h1 style="font-family: Arial; font-size: 2rem; color: #012376;"><strong><i>MISSION</i></strong></h1>
                    <br>
                    <br>
                    <br>
                    <p style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                        We are a Catholic Benedictine educational institution for Scholasticans, committed to providing holistic
                        formation anchored on academic excellence to mold our students into critically aware and socially
                        responsible agents of change towards the transformation to a just and equitable society.
                    </p>
                    <br>
                    <p style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                        The mission is done in the context of a socially-oriented school:
                    </p>
                    <br>
                    <ul style="padding:0%">
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                            <strong>
                                Whose administration, faculty, students, and personnel are conversant with and adaptable to
                                the current economic, political, socio-cultural, and ecological conditions of the Philippines and
                                the world;
                            </strong>
                        </li>
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                            <strong>
                                Where the school community is actively involved in working with the poor and vulnerable
                                toward self-reliance and total human liberation for a more inclusive and compassionate society;
                            </strong>
                        </li>
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                            <strong>
                                Where curricular, co-curricular, and extra-curricular activities foster the development of social
                                awareness and involvement of the different sectors of the community with chosen programs of
                                action that promote genuine democracy and nationalism;
                            </strong>
                        </li>
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                            <strong>
                                Whose systems of evaluation recognize the uniqueness of every person, develop critical thinking,
                                and cultivate social responsibility;
                            </strong>
                        </li>
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                            <strong>
                                Where policies of employment and interrelationships reflect social values, especially justice,
                                equity, and respect for human dignity, and
                            </strong>
                        </li>
                        <li style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                            <strong>
                                Where the various sectors participate in making the decisions and policies which affect them.
                            </strong>
                        </li>
                    </ul>
                </center>
            </template>
        </div>

        <div class="container py-5">
            <div class="row">
                <div class="col-md-12">
                    <img :src="resources.bldg" alt="St. Scholastica's Bldg.(Wire Framed)" :style="!isWindowOnDesktop ? 'width: 100%;' : 'width: 1000px;'">
                </div>
            </div>
        </div>

        <div class="container py-5">
            <center>
                <h1 style="font-family: Arial; font-size: 2rem; color: #012376;"><strong>HOME OF FIRST FOR WOMEN</strong></h1>
                <br>
                <p style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                    St. Scho has produced notable women who have distinguished themselves by achieving some of the country’s “FIRSTS” in their fields of pursuits in life.
                </p>
            </center>
            <div v-for="(pair, index) in transformedData" :key="index" class="row slide-down">
                <div class="col-md-6" v-for="(img, imgIndex) in pair" :key="imgIndex">
                    <img :src="img" :alt="`${index}-${imgIndex}`" :style="!isWindowOnDesktop ? 'width: 100%; height: 100%;' : 'width: 600px; height: 100%;'" class="slide-down">
                </div>
            </div>
        </div>

        <div style="margin-top: 20px; margin-bottom: 20px;">
            <div class="row" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" style="padding-right: 0px;">
                    &nbsp;
                </div>
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                    <button @click="scrollToTop()" class="btn btn-ssa pull-right"><span style="font-family: Arial; font-size: 2rem; color: #ffffff;"><strong><i class="fa fa-angle-double-up" aria-hidden="true"></i>Back to Top</strong></span></button>
                </div>
            </div>
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div style="background: #012376;">
            <div :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                &nbsp;
            </div>
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .blue-shape {
        position: absolute;
        width: 350px;
        height: 350px;
        background-color: #002d72;
        transform: rotate(45deg);
        top: -10%;
        right: 10%;
        z-index: 0;
        left: 50%;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 50%; /* Maintain aspect ratio */
    }

    .image {
        position: absolute;
        border: 5px solid white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease;
    }

    .image1 {
        top: -10%;
        left: 50%;
        width: 60%;
        transform: rotate(-5deg);
        z-index: 2;
    }

    .image2 {
        top: 70%;
        left: 25%;
        width: 60%;
        transform: rotate(5deg);
        z-index: 3;
    }

    .image:hover {
        transform: scale(1.05);
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Cover the container */
        border: 2px solid white;
        border-radius: 5px;
    }

    .who-we-are {
        position: relative;
        background: url('https://ssamanilademo.orangeapps.ph/ssamanila_site/public/assets/img/ssa_manila/who_are_we_page/Backdrop for header.jpg') no-repeat center center;
        background-size: cover;
        color: white;
        text-align: center;
        padding: 50px 0;
    }
    .who-we-are::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(1, 35, 118, 0.7), rgba(1, 35, 118, 0.7));
        z-index: 1;
    }
    .who-we-are h1 {
        position: relative;
        z-index: 2;
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 20px;
    }

</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('in-view');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            document.querySelectorAll('.slide-down').forEach(img => {
                observer.observe(img);
            });
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        computed:{
            transformedData(){
                const result = [];
                const items = this.resources.home_of_first;
                const len = items.length;

                for(let i = 0; i < Math.ceil(len / 2); i++){
                    const row = [];
                    for(let j = 0; j < 2; j++){
                        if (items[i + j * Math.ceil(len / 2)]) {
                            row.push(items[i + j * Math.ceil(len / 2)]);
                        }
                    }
                    result.push(row);
                }
                return result;
            }
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>