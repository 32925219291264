<template>
    <div>
        <SiteHeader style="width: 100%;"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>

        <div style="margin-top: 30px;">
            <center>
                <h1 style="font-size: 4rem; font-family: Arial; color: #012376;"><strong>CONTACT US</strong></h1>
            </center>
        </div>

        <div style="margin-top: 25px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <span style="font-family: Arial; font-size: 1.5rem; color: #012376;">
                            <strong>DIRECTIONS</strong><br>
                            2560 Leon Guinto Street Singalong, Malate,<br>
                            Manila Philippines
                        </span>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3871.986279574857!2d120.99630191513836!3d14.563906282721347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c979aa5ecb31%3A0xd6bc79af8fcb7383!2sSt.%20Scholastica's%20College!5e0!3m2!1sen!2sph!4v1687373024853!5m2!1sen!2sph"
                            width="500"
                            height="450"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div class="col-md-6">
                        <div style="margin-top: 75px;">&nbsp;</div>
                        <center>
                            <span style="margin-bottom: 5px; font-family: Arial;"><strong>We'd love to help</strong></span><br>
                            <el-input v-model="name" type="text" placeholder="Your Name" style="width: 350px; margin-bottom: 5px;"></el-input>
                            <el-input v-model="email" type="text" placeholder="Your Email" style="width: 350px; margin-bottom: 5px;"></el-input>
                            <el-input v-model="phone_no" type="text" placeholder="Your Contact Number" style="width: 350px; margin-bottom: 5px;"></el-input>
                            <el-input v-model="message" type="text" placeholder="Message" style="width: 350px; margin-bottom: 5px;"></el-input>
                            <el-button type="primary" @click="submitMessage()" style="width: 350px;">SUBMIT</el-button>
                        </center>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 75px;">
            <center>
                <h1 style="font-family: Arial; color: #012376; font-size: 3rem;">
                    <strong>Dial <span style="font-size: 4rem;">8567 - 7686</span> with the office local number</strong>
                </h1>
            </center>
        </div>

        <div class="container" style="margin-top: 75px;">
            <div class="row">
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;GRADE SCHOOL DIRECTORY:</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div><strong>Grade School Principal Secretary:</strong> local 4498</div>
                            <div><strong>Grade School Faculty Room (Rm. 1 & 2):</strong> local 4470</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;HIGH SCHOOL DIRECTORY:</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div><strong>High School Office:</strong> local 8593</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div class="row" style="margin-top: 25px;">
                <div class="col-md-12">
                    <el-collapse>
                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;COLLEGE and GRADUATE SCHOOL DIRECTORY:</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div><strong>Schoo of Music</strong></div>
                            <div>local 2288 (Dean of School of Music Secretary)</div>
                            <div><strong>School of Arts and Sciences</strong></div>
                            <div>local 2292 (Dean of School of Arts and Sciences Secretary)</div>
                            <div><strong>School of Business and Accountancy</strong></div>
                            <div>local 2298 (Dean of School of Business and Accountancy Secretary)</div>
                            <div><strong>School of Hotel, Leisure and Restaurant Management</strong></div>
                            <div>local 7110 (Friedenshaus Hotel Admin Office)</div>
                            <div><strong>Dean of College Student Affairs</strong></div>
                            <div>local 2228 (Student Affairs Office Secretary)</div>
                            <div><strong>Dean of Graduate School</strong></div>
                            <div>local 8192 (Dean of Graduate School Secretary)</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div class="row" style="margin-top: 25px;">
                <div class="col-md-12">
                    <el-collapse>
                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INSTITUTIONAL OFFICE DIRECTORY:</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div><strong>Admissions Office:</strong> (local 8169)</div>
                            <div><strong>Registrar’s Office:</strong> (local 8167)</div>
                            <div><strong>Marketing and Communications Office:</strong> (local 8556)</div>
                            <div><strong>Scholarships and Grants Office:</strong> (local 8163)</div>
                            <div><strong>Business Office:</strong> (local 8173)</div>
                            <div><strong>General Information:</strong> (local 8000)</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>


        <div style="margin-top: 100px; margin-bottom: 100px;">
            <div class="row">
                <div class="col-md-6">
                    &nbsp;
                </div>
                <div class="col-md-6">
                    <button @click="scrollToTop()" class="btn btn-ssa pull-right"><span style="font-family: Arial; font-size: 2rem; color: #ffffff;"><strong><i class="fa fa-angle-double-up" aria-hidden="true"></i>Back to Top</strong></span></button>
                </div>
            </div>
        </div>

        <div style="margin-top: 75px;">
            &nbsp;
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
    </div>
</template>
<style>
    .el-collapse-item__header{
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        background-color: #012376;
        color: #ffffff;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        font-size: 13px;
        font-weight: 500;
        transition: border-bottom-color .3s;
        outline: 0;
    }
    .el-collapse-item__content {
        padding-bottom: 25px;
        font-size: 13px;
        color: #012376;
        line-height: 1.769230769230769;
        margin-left: 25px;
        background-color: #f6f6f6;
        font-family: Arial;
    }
    .el-collapse-item__wrap {
        will-change: height;
        background-color: #f6f6f6;
        overflow: hidden;
        box-sizing: border-box;
        border-bottom: 1px solid #EBEEF5;
    }
</style>
<script>
    import SiteHeader from "../../../components/general/SiteHeader";
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {

        },
        components: { SiteHeader,NavbarStatic,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                name: '',
                email: '',
                phone_no: '',
                message: '',
            }
        },
        watch:{
            // phone_no(newVal, oldVal) {
            //     if (!newVal.startsWith('+63')) {
            //         this.phone_no = '+63' + newVal.replace(/^\+63/, '');
            //     }
            // }
        },
        mounted() {

        },
        created() {
            this.addSsaColorClass();
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            addSsaColorClass(){
                $(".el-collapse-item__header").css({
                    "background-color": "#012376",
                    "color": "#ffffff"
                });
            },
            validateEmail() {
                // Basic email pattern for validation
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(this.email)) {
                    this.emailError = 'Please enter a valid email address.';
                } else {
                    this.emailError = '';
                }
            },
            submitMessage(){
                var params = {
                    name: this.name,
                    email: this.email,
                    phone_no: this.phone_no,
                    message: this.message,
                };
                // axios.post(env_wp.APP_URL+ '/submit_contact_us', params)
                // .then(res => {
                //     if(res.data.status == 1){
                //         this.$swal(res.data.title, res.data.text, res.data.type);
                //         this.name = '';
                //         this.email = '';
                //         this.phone_no = '';
                //         this.message = '';
                //     }else{
                //         this.$swal('Oops', 'Something went wrong while submitting your message.', 'error');
                //     }
                // })
                // .catch(err => {
                //     console.log(err);
                // })
            }
        }
    }
</script>